import { useState } from "react";
import Rating from "react-rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import moment from "moment";
import ImageZoomModal from "../../templates/modals/imageZoomModal";

let imageSrc = "";

function ReviewItem({ data, style }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [isModalVisiable, setIsModalVisiable] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 0px 20px 0px",
          borderBottom: "1px solid #DCDCDC",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: 42,
              height: 42,
              borderRadius: 100,
              marginRight: 5,
            }}
            src={
              data.profileServerFileName
                ? `${process.env.REACT_APP_MEDIA_URL}${data.profileServerFileName}`
                : "/assets/icons/empty_person.png"
            }
            alt="user-temp02"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <Rating
                style={{
                  height: isMobile ? 16 : 20,
                }}
                initialRating={data.score}
                readonly
                emptySymbol={
                  <img
                    src="/assets/icons/rating_d.png"
                    className="icon"
                    style={{
                      width: isMobile ? 16 : 20,
                      height: isMobile ? 16 : 20,
                    }}
                  />
                }
                fullSymbol={
                  <img
                    src="/assets/icons/rating_a.png"
                    className="icon"
                    style={{
                      width: isMobile ? 16 : 20,
                      height: isMobile ? 16 : 20,
                    }}
                  />
                }
              />
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 16,
                  fontWeight: 700,
                  color: "#313131",
                  marginLeft: 5,
                }}
              >
                {data.score}.0
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 12 : 14,
                  lineHeight: isMobile ? "16.8px" : "19.6px",
                  fontWeight: 500,
                  color: "#C4C4C4",
                  marginRight: 4,
                }}
              >
                {data.email}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 12 : 14,
                  lineHeight: isMobile ? "16.8px" : "19.6px",
                  fontWeight: 500,
                  color: "#C4C4C4",
                }}
              >
                {moment(data.createdAt).format("YYYY.MM.DD")}
              </PretendardText>
            </div>
          </div>
        </div>

        {data.optionFlag ? (
          JSON.parse(data.optionInfo).map((item02, index) => {
            return (
              <PretendardText
                key={index}
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  fontWeight: 500,
                  color: "#939393",
                  margin: "10px 0px",
                }}
              >
                {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
              </PretendardText>
            );
          })
        ) : (
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "19.6px",
              fontWeight: 500,
              color: "#939393",
              margin: "10px 0px",
            }}
          >
            {`Số lượng: ${data.noOptionQuantity}`}
          </PretendardText>
        )}

        <PretendardText
          style={{
            fontSize: isMobile ? 14 : 16,
            lineHeight: isMobile ? "19.6px" : "23.2px",
            fontWeight: 400,
            color: "#313131",
            marginBottom: 10,
          }}
        >
          {data.content}
        </PretendardText>

        <div
          style={{
            position: "relative",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Swiper
            modules={[Navigation]}
            slidesPerView={isMobile ? 4 : 7}
            spaceBetween={4}
          >
            {data.images?.length > 0
              ? data.images?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        onClick={() => {
                          setIsModalVisiable(true);
                          imageSrc = item.imageServerFileName;
                        }}
                        style={{
                          // cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          width: isMobile ? 80 : "86px",
                          height: isMobile ? 80 : "86px",
                          position: "relative",
                          borderRadius: 4,
                        }}
                      >
                        <img
                          style={{
                            width: isMobile ? 80 : "86px",
                            height: isMobile ? 80 : "86px",
                            borderRadius: 4,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="review_image"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>
      </div>

      {isModalVisiable ? (
        <ImageZoomModal
          data={imageSrc}
          setVisible={setIsModalVisiable}
          isScroll={false}
        />
      ) : null}
    </>
  );
}

export default ReviewItem;
