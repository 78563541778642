import React, { useEffect, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";

import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import { route } from "../../router/route";
import PromotionApi from "../../api/promotionApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Pagination from "../../components/organisms/pagination";
import moment from "moment";
import Empty from "../../components/organisms/empty";

const ExhibitionList = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useParams();

  const [exhibitionList, setExhibitionList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 4, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    promotionListFunc();
  }, [location]);

  const promotionListFunc = async () => {
    try {
      setExhibitionList([]);

      const params = {
        page: page,
        size: pagination.size,
      };

      const response = (await PromotionApi.getList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setExhibitionList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ExhibitionListSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isSmall ? "0px 20px" : "0px",
            width: isSmall ? "100%" : "auto",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              color: "#313131",
              fontWeight: 600,
              marginBottom: isMobile ? 40 : 60,
            }}
          >
            Khuyến mãi đặc biệt
          </PretendardText>

          <div
            style={{
              display: "flex",
              width: "100%",
              gap: isMobile ? "24px 0px" : "40px 28px",
              flexDirection: "row",
              justifyContent: isSmall ? "center" : "flex-start",
              flexWrap: "wrap",
            }}
          >
            {exhibitionList.length > 0 ? (
              exhibitionList.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      navigate(
                        `${route.exhibition_list_detail}/${item.id}/1?&bannerImage=${item.bannerImageServerFileName}`
                      );
                    }}
                    style={{
                      width: isMobile ? "100%" : 576,
                      cursor: "pointer",
                    }}
                    key={index}
                  >
                    <img
                      style={{
                        width: "100%",
                        minHeight: isMobile ? 186 : 320,
                        maxHeight: isMobile ? 186 : 320,
                        borderRadius: 12,
                        marginBottom: 20,
                      }}
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                      alt="plan01"
                    />
                    <PretendardText
                      style={{
                        fontSize: isMobile ? 16 : 20,
                        fontWeight: 600,
                        lineHeight: isMobile ? "23.2px" : "28px",
                        color: "#313131",
                        marginBottom: isMobile ? 3 : 6,
                      }}
                    >
                      {item.title}
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: isMobile ? 14 : 16,
                        fontWeight: 500,
                        lineHeight: isMobile ? "19.6px" : "23.2px",
                        color: "#ABABAB",
                      }}
                    >
                      {moment(item.startDate).format("YYYY.MM.DD")} ~
                      {moment(item.endDate).format("YYYY.MM.DD")}
                    </PretendardText>
                  </div>
                );
              })
            ) : (
              <Empty title={"Chúng mình đang chuẩn bị."} />
            )}
          </div>

          {exhibitionList.length > 0 && pagination.totalCount > 4 ? (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Pagination
                route={route.exhibition_list}
                queryString={""}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ExhibitionListSection>
    </>
  );
};

export default ExhibitionList;

const ExhibitionListSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
`;
