import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/text/pretendardText";

const PopupModal = ({
  setVisible,
  data,
  btnOnPress,
  btnConfirmPress,
  isScroll,
}) => {
  const swiperRef = useRef();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  return (
    <ModalWrapper $isMobile={isMobile}>
      <ModalContain $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: isMobile ? 288 : 388,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              position: "relative",
            }}
          >
            <Swiper
              modules={[Autoplay, Navigation]}
              onSwiper={(it) => {
                swiperRef.current = it;
              }}
              slidesPerView={1}
              centeredSlides={true}
              spaceBetween={0}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex);
              }}
            >
              {data.images && data.images.length > 0
                ? data.images.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          style={{
                            width: "100%",
                            height: isMobile ? 288 : 388,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                          }}
                          alt=""
                        />
                      </SwiperSlide>
                    );
                  })
                : null}
            </Swiper>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "absolute",
                bottom: 18,
                zIndex: 10,
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {Array(data.images.length)
                .fill(0)
                .map((item02, index02) => {
                  return (
                    <div
                      key={index02}
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: 99,
                        background:
                          index02 === activeIndex
                            ? "#FFFFFF"
                            : "rgba(255, 255, 255, 0.5)",
                        marginRight:
                          data.images.length === index02 + 1 ? 0 : 10,
                      }}
                    />
                  );
                })}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PretendardText
              onClick={() => {
                btnOnPress();
              }}
              style={{
                flex: 1,
                fontSize: isMobile ? 12 : 16,
                lineHeight: "23.2px",
                fontWeight: 500,
                textAlign: "center",
                cursor: "pointer",
                padding: "18px 0px 21px 0px",
                borderRight: "1px solid #F1F1F1",
              }}
            >
              Không xem trong một ngày
            </PretendardText>
            <PretendardText
              onClick={() => {
                btnConfirmPress();
              }}
              style={{
                flex: 1,
                fontSize: isMobile ? 12 : 16,
                lineHeight: "23.2px",
                fontWeight: 500,
                textAlign: "center",
                cursor: "pointer",
                padding: "18px 0px 21px 0px",
              }}
            >
              sự đóng cửa
            </PretendardText>
          </div>
        </div>
      </ModalContain>
    </ModalWrapper>
  );
};

export default PopupModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 70px 0; */
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: ${(props) => (props.$isMobile ? "335px" : "450px")};
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 8px 8px 35px 0px #00000073;
`;
