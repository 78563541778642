import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { route } from "../../router/route";

import PretendardText from "../atoms/text/pretendardText";
import InputSearch from "../atoms/input/inputSearch";
import GrayBorderBtn from "../atoms/button/grayBorderBtn";

import { toast } from "react-toastify";
import {
  headerMenuState,
  headerTriggerState,
  isLoginTrigger,
} from "../../store/headerState";
import CartApi from "../../api/cartApi";
import { errorHandler } from "../../util/errorHandler";
import { useCookies } from "react-cookie";

const HeaderM = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["autoLogin"]);

  const headerTriggerStateAtom = useRecoilValue(headerTriggerState);
  const isLoginTriggerAtom = useRecoilValue(isLoginTrigger);
  const headerMenuStateAtom = useRecoilValue(headerMenuState);

  const [isSecondOpen, setIsSecondOpen] = useState(-1);

  const [keyword, setKeyword] = useState("");
  const [isMenuOn, setIsMenuOn] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");
    let role = sessionStorage.getItem("role");
    let name = sessionStorage.getItem("name");
    let profile = sessionStorage.getItem("profile");

    setIsLogin(isLogin);
    setRole(role);
    setName(name);
    setProfile(profile);

    if (isLogin) {
      cartListFunc();
    }
  }, [pathname, headerTriggerStateAtom, isLoginTriggerAtom]);

  const logoutFunc = async () => {
    removeCookie("autoLogin", { path: "/" });
    sessionStorage.clear();

    window.location.href = route.login + "?refresh=true";
  };

  const handleCopyClipBoard = async () => {
    try {
      let recommendID = sessionStorage.getItem("recommendID");

      await navigator.clipboard.writeText(
        // `https://lsl3jsvf4799.edge.naverncp.com/koviet/register?type=CONSUMER&recommendID=${recommendID}` // test
        `https://koviet.vn/koviet/register?type=CONSUMER&recommendID=${recommendID}` // 실제주소
      );
      toast("Liên kết người giới thiệu đã được sao chép.");
    } catch (err) {
      console.log(err);
    }
  };

  const cartListFunc = async () => {
    try {
      const params = {
        page: 1,
        size: 0,
      };

      const response = (await CartApi.getList(params)).data.data;
      setCartTotal(response.totalCount);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <HeaderMSection>
      {isLogin ? null : (
        <HeaderMTopWrapper>
          <div
            onClick={() => {
              navigation(`${route.login}`);
            }}
            style={{
              background: "#fff",
              padding: "4px 10px",
              marginRight: 20,
              borderRadius: 6,
              cursor: "pointer",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#434343",
                fontWeight: 500,
              }}
            >
              Đăng nhập
            </PretendardText>
          </div>
          <img
            onClick={() => {
              navigation(`${route.register_type}`);
            }}
            style={{
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
            src="/assets/icons/user_add.png"
            alt="user_add"
          />
        </HeaderMTopWrapper>
      )}

      <HeaderMWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => {
              setIsMenuOn(true);
            }}
            style={{
              width: 38,
              height: 38,
              cursor: "pointer",
              marginRight: 12,
            }}
            src={"/assets/icons/menu_d.png"}
            alt="menu"
          />

          <img
            onClick={() => {
              navigation(`${route.home}`);
            }}
            style={{
              width: 95,
              height: 27.14,
              cursor: "pointer",
            }}
            src="/assets/imgs/logo01.png"
            alt="logo01"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              marginRight: 16,
              width: 30,
              height: 30,
            }}
          >
            <img
              onClick={() => {
                if (isLogin) {
                  navigation(route.shop_basket);
                } else {
                  navigation(route.login);
                }
              }}
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
              }}
              src="/assets/icons/basket.png"
              alt="basket"
            />

            {cartTotal > 0 ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: -1,
                  width: 14,
                  height: 14,
                  borderRadius: 999,
                  background: "#FF3D83",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 11,
                    lineHeight: "13.13px",
                    color: "#fff",
                    fontWeight: 700,
                  }}
                >
                  {cartTotal}
                </PretendardText>
              </div>
            ) : null}
          </div>
          <img
            onClick={() => {
              if (isLogin) {
                navigation(route.wish_list);
              } else {
                navigation(route.login);
              }
            }}
            style={{
              width: 30,
              height: 30,
              marginRight: 16,
              cursor: "pointer",
            }}
            src="/assets/icons/wish.png"
            alt="wish"
          />

          <img
            onClick={() => {
              if (isLogin) {
                navigation(`${route.mypage_order_list}/1`);
              } else {
                navigation(route.login);
              }
            }}
            style={{
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
            src="/assets/icons/mypage.png"
            alt="mypage"
          />

          {role === "INFLUENCER" ? (
            <img
              onClick={() => {
                navigation(`${route.dashboard}/1`);
              }}
              style={{
                width: 30,
                height: 30,
                marginLeft: 16,
                cursor: "pointer",
              }}
              src="/assets/icons/ar.png"
              alt="ar"
            />
          ) : null}
        </div>
      </HeaderMWrapper>

      {isMenuOn ? (
        <SubHeaderWrapper>
          <SubHeaderContainer>
            <div
              style={{
                padding: "30px 20px 24px 20px",
                background: "#FCF6F8",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  onClick={() => {
                    navigation(`${route.home}`);
                    setIsMenuOn(false);
                  }}
                  src="/assets/imgs/logo01.png"
                  alt=""
                  style={{
                    width: 110,
                    height: 31,
                    cursor: "pointer",
                  }}
                />
                <img
                  src="/assets/icons/ic-closed-30.png"
                  alt=""
                  style={{
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => setIsMenuOn(false)}
                />
              </div>

              {isLogin ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 24,
                    marginBottom: 24,
                  }}
                >
                  <img
                    onClick={() => {
                      navigation(route.mypage_account_manage);
                      setIsMenuOn(false);
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: 4,
                      borderRadius: 100,
                      cursor: "pointer",
                    }}
                    src={
                      profile
                        ? `${process.env.REACT_APP_MEDIA_URL}${profile}`
                        : "/assets/icons/empty_person.png"
                    }
                    alt="empty_person"
                  />
                  <UserNameText
                    onClick={() => {
                      navigation(route.mypage_account_manage);
                      setIsMenuOn(false);
                    }}
                  >
                    MY
                  </UserNameText>

                  <div
                    onClick={() => {
                      handleCopyClipBoard();
                    }}
                    style={{
                      cursor: "pointer",
                      minWidth: 108,
                      height: 33,
                      border: "1px solid #FFD8E6",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0px 8px",
                      borderRadius: 8,
                      background: "#fff",
                    }}
                  >
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 1,
                      }}
                      src="/assets/icons/content_copy.png"
                      alt="content_copy"
                    />
                    <PretendardText
                      style={{
                        fontSize: 12,
                        lineHeight: "16.8px",
                        color: "#626262",
                        fontWeight: 500,
                      }}
                    >
                      Liên kết gợi ý
                    </PretendardText>
                  </div>
                </div>
              ) : (
                <GrayBorderBtn
                  onClick={() => {
                    navigation(`${route.login}`);
                    setIsMenuOn(false);
                  }}
                  style={{
                    height: 47,
                    marginBottom: 14,
                    marginTop: 28,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 600,
                    }}
                  >
                    Đăng nhập
                  </PretendardText>
                </GrayBorderBtn>
              )}

              <InputSearch
                style={{
                  background: "#ffffff",
                }}
                placeholder={"Tìm kiếm"}
                value={keyword}
                onChange={(value) => {
                  setKeyword(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsMenuOn(false);
                    navigation(`${route.search}/1?keyword=${keyword}`);

                    setKeyword("");
                  }
                }}
                searchStyle={{
                  top: 9,
                }}
                setDataToKeyword={() => {
                  setIsMenuOn(false);
                  navigation(`${route.search}/1?keyword=${keyword}`);

                  setKeyword("");
                }}
              />
            </div>

            <div>
              {headerMenuStateAtom.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <MenuWrapper>
                      <Menu
                        $active={isSecondOpen === index}
                        onClick={() => {
                          if (item.childCategories.length > 0) {
                            if (isSecondOpen === index) {
                              setIsSecondOpen(-1);
                            } else {
                              setIsSecondOpen(index);
                            }

                            return;
                          }

                          setIsMenuOn(false);
                          if (item.src) {
                            navigation(item.src);
                          } else {
                            navigation(
                              `${route.category_product}/1?categoryValue=${item.id}`
                            );
                          }
                        }}
                      >
                        {item.title}
                      </Menu>
                      {item.childCategories.length > 0 ? (
                        <img
                          src={
                            isSecondOpen === index
                              ? "/assets/icons/menu_up.png"
                              : "/assets/icons/menu_down.png"
                          }
                          alt=""
                          onClick={() => {
                            if (isSecondOpen === index) {
                              setIsSecondOpen(-1);
                            } else {
                              setIsSecondOpen(index);
                            }
                          }}
                          style={{
                            width: 28,
                            height: 28,
                            cursor: "pointer",
                          }}
                        />
                      ) : null}
                    </MenuWrapper>

                    {isSecondOpen === index && item.childCategories
                      ? item.childCategories?.map((item2, index2) => {
                          return (
                            <React.Fragment key={index2}>
                              <SecondMenuWrapper
                                $active={item2.link === pathname}
                              >
                                <SecondMenu
                                  $active={item2.link === pathname}
                                  onClick={() => {
                                    setIsMenuOn(false);
                                    if (item.src) {
                                      navigation(item.src);
                                    } else {
                                      navigation(
                                        `${route.category_product}/1?categoryValue=${item.id}&subCategoryValue=${item2.id}`
                                      );
                                    }
                                  }}
                                >
                                  {item2.title}
                                </SecondMenu>
                              </SecondMenuWrapper>
                            </React.Fragment>
                          );
                        })
                      : null}
                  </React.Fragment>
                );
              })}
            </div>

            <div
              onClick={() => {
                navigation(`${route.customer_center}/1`);
                setIsMenuOn(false);
              }}
              style={{
                cursor: "pointer",
                borderRadius: 6,
                margin: "30px 20px",
                border: "2px solid #E8E8E8",
                width: 166,
                height: 36,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#313131",
                  fontWeight: 600,
                }}
              >
                Trung tâm khách hàng
              </PretendardText>
            </div>

            <div
              onClick={() => {
                logoutFunc();
                setIsMenuOn(false);
              }}
              style={{
                margin: "0px 20px",
                cursor: "pointer",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#ABABAB",
                  fontWeight: 500,
                  textDecoration: "underline",
                }}
              >
                Đăng xuất
              </PretendardText>
            </div>
          </SubHeaderContainer>
        </SubHeaderWrapper>
      ) : null}
    </HeaderMSection>
  );
};

export default HeaderM;

const HeaderMSection = styled.div`
  position: sticky;
  top: 0;
  z-index: 98;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  border-bottom: 1px solid #dcdcdc;
`;

const HeaderMTopWrapper = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: #fae9ef;
  padding: 0px 20px;
`;

const HeaderMWrapper = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0px 20px;
`;

const SubHeaderWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  overflow-y: auto;
`;

const SubHeaderContainer = styled.div`
  position: relative;
  width: 320px;
  min-height: 100%;
  background: #fff;
  padding-bottom: 30px;
  z-index: 10;
`;

const UserNameText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  color: #313131;
  flex: 1;
  cursor: pointer;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const MenuWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-bottom: 1px solid #e8e8e8;
`;

const Menu = styled(PretendardText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 23.2px;
  color: ${(props) => (props.$active ? "#313131" : "#626262")};
  cursor: pointer;
`;

const SecondMenuWrapper = styled.div`
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 35px;
  border-bottom: 1px solid #e8e8e8;
  background-color: ${(props) => (props.$active ? "#FFF6FA" : "#fafafa")};
`;

const SecondMenu = styled(PretendardText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 23.2px;
  color: ${(props) => (props.$active ? "#FF649C" : "#626262")};
  cursor: pointer;
`;
