import React, { useMemo } from "react";

import PretendardText from "../atoms/text/pretendardText";
import useDisplay from "../../hooks/useDisplay";

function Empty({ title }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <PretendardText
          style={{
            fontSize: isMobile ? 16 : 18,
            fontWeight: 600,
            color: "rgb(147, 147, 147)",
          }}
        >
          {title}
        </PretendardText>
      </div>
    </>
  );
}

export default Empty;
