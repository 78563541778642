import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";
import Checkbox from "../../atoms/checkbox/checkbox";
import GrayBorderBtn from "../../atoms/button/grayBorderBtn";

import { route } from "../../../router/route";

function ProductBasketItem({
  data,
  style,
  setDIDList,
  dIDList,
  basketProductList,
  setAllCheck,
}) {
  const navigation = useNavigate();
  let optionInfo = JSON.parse(data.optionInfo);

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  function amountCalFunc(optionFlag) {
    let amount = 0;

    if (optionFlag) {
      for (let i = 0; i < optionInfo.length; i++) {
        amount += optionInfo[i].optionValue.price;
      }
      amount += data.merch.salePrice;
    } else {
      amount = data.noOptionQuantity * data.merch.salePrice;
    }

    // 소수점 첫째 자리에서 반올림
    return amount;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          padding: 20,
          borderTop: "1px solid #DCDCDC",
          flex: 1,
          ...style,
        }}
      >
        <Checkbox
          checked={dIDList.some((item) => item === data.id)}
          size={24}
          onChange={() => {
            let temp;
            if (dIDList.some((item) => item === data.id)) {
              temp = dIDList.filter((item) => item !== data.id);
              setDIDList(temp);
            } else {
              temp = [...dIDList, data.id];
              setDIDList(temp);
            }

            const idList = basketProductList.map((item) => item.id);
            const allValuesExist =
              temp.length > 0 && idList.every((value) => temp.includes(value));

            if (allValuesExist) {
              setAllCheck(true);
            } else {
              setAllCheck(false);
            }
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 15,
          }}
        >
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}${data.merch.imageServerFileName}`}
            className="sample04"
            style={{
              width: 120,
              height: 120,
              borderRadius: 8,
              marginRight: 15,
            }}
          />
          <div
            style={{
              width: 291,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#222222",
                fontWeight: 600,
                marginBottom: 4,
              }}
            >
              {data.merch.title}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#939393",
                fontWeight: 600,
                marginBottom: 4,
              }}
            >
              {data.merch.brand}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#ABABAB",
                fontWeight: 400,
                marginBottom: 2,
                textDecoration: "line-through",
              }}
            >
              {Number(data.merch.originalPrice).toLocaleString()} đồng
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "26.1px",
                  color: "#313131",
                  fontWeight: 700,
                  marginRight: 6,
                }}
              >
                {Number(data.merch.salePrice).toLocaleString()} đồng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "26.1px",
                  color: "#FF649C",
                  fontWeight: 700,
                }}
              >
                {calculateDiscountRate(
                  Number(data.merch.originalPrice),
                  Number(data.merch.salePrice)
                )}
                %
              </PretendardText>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "4px 45px",
            width: 1,
            background: "#DCDCDC",
            minHeight: 120,
          }}
        />

        <div
          style={{
            width: 166,
          }}
        >
          {data.optionFlag ? (
            optionInfo.map((item, index) => {
              return (
                <PretendardText
                  key={index}
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#939393",
                    fontWeight: 400,
                    marginBottom: optionInfo.length === index + 1 ? 10 : 0,
                    textAlign: "center",
                  }}
                >
                  {`${item.option.title}: ${item.optionValue.title}, ${item.optionValue.quantity}`}
                </PretendardText>
              );
            })
          ) : (
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#939393",
                fontWeight: 400,
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              Số lượng: {data.noOptionQuantity}
            </PretendardText>
          )}

          <GrayBorderBtn
            onClick={() => {
              navigation(`${route.product_detail}/${data.merch.id}`, {
                state: {
                  optionInfo: optionInfo,
                  amountState: data.noOptionQuantity,
                },
              });
            }}
            style={{
              width: 165,
              height: 47,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Sửa đổi tùy chọn
            </PretendardText>
          </GrayBorderBtn>
        </div>

        <div
          style={{
            margin: "4px 45px",
            width: 1,
            background: "#DCDCDC",
            minHeight: 120,
          }}
        />

        <div
          style={{
            width: 329,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // marginBottom: 15,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "19.09px",
                color: "#222222",
                fontWeight: 400,
              }}
            >
              Tổng tiền hàng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "19.09px",
                color: "#222222",
                fontWeight: 700,
              }}
            >
              {amountCalFunc(data.optionFlag).toLocaleString()} đồng
            </PretendardText>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "19.09px",
                color: "#222222",
                fontWeight: 400,
              }}
            >
              Phí giao hàng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "19.09px",
                color: "#222222",
                fontWeight: 700,
              }}
            >
              {Number(data.merch.deliveryCost).toLocaleString()} đồng
            </PretendardText>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ProductBasketItem;
