import { route } from "../router/route";

export const menus = [
  {
    title: "Danh mục",
    src: `${route.category_product}/1`,
  },
  {
    title: "Sản phẩm mới",
    src: `${route.new_product}/1`,
  },
  {
    title: "Bán chạy",
    src: `${route.best_product}/1`,
  },
  {
    title: "Sự kiện tháng",
    src: `${route.event_product}/1`,
  },
  {
    title: "Khuyến mãi đặc biệt",
    src: `${route.exhibition_list}/1`,
  },
  {
    title: "TOP Influencers",
    src: `${route.influencer_list}/1`,
  },
];
