import { route } from "../router/route";

export const skinSubMenu = [
  {
    title: "làm sạch",
    isActive: true,
  },
  {
    title: "tông màu da",
    isActive: false,
  },
  {
    title: "Ampoule/Serum/Tinh chất",
    isActive: false,
  },
  {
    title: "Sữa dưỡng/Kem",
    isActive: false,
  },
  {
    title: "chống nắng",
    isActive: false,
  },
];

export const baseSubMenu = [
  {
    title: "nhạy cảm / rắc rối",
    isActive: true,
  },
  {
    title: "Làm trắng / trắng",
    isActive: false,
  },
  {
    title: "nếp nhăn / đàn hồi",
    isActive: false,
  },
  {
    title: "lỗ chân lông / bã nhờn",
    isActive: false,
  },
];

export const bodySubMenu = [
  {
    title: "sữa tắm",
    isActive: true,
  },
  {
    title: "dùng body scrub",
    isActive: false,
  },
  {
    title: "kem dưỡng thể",
    isActive: false,
  },
  {
    title: "dầu gội đầu",
    isActive: false,
  },
  {
    title: "Tinh tế về tóc",
    isActive: false,
  },
];

export const healthSubMenu = [
  {
    title: "chất dinh dưỡng",
    isActive: true,
  },
  {
    title: "thuốc bổ",
    isActive: false,
  },
  {
    title: "đồ uống chức năng",
    isActive: false,
  },
];

export const myShoppingMenu = [
  {
    title: "Yêu cầu đặt hàng",
    isActive: false,
    routeName: `${route.mypage_order_list}/1`,
    activeName: "/order_list",
  },
  {
    title: "Quản lý trao đổi/trả lại",
    isActive: false,
    routeName: `${route.mypage_exchange_list}/1`,
    activeName: "/exchange_list",
  },
];

export const myActivityMenu = [
  {
    title: "Quản lý đánh giá",
    isActive: false,
    routeName: `${route.mypage_review_list}/1`,
    activeName: "/review_list",
  },
  {
    title: "Quản lý điểm/Phiếu giảm giá",
    isActive: false,
    routeName: `${route.mypage_point_manage}/1`,
    activeName: "/point_manage",
  },
  {
    title: "báo thức",
    isActive: false,
    routeName: `${route.mypage_notification_list}/1`,
    activeName: "/notification_list",
  },
  {
    title: "cuộc điều tra",
    isActive: false,
    routeName: `${route.mypage_inquiry_list}/1`,
    activeName: "/inquiry_list",
  },
];

export const myActivityMenuIF = [
  {
    title: "bảng điều khiển",
    isActive: false,
    routeName: `${route.dashboard}/1`,
    activeName: "dashb",
  },
  {
    title: "Quản lý đánh giá",
    isActive: false,
    routeName: `${route.mypage_review_list}/1`,
    activeName: "/review_list",
  },
  {
    title: "Quản lý điểm/Phiếu giảm giá",
    isActive: false,
    routeName: `${route.mypage_point_manage}/1`,
    activeName: "/point_manage",
  },
  {
    title: "báo thức",
    isActive: false,
    routeName: `${route.mypage_notification_list}/1`,
    activeName: "/notification_list",
  },
  {
    title: "cuộc điều tra",
    isActive: false,
    routeName: `${route.mypage_inquiry_list}/1`,
    activeName: "/inquiry_list",
  },
];

export const myInfoMenu = [
  {
    title: "Quản lý tài khoản",
    isActive: false,
    routeName: route.mypage_account_manage,
    activeName: "/account_manage",
  },
  {
    title: "Hủy đăng ký",
    isActive: false,
    routeName: route.mypage_withdraw,
    activeName: "/withdraw",
  },
];
