import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import useDisplay from "../../../hooks/useDisplay";

const ImageZoomModal = ({ setVisible, data, isScroll }) => {
  const swiperRef = useRef();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  return (
    <ModalWrapper $isMobile={isMobile}>
      <ModalContain $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <img
            onClick={() => {
              setVisible(false);
            }}
            style={{
              width: 30,
              height: 30,
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 10,
              zIndex: 10,
            }}
            src={"/assets/icons/ic-closed-30.png"}
            alt="ic-closed-30"
          />

          <div
            style={{
              height: "auto",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              position: "relative",
              width: "64%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}${data}`}
              style={{
                width: "64%",
                height: "auto",
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
              }}
              alt=""
            />
          </div>
        </div>
      </ModalContain>
    </ModalWrapper>
  );
};

export default ImageZoomModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 70px 0; */
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: ${(props) => (props.$isMobile ? "335px" : "800px")};
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 8px 8px 35px 0px #00000073;
`;
