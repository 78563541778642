import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import TitleInput from "../../molecules/input/titleInput";
import Select from "../../atoms/select/select";
import CameraFileForm from "../../atoms/fileForm/cameraFileForm";

import useDisplay from "../../../hooks/useDisplay";
import CommonApi from "../../../api/commonApi";
import { errorHandler } from "../../../util/errorHandler";
import OrderApi from "../../../api/orderApi";

function PayInqueryModal({ visiable, setVisiable, isScroll, orderId }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [selectValue01, setSelectValue01] = useState("");
  const [selectList01, setSelectList01] = useState([
    {
      value: "Hỏi đáp sản phẩm",
      label: "Hỏi đáp sản phẩm",
    },
    {
      value: "Yêu cầu trao đổi/trả lại",
      label: "Yêu cầu trao đổi/trả lại",
    },
    {
      value: "Yêu cầu hủy/hoàn tiền",
      label: "Yêu cầu hủy/hoàn tiền",
    },
    {
      value: "Yêu cầu giao hàng",
      label: "Yêu cầu giao hàng",
    },
    {
      value: "Các yêu cầu khác",
      label: "Các yêu cầu khác",
    },
  ]);

  const [content, setContent] = useState("");
  const [contentErrorM, setContentErrorM] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  const handleChange = async (e) => {
    if (selectedFiles.length >= 3) {
      toast("Tối đa 3 trang");

      return;
    }

    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 3) {
      toast("Tối đa 3 trang");
      return;
    }
    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop()
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          imageOriginFileName: file.name,
          imageServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setSelectedFiles((prevFiles) => [...prevFiles, fileObject]);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType) => {
    try {
      const obj = {
        path: "ask/",
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const deleteFile = (fileName) => {
    let temp = selectedFiles.filter(
      (item) => item.imageServerFileName != fileName
    );
    setSelectedFiles(temp);
  };

  const orderAskFunc = async () => {
    try {
      let images = selectedFiles.map((item) => {
        return {
          imageOriginFileName: item.imageOriginFileName,
          imageServerFileName: item.imageServerFileName,
        };
      });

      const params = {
        askType:
          selectValue01 === "Hỏi đáp sản phẩm"
            ? "MERCH"
            : selectValue01 === "Yêu cầu trao đổi/trả lại"
            ? "CHANGE"
            : selectValue01 === "Yêu cầu hủy/hoàn tiền"
            ? "CANCEL"
            : selectValue01 === "Yêu cầu giao hàng"
            ? "DELIVERY"
            : "ETC",
        question: content,
        images: images,
      };

      await OrderApi.orderAsk(orderId, params);

      setVisiable(false);
      toast("Câu hỏi đã được đăng ký.");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  return (
    <PayInqueryModalWrapper $isMobile={isMobile}>
      <PayInqueryModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: 30,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Liên hệ chúng tôi
            </PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
              }}
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Loại yêu cầu
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#FF3D83",
                  fontWeight: 700,
                }}
              >
                *
              </PretendardText>
            </div>
            <Select
              style={{
                marginBottom: 20,
              }}
              setValue={setSelectValue01}
              value={selectValue01}
              dataList={selectList01}
              placeholder={"Vui lòng chọn một mục."}
            />

            <TitleInput
              isTextarea={true}
              title={"Chi tiết yêu cầu"}
              essential={true}
              placeholder={"Vui lòng nhập nội dung."}
              type={"textare"}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
              warningMessage={contentErrorM}
              style={{
                marginBottom: 20,
              }}
              inputStyle={{
                height: 185,
              }}
            />

            {selectedFiles.length > 0 ? (
              <MenuOverflowXHidden>
                {selectedFiles.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        onClick={() => {
                          deleteFile(item.imageServerFileName);
                        }}
                        style={{
                          width: 72,
                          height: 72,
                          cursor: "pointer",
                          borderRadius: 8,
                        }}
                        src={item.fileUrl}
                        alt="file"
                      />

                      <img
                        onClick={() => {
                          deleteFile(item.imageServerFileName);
                        }}
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          position: "absolute",
                          top: 3,
                          right: 3,
                        }}
                        src="/assets/icons/subBTn_7_deleteBTN.png"
                        alt="subBTn_7_deleteBTN"
                      />
                    </div>
                  );
                })}
              </MenuOverflowXHidden>
            ) : null}

            <CameraFileForm multiple={"multiple"} handleChange={handleChange} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 40,
              width: "100%",
            }}
          >
            <MainBtn
              disabled={selectValue01 && content ? false : true}
              onClick={() => {
                orderAskFunc();
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Liên hệ chúng tôi
              </PretendardText>
            </MainBtn>
            <PretendardText
              style={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: 8,
              }}
            >
              {isMobile
                ? "Bạn có thể tìm thấy câu trả lời cho thắc mắc của mình tại Trang của tôi > Yêu cầu."
                : "Bạn có thể tìm thấy câu trả lời cho thắc mắc của mình tại Trang của tôi\n> Yêu cầu."}
            </PretendardText>
          </div>
        </div>
      </PayInqueryModalContainer>
    </PayInqueryModalWrapper>
  );
}

export default PayInqueryModal;

const PayInqueryModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const PayInqueryModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "520px")};
  padding: ${(props) => (props.$isMobile ? "40px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  width: 100%;

  gap: 12px;

  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
