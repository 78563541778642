import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import Pagination from "../../organisms/pagination";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MemberApi from "../../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import { route } from "../../../router/route";
import moment from "moment";
import Empty from "../../organisms/empty";

function UseDetails({ parentCategory }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [categoryValue, setCategoryValue] = useState("Tất cả");
  const [categoryList, setCategoryList] = useState([
    { title: "Tất cả" },
    { title: "Tích lũy" },
    { title: "Sử dụng" },
    { title: "Hết hiệu lực" },
  ]);

  const [useDataList, setUseDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    setCategoryValue(
      searchParams.get("subCategory")
        ? searchParams.get("subCategory")
        : "Tất cả"
    );

    pointListFunc();
  }, [location]);

  const pointListFunc = async () => {
    try {
      let data;

      if (
        searchParams.get("subCategory") === "Tất cả" ||
        searchParams.get("subCategory") == null
      ) {
        data = {
          page: page,
          size: pagination.size,
        };
      } else {
        data = {
          page: page,
          size: pagination.size,
          pointType:
            searchParams.get("subCategory") === "Tích lũy"
              ? "REWARD"
              : searchParams.get("subCategory") === "Sử dụng"
              ? "USED"
              : searchParams.get("subCategory") === "Hết hiệu lực"
              ? "EXPIRED"
              : "",
        };
      }

      let response = (await MemberApi.getPointList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setUseDataList([]);
      setUseDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const pointTypeReturn = (type) => {
    switch (type) {
      case "REVIEW":
        return "Kiếm được đánh giá";

      case "PHOTO":
        return "Tích lũy đánh giá ảnh";

      case "REWARD":
        return "Thành viên tích lũy";

      case "USED":
        return "Sử dụng khi thanh toán";

      case "EXPIRED":
        return "Hết hiệu lực";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
          position: "relative",
          marginTop: isMobile ? 20 : 24,
        }}
      >
        <MenuOverflowXHidden $isMobile={isMobile}>
          {categoryList.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  marginRight: isMobile ? 8 : 12,
                  cursor: "pointer",
                  padding: isMobile ? "4px 12px" : "8px 12px",
                  border:
                    categoryValue === item.title
                      ? "1px solid #FF649C"
                      : "1px solid #E8E8E8",
                  borderRadius: 6,
                  background: categoryValue === item.title ? "#FF649C" : "#fff",
                }}
                onClick={() => {
                  navigate(
                    `${route.mypage_point_manage}/1?category=${parentCategory}&subCategory=${item.title}`
                  );
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    fontWeight: categoryValue === item.title ? 700 : 500,
                    color: categoryValue === item.title ? "#ffffff" : "#626262",
                    width: "max-content",
                  }}
                >
                  {item.title}
                </PretendardText>
              </div>
            );
          })}
        </MenuOverflowXHidden>
        {useDataList.length > 0 ? (
          useDataList.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                  borderBottom:
                    useDataList.length === index + 1
                      ? "none"
                      : "1px solid #DCDCDC",
                  padding: "20px 0px",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    fontWeight: 400,
                    color: "#313131",
                    marginBottom: isMobile ? 8 : 0,
                  }}
                >
                  {moment(item.createdAt).format("YYYY.MM.DD")}
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isMobile ? "flex-start" : "flex-end",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      lineHeight: "26.1px",
                      fontWeight: 600,
                      color: item.isMinus ? "#FF5A5A" : "#5782FF",
                    }}
                  >
                    {item.isMinus ? "-" : "+"}
                    {Number(item.amount).toLocaleString()}
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "19.6px",
                      fontWeight: 400,
                      color: "#313131",
                      marginTop: 3,
                    }}
                  >
                    {pointTypeReturn(item.pointType)}
                  </PretendardText>
                </div>
              </div>
            );
          })
        ) : (
          <Empty title={"Không có điểm nhấn."} />
        )}

        {useDataList.length > 0 && pagination.totalCount > 10 ? (
          <div
            style={{
              marginTop: 48,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              route={route.mypage_point_manage}
              queryString={`category=${parentCategory}&subCategory=${categoryValue}`}
              currentPage={Number(page)}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default UseDetails;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "28px")};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
