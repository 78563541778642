import api from "./api.js";

export default class BannerApi {
  static async getMainList() {
    return await api.get(`main-banner`, { page: 1, size: 100 });
  }

  static async getSubList(data) {
    return await api.get(`sub-banner`, data);
  }
}
