import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../components/atoms/text/pretendardText";
import PCContainer from "../components/atoms/layout/pcContainer";
import ProductItem from "../components/templates/product/productItem";
import FooterBtnGroup from "../components/templates/footerBtnGroup";
import Input from "../components/atoms/input/input";
import Pagination from "../components/organisms/pagination";
import SelectM from "../components/atoms/select/selectM";

import useDisplay from "../hooks/useDisplay";
import MerchApi from "../api/merchApi";
import { toast } from "react-toastify";
import { errorHandler } from "../util/errorHandler";
import { route } from "../router/route";
import Empty from "../components/organisms/empty";

const Search = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [keyword, setKeyword] = useState("");
  const [searchProductList, setSearchProductList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 20, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    {
      value: "Gần nhất",
      label: "Gần nhất",
    },
    {
      value: "Bán chạy",
      label: "Bán chạy",
    },
    {
      value: "Yêu thích",
      label: "Yêu thích",
    },
  ]);

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setFilterValue(
      searchParams.get("filterValue") ? searchParams.get("filterValue") : ""
    );

    merchListFunc();
  }, [location]);

  const merchListFunc = async () => {
    try {
      setSearchProductList([]);

      const params = {
        page: page,
        size: pagination.size,
        keyword: searchParams.get("keyword"),
        orderType:
          searchParams.get("filterValue") === "Yêu thích"
            ? 3
            : searchParams.get("filterValue") === "Bán chạy"
            ? 2
            : 1,
      };

      const response = (await MerchApi.getList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });
      setSearchProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <SearchSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isSmall ? "80px 20px 80px 20px" : "80px 0px 80px 0px",
            width: "100%",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              color: "#313131",
              fontWeight: 600,
              marginBottom: isMobile ? 40 : 28,
            }}
          >
            Kết quả tìm kiếm
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              width: "100%",
              justifyContent: "center",
              alignItems: isMobile ? "flex-start" : "center",
              background: "#F3F1F2",
              borderRadius: 12,
              height: isMobile ? 118 : 104,
              padding: isMobile ? 15 : 0,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 700,
                marginRight: isMobile ? 0 : 15,
                marginBottom: isMobile ? 10 : 0,
              }}
            >
              Tên sản phẩm / Từ khóa
            </PretendardText>
            <div
              style={{
                width: isMobile ? "100%" : 440,
              }}
            >
              <Input
                placeholder={"Vui lòng tìm kiếm tên sản phẩm."}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.search}/1?keyword=${keyword}`);
                  }
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: 80,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "space-between",
                marginBottom: isMobile ? 24 : 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: isMobile ? 40 : 0,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 20 : 24,
                    lineHeight: isMobile ? "28px" : "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                    marginRight: 10,
                  }}
                >
                  xịt khoáng
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    fontWeight: 500,
                    color: "#ABABAB",
                  }}
                >
                  Tổng cộng {pagination.totalCount} kết quả
                </PretendardText>
              </div>
              {isSmall ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  <SelectM
                    style={{}}
                    setValue={(value) => {
                      navigate(
                        `${route.search}/1?keyword=${keyword}&filterValue=${value}`
                      );
                    }}
                    value={filterValue}
                    dataList={filterList}
                    placeholder={"Lựa chọn"}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {filterList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CategoryText
                          onClick={() => {
                            navigate(
                              `${route.search}/1?keyword=${keyword}&filterValue=${item.value}`
                            );
                          }}
                          $isActive={filterValue === item.value}
                        >
                          {item.label}
                        </CategoryText>
                        {filterList.length === index + 1 ? null : (
                          <div
                            style={{
                              height: 12,
                              width: 1,
                              background: "#C4C4C4",
                              margin: "0px 8px",
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                gap: isMobile ? "24px 15px" : "40px 20px",
                flexDirection: "row",
                justifyContent: isSmall ? "space-between" : "flex-start",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {searchProductList.length > 0 ? (
                searchProductList.map((item, index) => {
                  return <ProductItem key={index} data={item} />;
                })
              ) : (
                <Empty title={"Vẫn chưa có bình luận."} />
              )}
            </div>
          </div>

          {searchProductList.length > 0 && pagination.totalCount > 5 ? (
            <div
              style={{
                marginTop: 44,
              }}
            >
              <Pagination
                route={route.search}
                queryString={`keyword=${keyword}&filterValue=${filterValue}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </SearchSection>
    </>
  );
};

export default Search;

const SearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CategoryText = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#ff649c" : "rgb(98, 98, 98)")};

  cursor: pointer;

  &:hover {
    color: #ff649c;
  }
`;
