import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import TitleInput from "../../molecules/input/titleInput";
import Input from "../../atoms/input/input";
import useDisplay from "../../../hooks/useDisplay";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import OrderApi from "../../../api/orderApi";

function OrderAddressModal({ visiable, setVisiable, isScroll, data }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [address, setAddress] = useState(data.address);
  const [addressDetail, setAddressDetail] = useState(data.detailAddress);

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  const orderAddressEditFunc = async () => {
    try {
      const param = {
        address: address,
        detailAddress: addressDetail,
      };

      const response = (await OrderApi.orderAddressEdit(data.id, param)).data
        .data;

      data.address = address;
      data.detailAddress = addressDetail;
      setVisiable(false);

      toast("Đã thay đổi địa điểm vận chuyển.");
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <OrderAddressModalWrapper $isMobile={isMobile}>
      <OrderAddressModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: 30,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Địa chỉ nhận hàng
            </PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
              }}
              style={{
                width: isMobile ? 20 : 30,
                height: isMobile ? 20 : 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 8,
                marginTop: 20,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Địa chỉ
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#FF3D83",
                  fontWeight: 700,
                }}
              >
                *
              </PretendardText>
            </div>
            <Input
              disabled={true}
              placeholder={"Hãy nhập địa chỉ."}
              type={"text"}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              value={address}
              style={{
                marginBottom: 10,
              }}
            />

            <Input
              placeholder={"Vui lòng nhập địa chỉ chi tiết."}
              type={"text"}
              onChange={(e) => {
                setAddressDetail(e.target.value);
              }}
              value={addressDetail}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 40,
              width: "100%",
            }}
          >
            <MainBtn
              onClick={() => {
                orderAddressEditFunc();
              }}
              disabled={address && addressDetail ? false : true}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Lưu
              </PretendardText>
            </MainBtn>
          </div>
        </div>
      </OrderAddressModalContainer>
    </OrderAddressModalWrapper>
  );
}

export default OrderAddressModal;

const OrderAddressModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const OrderAddressModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "520px")};
  padding: ${(props) => (props.$isMobile ? "40px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;
