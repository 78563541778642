import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";

import styled from "styled-components";
import moment from "moment";

function DashboardSellTable({ data }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <OverflowXHidden>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "fit-content",
            height: 53,
            alignItems: "center",
            background: "#FAFAFA",
          }}
        >
          <div
            style={{
              minWidth: 180,
              marginRight: 20,
            }}
          >
            <TableTitle>Thông tin người mua</TableTitle>
          </div>
          <div
            style={{
              minWidth: 380,
              marginRight: 20,
            }}
          >
            <TableTitle>Mặt hàng</TableTitle>
          </div>
          <div
            style={{
              minWidth: 180,
              marginRight: 20,
            }}
          >
            <TableTitle>Số tiền thanh toán</TableTitle>
          </div>
          <div
            style={{
              minWidth: 180,
              marginRight: 20,
            }}
          >
            <TableTitle>Ngày thanh toán</TableTitle>
          </div>
          <div
            style={{
              minWidth: 180,
            }}
          >
            <TableTitle>Tích điểm</TableTitle>
          </div>
        </div>
        {data && data.length > 0
          ? data.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "fit-content",
                    alignItems: "center",
                    borderTop: "1px solid #DCDCDC",
                    borderBottom:
                      data.length === index + 1 ? "1px solid #DCDCDC" : "none",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      minWidth: 180,
                      marginRight: 20,
                    }}
                  >
                    <TableContent>{item.email}</TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 380,
                      marginRight: 20,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      onClick={() => {}}
                      style={{
                        width: 56,
                        height: 56,
                        marginRight: 12,
                        borderRadius: 8,
                      }}
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                      alt="product01"
                    />
                    <div>
                      <TableContent
                        style={{
                          textAlign: "left",
                          color: "#313131",
                        }}
                      >
                        {item.title}
                      </TableContent>
                      {item.optionFlag ? (
                        JSON.parse(item.optionInfo).map((item02, index) => {
                          return (
                            <PretendardText
                              key={index}
                              style={{
                                fontSize: 16,
                                lineHeight: "23.2px",
                                fontWeight: 400,
                                color: "#313131",
                              }}
                            >
                              {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                            </PretendardText>
                          );
                        })
                      ) : (
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            fontWeight: 400,
                            color: "#313131",
                          }}
                        >
                          {`Số lượng: ${item.noOptionQuantity}`}
                        </PretendardText>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      minWidth: 180,
                      marginRight: 20,
                    }}
                  >
                    <TableContent>
                      {Number(item.payAmount).toLocaleString()}đ
                    </TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 180,
                      marginRight: 20,
                    }}
                  >
                    <TableContent>
                      {moment(item.createdAt).format("YYYY/MM/DD")}
                    </TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 180,
                    }}
                  >
                    <TableContent>
                      {Number(item.rewardPoint).toLocaleString()}P
                    </TableContent>
                  </div>
                </div>
              );
            })
          : null}
      </OverflowXHidden>
    </>
  );
}

export default DashboardSellTable;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  position: relative;

  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const TableTitle = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  color: #313131;
  font-weight: 600;
  text-align: center;
`;

const TableContent = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  color: #626262;
  font-weight: 400;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
