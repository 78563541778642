import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import Checkbox from "../../atoms/checkbox/checkbox";
import GrayBorderBtn from "../../atoms/button/grayBorderBtn";

function ProductWishItem({
  data,
  style,
  wishTriggerFunc,
  setDIDList,
  dIDList,
  setAllCheck,
  wishProdcutList,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          padding: 20,
          borderTop: "1px solid #DCDCDC",
          flex: 1,
          ...style,
        }}
      >
        <Checkbox
          checked={dIDList.some((item) => item === data.merch.id)}
          size={24}
          onChange={() => {
            let temp;
            if (dIDList.some((item) => item === data.merch.id)) {
              temp = dIDList.filter((item) => item !== data.merch.id);
              setDIDList(temp);
            } else {
              temp = [...dIDList, data.merch.id];
              setDIDList(temp);
            }

            const idList = wishProdcutList.map((item) => item.merch.id);
            const allValuesExist =
              temp.length > 0 && idList.every((value) => temp.includes(value));

            if (allValuesExist) {
              setAllCheck(true);
            } else {
              setAllCheck(false);
            }
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 15,
            flex: 1,
          }}
        >
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}${data.merch.imageServerFileName}`}
            className="sample04"
            style={{
              width: 120,
              height: 120,
              borderRadius: 8,
              marginRight: 15,
            }}
          />
          <div
            style={{
              width: 291,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#222222",
                fontWeight: 600,
                marginBottom: 4,
              }}
            >
              {data.merch.title}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#939393",
                fontWeight: 600,
                marginBottom: 4,
              }}
            >
              {data.merch.brand}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#ABABAB",
                fontWeight: 400,
                marginBottom: 2,
                textDecoration: "line-through",
              }}
            >
              {Number(data.merch.originalPrice).toLocaleString()}
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "26.1px",
                  color: "#313131",
                  fontWeight: 700,
                  marginRight: 6,
                }}
              >
                {Number(data.merch.salePrice).toLocaleString()}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "26.1px",
                  color: "#FF649C",
                  fontWeight: 700,
                }}
              >
                {calculateDiscountRate(
                  Number(data.merch.originalPrice),
                  Number(data.merch.salePrice)
                )}
                %
              </PretendardText>
            </div>
          </div>
        </div>

        <div
          style={{
            width: 169,
          }}
        >
          <GrayBorderBtn
            onClick={() => {
              navigation(`${route.product_detail}/${data.merch.id}`);
            }}
            style={{
              width: 169,
              height: 47,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Mua hàng
            </PretendardText>
          </GrayBorderBtn>
          <GrayBorderBtn
            onClick={() => {
              wishTriggerFunc(data.merch.id);
            }}
            style={{
              width: 169,
              height: 47,
              marginTop: 10,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Xóa bỏ
            </PretendardText>
          </GrayBorderBtn>
        </div>
      </div>
    </>
  );
}

export default ProductWishItem;
