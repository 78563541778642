import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";
import MypageMenuM from "../../components/templates/mypageMenuM";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import OrderApi from "../../api/orderApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Pagination from "../../components/organisms/pagination";
import Empty from "../../components/organisms/empty";

const ExchangeList = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useParams();

  const [totalCnt, setTotalCnt] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    exchangeListFunc();
  }, [location]);

  const exchangeListFunc = async () => {
    try {
      setDataList([]);

      const data = {
        page: page,
        size: pagination.size,
        orderStatus: "EXCHANGE",
      };

      const response = (await OrderApi.exchangeMerchList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setTotalCnt(response.totalCount);
      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const exchangeStateFunc = (state) => {
    switch (state) {
      case "PICKING_UP":
        return "Thu thập";
      case "PICKUP_COMPLETED":
        return "Bộ sưu tập đã hoàn thành";
      case "EXCHANGE_PENDING":
        return "Trao đổi đang chờ xử lý";
      case "REDELIVERING":
        return "Trao đổi và gửi lại";
      case "EXCHANGE_COMPLETED":
        return "Trao đổi hoàn tất";
      case "EXCHANGE_DENIED":
        return "Từ chối trao đổi";
    }
  };

  const returnStateFunc = (state) => {
    switch (state) {
      case "PICKING_UP":
        return "Thu thập";
      case "PICKUP_COMPLETED":
        return "Bộ sưu tập đã hoàn thành";
      case "RETURN_COMPLETED":
        return "Trả lại đã hoàn thành";
      case "REFUNDING":
        return "Đang hoàn tiền";
      case "REFUND_COMPLETED":
        return "Hoàn tiền hoàn tất";
      case "RETURN_DENIED":
        return "Từ chối trả lại";
    }
  };

  return (
    <>
      <ExchangeListSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : "32px 24px",
                background: "#fff",
                borderRadius: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                  marginBottom: isMobile ? 24 : 20,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "26.1px",
                    color: "#313131",
                    fontWeight: 700,
                    marginBottom: isMobile ? 4 : 0,
                  }}
                >
                  Đơn hàng hoàn thành
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "19.6px",
                    color: "#939393",
                    fontWeight: 400,
                  }}
                >
                  Tổng {totalCnt} đơn
                </PretendardText>
              </div>
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return isMobile ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: dataList.length === index + 1 ? 0 : 36,
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          padding: "4px 12px",
                          borderRadius: 6,
                          background: "#F5F5F5",
                          alignSelf: "baseline",
                          marginBottom: 16,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color:
                              item.exchangeStatus === "REFUND_COMPLETED" ||
                              item.returnStatus === "EXCHANGE_COMPLETED"
                                ? "#FF649C"
                                : "#626262",
                            fontWeight: 500,
                          }}
                        >
                          {item.orderStatus === "EXCHANGE"
                            ? exchangeStateFunc(item.exchangeStatus)
                            : returnStateFunc(item.returnStatus)}
                        </PretendardText>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: 0,
                          }}
                        >
                          <img
                            style={{
                              width: 72,
                              height: 72,
                              borderRadius: 6,
                              marginRight: 12,
                            }}
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                            alt="banner02"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <ProductTitle $isMobile={isMobile}>
                              {item.title}
                            </ProductTitle>
                            {item.optionFlag ? (
                              JSON.parse(item.optionInfo).map(
                                (item02, index) => {
                                  return (
                                    <PretendardText
                                      key={index}
                                      style={{
                                        fontSize: 14,
                                        lineHeight: "19.6px",
                                        color: "#939393",
                                        fontWeight: 400,
                                        marginBottom: 4,
                                      }}
                                    >
                                      {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                    </PretendardText>
                                  );
                                }
                              )
                            ) : (
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  lineHeight: "19.6px",
                                  color: "#939393",
                                  fontWeight: 400,
                                  marginBottom: 5,
                                }}
                              >
                                {`Số lượng: ${item.noOptionQuantity}`}
                              </PretendardText>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  color: "#626262",
                                  fontWeight: 600,
                                  marginRight: 12,
                                  textDecoration: "line-through",
                                }}
                              >
                                {Number(item.originalPrice).toLocaleString()}{" "}
                                đồng
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  color: "#939393",
                                  fontWeight: 400,
                                }}
                              >
                                {Number(item.salePrice).toLocaleString()} đồng
                              </PretendardText>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: dataList.length === index + 1 ? 0 : 40,
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: 0,
                          }}
                        >
                          <img
                            style={{
                              width: 120,
                              height: 120,
                              borderRadius: 6,
                              marginRight: 20,
                            }}
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                            alt="banner02"
                          />
                          <div
                            style={{
                              height: 110,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <ProductTitle $isMobile={isMobile}>
                              {item.title}
                            </ProductTitle>
                            {item.optionFlag ? (
                              JSON.parse(item.optionInfo).map(
                                (item02, index) => {
                                  return (
                                    <PretendardText
                                      key={index}
                                      style={{
                                        fontSize: 14,
                                        lineHeight: "19.6px",
                                        color: "#939393",
                                        fontWeight: 400,
                                        marginBottom: 16,
                                      }}
                                    >
                                      {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                    </PretendardText>
                                  );
                                }
                              )
                            ) : (
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  lineHeight: "19.6px",
                                  color: "#939393",
                                  fontWeight: 400,
                                  marginBottom: 16,
                                }}
                              >
                                {`Số lượng: ${item.noOptionQuantity}`}
                              </PretendardText>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  color: "#626262",
                                  fontWeight: 600,
                                  marginRight: 12,
                                  textDecoration: "line-through",
                                }}
                              >
                                {Number(item.originalPrice).toLocaleString()}{" "}
                                đồng
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  color: "#939393",
                                  fontWeight: 400,
                                }}
                              >
                                {Number(item.salePrice).toLocaleString()} đồng
                              </PretendardText>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "4px 12px",
                          borderRadius: 6,
                          background: "#F5F5F5",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color:
                              item.exchangeStatus === "REFUND_COMPLETED" ||
                              item.returnStatus === "EXCHANGE_COMPLETED"
                                ? "#FF649C"
                                : "#626262",
                            fontWeight: 500,
                          }}
                        >
                          {item.orderStatus === "EXCHANGE"
                            ? exchangeStateFunc(item.exchangeStatus)
                            : returnStateFunc(item.returnStatus)}
                        </PretendardText>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty title={"Không có lịch sử đổi/trả lại."} />
              )}

              {dataList.length > 0 && pagination.totalCount > 5 ? (
                <div
                  style={{
                    marginTop: 44,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    route={route.mypage_exchange_list}
                    queryString={``}
                    currentPage={Number(page)}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ExchangeListSection>
    </>
  );
};

export default ExchangeList;

const ExchangeListSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 16px;
  color: rgb(49, 49, 49);
  font-weight: 500;
  line-height: 23.2px;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "8px")};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props.$isMobile ? "1" : "2"}; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
