import Input from "../../atoms/input/input";
import PretendardText from "../../atoms/text/pretendardText";
import TextArea from "../../atoms/input/textarea";

import useDisplay from "../../../hooks/useDisplay";

function TitleInput({
  style,
  title,
  essential,
  value,
  onChange,
  type,
  placeholder,
  maxLength,
  disabled,
  warningMessage,
  checkMessage,
  inputStyle,
  onKeyDown,
  inputType,
  setInputType,
  isRightButton,
  isRightButtonText,
  isRightButtonStyle,
  isTimer,
  timerStyle,
  isTextarea,
  rightButtonOnClick,
  min,
  sec,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          ...style,
        }}
      >
        <div
          style={{
            marginBottom: 8,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "23.2px",
              color: disabled ? "#ABABAB" : "#313131",
              fontWeight: 700,
            }}
          >
            {title}
          </PretendardText>
          {essential ? (
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#FF3D83",
                fontWeight: 700,
              }}
            >
              *
            </PretendardText>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              {isTextarea ? (
                <TextArea
                  value={value}
                  onChange={onChange}
                  type={type}
                  placeholder={placeholder}
                  style={{
                    flex: 1,
                    borderColor: warningMessage ? "#FF5A5A" : "#e8e8e8",
                    ...inputStyle,
                  }}
                />
              ) : (
                <Input
                  value={value}
                  onChange={onChange}
                  type={type}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={disabled}
                  style={{
                    flex: 1,
                    borderColor: warningMessage ? "#FF5A5A" : "#e8e8e8",
                    ...inputStyle,
                  }}
                  onKeyDown={onKeyDown}
                />
              )}

              {isTimer ? (
                <div
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: isMobile ? 82 : "18%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "4px 10px",
                    borderRadius: 6,
                    ...timerStyle,
                  }}
                >
                  <PretendardText
                    style={{
                      fontWeight: 600,
                      fontSize: 12,
                      lineHeight: "16.8px",
                      color: "#FF3D83",
                    }}
                  >
                    {min}:{sec < 10 ? "0" + sec : sec}
                  </PretendardText>
                </div>
              ) : null}

              {isRightButton ? (
                <div
                  onClick={rightButtonOnClick}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "-6%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "#FFECF3",
                    padding: "4px 10px",
                    borderRadius: 6,
                    ...isRightButtonStyle,
                  }}
                >
                  <PretendardText
                    style={{
                      fontWeight: 600,
                      fontSize: 12,
                      lineHeight: "16.8px",
                      color: "#313131",
                    }}
                  >
                    {isRightButtonText}
                  </PretendardText>
                </div>
              ) : null}

              {inputType && inputType === "password" && !disabled ? (
                type === "password" ? (
                  <img
                    onClick={() => {
                      setInputType("text");
                    }}
                    style={{
                      position: "absolute",
                      right: 13,
                      width: 22,
                      height: 22,
                      cursor: "pointer",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    src="/assets/icons/eye_d.png"
                    alt="eye_d"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setInputType("password");
                    }}
                    style={{
                      position: "absolute",
                      right: 13,
                      width: 22,
                      height: 22,
                      cursor: "pointer",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    src="/assets/icons/eye.png"
                    alt="eye"
                  />
                )
              ) : null}
            </div>

            {warningMessage ? (
              <PretendardText
                style={{
                  fontWeight: 600,
                  fontSize: 12,
                  lineHeight: "16.8px",
                  color: "#F24147",
                  marginTop: 4,
                }}
              >
                {warningMessage}
              </PretendardText>
            ) : null}

            {checkMessage ? (
              <PretendardText
                style={{
                  fontWeight: 600,
                  fontSize: 12,
                  lineHeight: "16.8px",
                  color: "#BA77EF",
                  marginTop: 4,
                }}
              >
                {checkMessage}
              </PretendardText>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default TitleInput;
