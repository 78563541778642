import api from "./api.js";

export default class CartApi {
  static async getList(data) {
    return await api.get(`member/cart/my`, data);
  }

  static async cartTrigger(id, data) {
    return await api.post(`member/cart/${id}`, data);
  }

  static async cartDel(id) {
    return await api.del(`member/cart/${id}`);
  }
}
