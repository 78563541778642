import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import PretendardText from "../../atoms/text/pretendardText";
import ConfirmModal from "../../templates/modals/confirmModal";

import { route } from "../../../router/route";
import PayInqueryModal from "../../templates/modals/payInqueryModal";
import OrderAddressModal from "../../templates/modals/orderAddressModal";

let merchOrderId;

function OrderListItem({ style, data, orderCancelFunc }) {
  const navigation = useNavigate();

  const [isDetail, setIsDetail] = useState(false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [isPayInqueryModal, setIsPayInqueryModal] = useState(false);
  const [isAddressEditModal, setIsAddressEditModal] = useState(false);

  const [merchInfo, setMerchInfo] = useState(data.memberMerches);
  const [couponInfo, setCouponInfo] = useState(JSON.parse(data.couponInfo));

  const orderStatusFunc = (deliveryStatus, orderStatus) => {
    if (data.cancelStatus !== null) {
      return "CANCEL";
    } else if (deliveryStatus === "ORDER_COMPLETED") {
      return "ORDER_COMPLETED";
    } else if (deliveryStatus === "PREPARING_SHIPMENT") {
      return "PREPARING_SHIPMENT";
    } else if (deliveryStatus === "SHIPPING") {
      return "SHIPPING";
    } else if (deliveryStatus === "DELIVERED") {
      return "DELIVERED";
    } else if (orderStatus === "RETURN") {
      return "RETURN";
    } else if (orderStatus === "EXCHANGE") {
      return "EXCHANGE";
    }
  };

  const orderStatusTitle = (status) => {
    switch (status) {
      case "CANCEL":
        return "Theo dõi vận chuyển";
      case "ORDER_COMPLETED":
        return "Hoàn thành đặt hàng";
      case "PREPARING_SHIPMENT":
        return "Đang chuẩn bị";
      case "SHIPPING":
        return "Đang vận chuyển";
      case "DELIVERED":
        return "Hoàn thành";
      case "RETURN":
        return "trả hàng";
      case "EXCHANGE":
        return "sự thay đổi";
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: 60,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...style,
        }}
      >
        <PretendardText
          style={{
            fontSize: 18,
            lineHeight: "26.1px",
            fontWeight: 600,
            color: "#222222",
            marginBottom: 12,
          }}
        >
          {moment(data.createdAt).format("YYYY.MM.DD")}
        </PretendardText>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            padding: "32px 24px",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <CardTitle>Đơn đặt hàng</CardTitle>
            <PretendardText
              style={{
                fontSize: 14,
                color: "#939393",
                fontWeight: 400,
              }}
            >
              Tổng {merchInfo.length} đơn
            </PretendardText>
          </div>

          <div
            style={{
              marginBottom: 40,
            }}
          >
            {merchInfo && merchInfo.length > 0
              ? merchInfo.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: merchInfo.length === index + 1 ? 0 : 40,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            flex: 1,
                          }}
                        >
                          <img
                            style={{
                              width: 120,
                              height: 120,
                              borderRadius: 6,
                              marginRight: 20,
                            }}
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                            alt="banner02"
                          />
                          <div
                            style={{
                              height: 110,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <ProductTitle>{item.title}</ProductTitle>
                            {item.optionFlag ? (
                              JSON.parse(item.optionInfo).map(
                                (item02, index) => {
                                  return (
                                    <PretendardText
                                      key={index}
                                      style={{
                                        fontSize: 14,
                                        lineHeight: "19.6px",
                                        color: "#939393",
                                        fontWeight: 400,
                                        marginBottom: 5,
                                      }}
                                    >
                                      {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                    </PretendardText>
                                  );
                                }
                              )
                            ) : (
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  lineHeight: "19.6px",
                                  color: "#939393",
                                  fontWeight: 400,
                                  marginBottom: 5,
                                }}
                              >
                                {`Số lượng: ${item.noOptionQuantity}`}
                              </PretendardText>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  color: "#626262",
                                  fontWeight: 600,
                                  marginRight: 12,
                                  textDecoration: "line-through",
                                }}
                              >
                                {Number(item.originalPrice).toLocaleString()}{" "}
                                đồng
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  color: "#939393",
                                  fontWeight: 400,
                                }}
                              >
                                {Number(item.salePrice).toLocaleString()} đồng
                              </PretendardText>
                            </div>
                          </div>
                        </div>

                        <PretendardText
                          key={index}
                          style={{
                            fontSize: 16,
                            color: "#313131",
                            fontWeight: 500,
                          }}
                        >
                          {orderStatusTitle(
                            orderStatusFunc(
                              item.deliveryStatus,
                              item.orderStatus
                            )
                          )}
                        </PretendardText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: 20,
                          textAlign: "center",
                        }}
                      >
                        <ProductBtn
                          onClick={() => {
                            merchOrderId = item.id;
                            setIsPayInqueryModal(true);
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 14,
                              color: "#626262",
                              fontWeight: 500,
                            }}
                          >
                            Liên hệ chúng tôi
                          </PretendardText>
                        </ProductBtn>
                        <ProductBtn
                          onClick={() => {
                            if (
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "DELIVERED"
                            ) {
                              // 교환/반품

                              navigation(
                                `${route.mypage_order_list_exchange}/${item.id}`,
                                {
                                  state: {
                                    merchInfo: [item],
                                    orderInfo: data,
                                  },
                                }
                              );
                              return;
                            }

                            if (
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "ORDER_COMPLETED"
                            ) {
                              // 배송지 변경

                              setIsAddressEditModal(true);
                              return;
                            }
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 14,
                              color:
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "RETURN" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "EXCHANGE" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "CANCEL" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "SHIPPING" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "PREPARING_SHIPMENT"
                                  ? "#C4C4C4"
                                  : "#626262",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            {orderStatusFunc(
                              item.deliveryStatus,
                              item.orderStatus
                            ) === "DELIVERED"
                              ? "Trao đổi / Trả lại"
                              : "Thay đổi địa chỉ giao hàng"}
                          </PretendardText>
                        </ProductBtn>
                        <ProductBtn
                          onClick={() => {
                            if (
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "CANCEL" ||
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "RETURN" ||
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "EXCHANGE"
                            ) {
                              return;
                            }

                            navigation(
                              `${route.mypage_order_list_delivery_status}/${data.id}`,
                              {
                                state: {
                                  deliveryItem: item,
                                },
                              }
                            );
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 14,
                              color:
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "CANCEL" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "RETURN" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "EXCHANGE"
                                  ? "#C4C4C4"
                                  : "#626262",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            Theo dõi vận chuyển
                          </PretendardText>
                        </ProductBtn>
                        <ProductBtn
                          onClick={() => {
                            if (
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "DELIVERED"
                            ) {
                              // 리뷰 작성

                              navigation(`${route.mypage_review_list}/1`);
                              return;
                            }

                            if (
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "ORDER_COMPLETED" ||
                              orderStatusFunc(
                                item.deliveryStatus,
                                item.orderStatus
                              ) === "PREPARING_SHIPMENT"
                            ) {
                              setIsCofirmModal(true);

                              return;
                            }
                          }}
                          style={{
                            marginRight: 0,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 14,
                              color:
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "CANCEL" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "SHIPPING" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "RETURN" ||
                                orderStatusFunc(
                                  item.deliveryStatus,
                                  item.orderStatus
                                ) === "EXCHANGE"
                                  ? "#C4C4C4"
                                  : "#626262",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            {orderStatusFunc(
                              item.deliveryStatus,
                              item.orderStatus
                            ) === "DELIVERED"
                              ? "Đánh giá"
                              : "Xóa"}
                          </PretendardText>
                        </ProductBtn>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div
            onClick={() => {
              setIsDetail(!isDetail);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              padding: isDetail ? "24px 0px" : "24px 0px 0px 0px",
              borderTop: "1px solid #E8E8E8",
              borderBottom: isDetail ? "1px solid #E8E8E8" : "none",
            }}
          >
            <CardTitle>Chi tiết đặt hàng</CardTitle>
            {isDetail ? (
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/ic_arrow-up-20.png"
                alt="ic_arrow-up-20"
              />
            ) : (
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/down_arrow.png"
                alt="down_arrow"
              />
            )}
          </div>

          {isDetail ? (
            <div
              style={{
                paddingTop: 24,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DetailTitle>Thông tin vận chuyển</DetailTitle>
              <DetailContainer>
                <DetailLeftText>Người nhận</DetailLeftText>
                <DetailRightText>{data.recipient}</DetailRightText>
              </DetailContainer>
              <DetailContainer>
                <DetailLeftText>Liên hệ</DetailLeftText>
                <DetailRightText>{data.phoneNumber}</DetailRightText>
              </DetailContainer>
              <DetailContainer
                style={{
                  marginBottom: 32,
                }}
              >
                <DetailLeftText>Địa chỉ</DetailLeftText>
                <DetailRightText>
                  {data.address} {data.detailAddress}
                </DetailRightText>
              </DetailContainer>

              <DetailTitle>Lịch sử thanh toán</DetailTitle>
              <DetailContainer>
                <DetailLeftText>Tổng tiền hàng</DetailLeftText>
                <DetailRightText>
                  {Number(data.orderAmount).toLocaleString()} đồng
                </DetailRightText>
              </DetailContainer>
              <DetailContainer>
                <DetailLeftText>Phí vận chuyển</DetailLeftText>
                <DetailRightText>
                  {Number(data.deliveryAmount).toLocaleString()} đồng
                </DetailRightText>
              </DetailContainer>
              <DetailContainer>
                <DetailLeftText>Mã giảm giá</DetailLeftText>
                <div>
                  <DetailRightText>
                    {Number(data.couponAmount).toLocaleString()} đồng
                  </DetailRightText>
                  {couponInfo && couponInfo.length > 0
                    ? couponInfo.map((item, index) => {
                        return (
                          <PretendardText
                            key={index}
                            style={{
                              fontSize: 14,
                              lineHeight: "19.6px",
                              fontWeight: 400,
                              color: "#767676",
                              marginTop: 8,
                            }}
                          >
                            {item.title} {Number(item.amount).toLocaleString()}{" "}
                            đ
                          </PretendardText>
                        );
                      })
                    : null}
                </div>
              </DetailContainer>
              <DetailContainer>
                <DetailLeftText>Điểm</DetailLeftText>
                <DetailRightText>
                  {Number(data.pointAmount).toLocaleString()} p
                </DetailRightText>
              </DetailContainer>
              <DetailContainer
                style={{
                  marginBottom: data.cancelStatus !== null ? 32 : 0,
                }}
              >
                <DetailLeftText>Tổng thành tiền</DetailLeftText>
                <DetailRightText>
                  {Number(data.payAmount).toLocaleString()} đồng
                </DetailRightText>
              </DetailContainer>

              {data.cancelStatus !== null ? (
                <>
                  <DetailTitle>Chi tiết hoàn tiền</DetailTitle>
                  <DetailContainer>
                    <DetailLeftText>Tổng tiền hàng</DetailLeftText>
                    <DetailRightText>
                      {Number(data.orderAmount).toLocaleString()} đồng
                    </DetailRightText>
                  </DetailContainer>
                  <DetailContainer>
                    <DetailLeftText>Phí vận chuyển</DetailLeftText>
                    <DetailRightText>
                      {Number(data.deliveryAmount).toLocaleString()} đồng
                    </DetailRightText>
                  </DetailContainer>
                  <DetailContainer>
                    <DetailLeftText>Mã giảm giá</DetailLeftText>
                    <div>
                      <DetailRightText>
                        {data.cancel.returnCoupon
                          ? Number(data.couponAmount).toLocaleString()
                          : 0}{" "}
                        đồng
                      </DetailRightText>
                      {data.cancel.returnCoupon &&
                      couponInfo &&
                      couponInfo.length > 0 ? (
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "19.6px",
                            fontWeight: 400,
                            color: "#767676",
                            marginTop: 8,
                          }}
                        >
                          Đăng ký mới phiếu giảm giá 30% 3.000.000 đ
                        </PretendardText>
                      ) : null}
                    </div>
                  </DetailContainer>
                  <DetailContainer>
                    <DetailLeftText>Điểm</DetailLeftText>
                    <DetailRightText>
                      {data.cancel.returnPoint
                        ? Number(data.pointAmount).toLocaleString()
                        : 0}{" "}
                      p
                    </DetailRightText>
                  </DetailContainer>
                  <DetailContainer
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <DetailLeftText>Số tiền hoàn lại</DetailLeftText>
                    <div>
                      <DetailRightText
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        {/* Thẻ tín dụng (00 thẻ) */}
                        {Number(data.cancel.refundAmount).toLocaleString()} đồng
                      </DetailRightText>
                      {/* <DetailRightText>{`{Tên phiếu giảm giá} 000.000.000.000 đ`}</DetailRightText> */}
                    </div>
                  </DetailContainer>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={false}
          title={"Xóa"}
          content={
            "Sản phẩm vẫn chưa được giao. Bạn có muốn\nhủy đơn đặt hàng của bạn?"
          }
          onClick={() => {
            setIsCofirmModal(false);
          }}
          onYClick={() => {
            setIsCofirmModal(false);
            orderCancelFunc(data.id);
          }}
          yText={"Có, tôi hủy."}
          nText={"KHÔNG"}
        />
      ) : null}

      {isPayInqueryModal ? (
        <PayInqueryModal
          isScroll={false}
          setVisiable={setIsPayInqueryModal}
          orderId={merchOrderId}
        />
      ) : null}

      {isAddressEditModal ? (
        <OrderAddressModal
          isScroll={false}
          setVisiable={setIsAddressEditModal}
          data={data}
        />
      ) : null}
    </>
  );
}

export default OrderListItem;

const CardTitle = styled(PretendardText)`
  font-size: 18px;
  line-height: 26.1px;
  color: rgb(49, 49, 49);
  font-weight: 700;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 16px;
  color: rgb(49, 49, 49);
  font-weight: 500;
  line-height: 23.2px;
  margin-bottom: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const DetailTitle = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  color: #626262;
  font-weight: 600;

  margin-bottom: 16px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const DetailLeftText = styled(PretendardText)`
  font-size: 14px;
  line-height: 19.6px;
  color: #939393;
  font-weight: 400;

  min-width: 180px;
  margin-right: 46px;
`;

const DetailRightText = styled(PretendardText)`
  font-size: 14px;
  line-height: 19.6px;
  color: #313131;
  font-weight: 500;
`;

const ProductBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  height: 40px;
  flex: 1;
  margin-right: 8px;

  cursor: pointer;
`;
