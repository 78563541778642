import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";

import MypageMenuM from "../../components/templates/mypageMenuM";
import OrderApi from "../../api/orderApi";
import { ToastContainer } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";

const DeliveryStatus = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const location = useLocation();

  const { deliveryItem } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const { id } = useParams();
  const [orderInfo, setOrderInfo] = useState();

  useEffect(() => {
    orderDetailFunc();
  }, []);

  const orderDetailFunc = async () => {
    try {
      const response = (await OrderApi.orderDetail(id)).data.data;

      setOrderInfo(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return ToastContainer(`${error.response.data.message}`);
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <DeliveryStatusSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "0px 20px 0px 20px" : "0px",
            }}
          >
            <div
              onClick={() => {
                navigation(-1);
              }}
              style={{
                marginTop: isSmall ? 28 : 8,
                marginBottom: isMobile ? 29 : 27,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/keyboard_left.png"
                alt="keyboard_left"
              />
              <PretendardText
                style={{
                  marginLeft: 8,
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 500,
                }}
              >
                Kiểm tra vận chuyển
              </PretendardText>
            </div>

            {orderInfo ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: isMobile ? "0px" : "60px 100px",
                  borderRadius: 8,
                  background: "#fff",
                }}
              >
                <PretendardText
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    lineHeight: "28px",
                    color: "#222222",
                    fontWeight: 600,
                    marginBottom: 40,
                  }}
                >
                  {deliveryItem?.deliveryStatus === "ORDER_COMPLETED"
                    ? "Sản phẩm bạn đặt đã được đặt hàng."
                    : deliveryItem?.deliveryStatus === "PREPARING_SHIPMENT"
                    ? "Đơn hàng của bạn đang được chuẩn bị"
                    : deliveryItem?.deliveryStatus === "SHIPPING"
                    ? "Sản phẩm bạn đặt đang được giao."
                    : "Sản phẩm bạn đặt đã được giao."}
                </PretendardText>

                <div
                  style={{
                    marginBottom: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "61%" : 424,
                      position: "absolute",
                      border: "1px dashed #FF9EC1",
                      top: isMobile ? 31 : 43,
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      width: isMobile ? 64 : 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: isMobile ? 26.33 : 40,
                      zIndex: 2,
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? 64 : 80,
                        height: isMobile ? 64 : 80,
                        borderRadius: 4,
                      }}
                      src={
                        deliveryItem?.deliveryStatus === "ORDER_COMPLETED"
                          ? "/assets/icons/delivery/status01_a.png"
                          : "/assets/icons/delivery/status01_d.png"
                      }
                      alt="status01"
                    />
                    <PretendardText
                      style={{
                        textAlign: "center",
                        fontSize: isMobile ? 14 : 16,
                        color: "#222222",
                        fontWeight: 500,
                        marginTop: 16,
                      }}
                    >
                      Hoàn thành đặt hàng
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isMobile ? 64 : 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: isMobile ? 26.33 : 40,
                      zIndex: 2,
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? 64 : 80,
                        height: isMobile ? 64 : 80,
                        borderRadius: 4,
                      }}
                      src={
                        deliveryItem?.deliveryStatus === "PREPARING_SHIPMENT"
                          ? "/assets/icons/delivery/status02_a.png"
                          : "/assets/icons/delivery/status02_d.png"
                      }
                      alt="status01"
                    />
                    <PretendardText
                      style={{
                        textAlign: "center",
                        fontSize: isMobile ? 14 : 16,
                        color: "#222222",
                        fontWeight: 500,
                        marginTop: 16,
                      }}
                    >
                      Đang chuẩn bị
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isMobile ? 64 : 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: isMobile ? 26.33 : 40,
                      zIndex: 2,
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? 64 : 80,
                        height: isMobile ? 64 : 80,
                        borderRadius: 4,
                      }}
                      src={
                        deliveryItem?.deliveryStatus === "SHIPPING"
                          ? "/assets/icons/delivery/status03_a.png"
                          : "/assets/icons/delivery/status03_d.png"
                      }
                      alt="status01"
                    />
                    <PretendardText
                      style={{
                        textAlign: "center",
                        fontSize: isMobile ? 14 : 16,
                        color: "#222222",
                        fontWeight: 500,
                        marginTop: 16,
                      }}
                    >
                      Đang vận chuyển
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isMobile ? 64 : 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      zIndex: 2,
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? 64 : 80,
                        height: isMobile ? 64 : 80,
                        borderRadius: 4,
                      }}
                      src={
                        deliveryItem?.deliveryStatus === "DELIVERED"
                          ? "/assets/icons/delivery/status04_a.png"
                          : "/assets/icons/delivery/status04_d.png"
                      }
                      alt="status01"
                    />
                    <PretendardText
                      style={{
                        textAlign: "center",
                        fontSize: isMobile ? 14 : 16,
                        color: "#222222",
                        fontWeight: 500,
                        marginTop: 16,
                      }}
                    >
                      Hoàn thành
                    </PretendardText>
                  </div>
                </div>

                <div
                  style={{
                    border: "1px solid #EEEEEE",
                    borderRadius: 8,
                    padding: "20px 24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Mã đặt hàng</LeftText>
                    <RightText $isMobile={isMobile}>
                      {orderInfo.orderNumber}
                    </RightText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Mã vận đơn</LeftText>
                    <RightText $isMobile={isMobile}>
                      {deliveryItem?.deliveryNumber}
                    </RightText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Đơn vị vận chuyển</LeftText>
                    <RightText $isMobile={isMobile}>
                      {deliveryItem?.deliveryCompany}
                    </RightText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Người nhận</LeftText>
                    <RightText $isMobile={isMobile}>
                      {orderInfo.recipient}
                    </RightText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 8,
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Địa chỉ</LeftText>
                    <RightText $isMobile={isMobile}>
                      {orderInfo.address} {orderInfo.detailAddress}
                    </RightText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <LeftText $isMobile={isMobile}>Số điện thoại</LeftText>
                    <RightText $isMobile={isMobile}>
                      {orderInfo.phoneNumber}
                    </RightText>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </DeliveryStatusSection>
    </>
  );
};

export default DeliveryStatus;

const DeliveryStatusSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LeftText = styled(PretendardText)`
  min-width: 94px;
  max-width: 94px;
  margin-right: 46px;
  font-size: 14px;
  font-weight: 400;
  color: #626262;
`;

const RightText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 500;
  color: #313131;
`;
