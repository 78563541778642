import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../../../hooks/useDisplay";
import ProductItem from "./productItem";
import ProductItemMini from "./productItemMini";

function RelatedProductSwiper({ dataList }) {
  const swiperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: isMobile ? 330 : "411px",
        }}
      >
        <Swiper
          modules={[Navigation]}
          onSwiper={(it) => {
            swiperRef.current = it;
          }}
          slidesPerView={isMobile ? 2 : isTablet ? 3 : 5.2}
          spaceBetween={isMobile ? 15 : 28}
        >
          {dataList?.length > 0
            ? dataList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ProductItemMini data={item} />
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>
      </div>
    </>
  );
}

export default RelatedProductSwiper;
