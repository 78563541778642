import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../../../hooks/useDisplay";

function ProductBottomSwiper({ dataList, activeIndex, setClickedIndex }) {
  const swiperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    swiperRef.current.slideTo(activeIndex);
  }, [activeIndex]);

  const getVideoEmbedUrl = (link) => {
    if (link) {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = link.match(regex);

      const videoId = match ? match[1] : "";
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return "";
    }

    // 추후 틱톡
    // return `https://www.tiktok.com/embed/${url.split('/')[5]}`;
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          marginBottom: 20,
          width: "100%",
          height: isMobile ? 107 : "206px",
          borderRadius: 8,
        }}
      >
        <Swiper
          modules={[Navigation]}
          onSwiper={(it) => {
            swiperRef.current = it;
          }}
          slidesPerView={3}
          spaceBetween={isMobile ? 7 : 11}
        >
          {dataList?.length > 0
            ? dataList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      onClick={() => {
                        if (dataList.length === index + 1) {
                          setClickedIndex(0);
                        } else {
                          setClickedIndex(index + 1);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        width: isMobile ? "100%" : "206px",
                        height: isMobile ? 107 : "206px",
                        position: "relative",
                        borderRadius: 8,
                      }}
                    >
                      {item.title ? (
                        item.link ? (
                          <div
                            style={{
                              width: isMobile ? "100%" : "206px",
                              height: isMobile ? 107 : "206px",
                              borderRadius: 8,
                            }}
                          >
                            <iframe
                              style={{
                                borderRadius: 8,
                              }}
                              width="100%"
                              height={"100%"}
                              src={`${getVideoEmbedUrl(item.link)}?autoplay=0`}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              title="YouTube video player"
                            />
                          </div>
                        ) : (
                          <video
                            muted
                            style={{
                              width: isMobile ? "100%" : "206px",
                              height: isMobile ? 107 : "206px",
                              objectFit: "cover",
                              borderRadius: 8,
                            }}
                          >
                            <source
                              src={`${process.env.REACT_APP_MEDIA_URL}${item.videoServerFileName}`}
                              type="video/mp4"
                            />
                          </video>
                        )
                      ) : (
                        <img
                          style={{
                            width: isMobile ? "100%" : "206px",
                            height: isMobile ? 107 : "206px",
                            borderRadius: 8,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="product_main"
                        />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>
      </div>
    </>
  );
}

export default ProductBottomSwiper;
