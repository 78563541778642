import { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../../../hooks/useDisplay";
import InfluencerSPItem from "../influencer/influencerSPItem";

function PickSwiper({ dataList }) {
  const swiperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [activeIndex, setActiveIndex] = useState(0);
  const totalIndex = Math.ceil(dataList.length / 4);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: isMobile ? "64%" : isTablet ? "64%" : "91%" }}>
          <Swiper
            onSwiper={(it) => {
              swiperRef.current = it;
            }}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.snapIndex);
            }}
            slidesPerView={isSmall ? 1 : 4.15}
            spaceBetween={isSmall ? 0 : 20}
            grabCursor={true}
            slidesPerGroup={isSmall ? 1 : 4}
            modules={[Pagination]}
          >
            {dataList?.length > 0
              ? dataList?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <InfluencerSPItem data={item} />
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>

        <img
          onClick={() => swiperRef.current?.slidePrev()}
          style={{
            position: "absolute",
            cursor: "pointer",
            left: 20,
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src="/assets/icons/btn-left-arrow-40.png"
          alt="btn-left-arrow-40"
        />
        <img
          onClick={() => swiperRef.current?.slideNext()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: -20,
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src="/assets/icons/btn-right-arrow-40.png"
          alt="btn-right-arrow-40"
        />

        {isSmall ? null : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "absolute",
              top: -40,
              right: 50,
            }}
          >
            {Array(totalIndex)
              .fill(0)
              .map((item02, index02) => {
                return (
                  <div
                    key={index02}
                    style={{
                      width: index02 === activeIndex ? 8 : 20,
                      height: 8,
                      borderRadius: 99,
                      background:
                        index02 === activeIndex ? "#6F7480" : "#C0C2C8",
                      marginRight: 4,
                    }}
                  />
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}

export default PickSwiper;
