import { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import MainBorderBtn from "../../atoms/button/mainBorderBtn";

import Pagination from "../pagination";

import useDisplay from "../../../hooks/useDisplay";
import QAItem from "../../molecules/qa/qaItem";
import NotiModal from "../../templates/modals/notiModal";
import ConfirmModal from "../../templates/modals/confirmModal";
import ProductInqueryModal from "../../templates/modals/productInqueryModal";
import QAItemM from "../../molecules/qa/qaItemM";
import MerchApi from "../../../api/merchApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import Empty from "../empty";

function QAList({ style, merchId }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [page, setPage] = useState(1);
  const [askList, setAskList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [isNotiModal, setIsNotiModal] = useState(false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [isPIModal, setIsPIModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [preData, setPreData] = useState(null);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");

    setIsLogin(isLogin);

    askListFunc();
  }, [page]);

  const askListFunc = async () => {
    try {
      setAskList([]);
      const params = {
        page: page,
        size: pagination.size,
      };

      const response = (await MerchApi.getAskList(merchId, params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setAskList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const askEditFunc = async (data) => {
    setPreData(data);
    setIsPIModal(true);
  };

  return (
    <>
      <QAListContain style={style}>
        {askList.length > 0 ? (
          askList.map((item, index) => {
            return isMobile ? (
              <QAItemM
                key={index}
                data={item}
                style={{
                  borderBottom:
                    askList.length === index + 1
                      ? "1px solid #DCDCDC"
                      : '"none"',
                }}
                setIsNotiModal={setIsNotiModal}
                askListFunc={askListFunc}
                askEditFunc={askEditFunc}
              />
            ) : (
              <QAItem
                key={index}
                data={item}
                style={{
                  borderBottom:
                    askList.length === index + 1
                      ? "1px solid #DCDCDC"
                      : '"none"',
                }}
                setIsNotiModal={setIsNotiModal}
                askListFunc={askListFunc}
                askEditFunc={askEditFunc}
              />
            );
          })
        ) : (
          <Empty title={"Vẫn chưa có câu hỏi."} />
        )}

        {isLogin ? (
          <div
            style={{
              marginTop: 40,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <MainBorderBtn
              onClick={() => {
                setIsPIModal(true);
              }}
              style={{
                width: 128,
                height: 47,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  color: "#313131",
                  fontWeight: 600,
                }}
              >
                Đặt câu hỏi
              </PretendardText>
            </MainBorderBtn>
          </div>
        ) : null}

        {askList.length > 0 && pagination.totalCount > 10 ? (
          <div
            style={{
              marginTop: 40,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              setPage={setPage}
              queryString={``}
              currentPage={Number(page)}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        ) : null}
      </QAListContain>

      {isNotiModal ? (
        <NotiModal
          isScroll={false}
          title={"Chỉ người viết mới có thể xem\n được."}
          onYClick={() => {
            setIsNotiModal(false);
          }}
        />
      ) : null}

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={false}
          title={"Bạn có lưu nội dung sửa đổi không?"}
          onClick={() => {}}
          onYClick={() => {}}
        />
      ) : null}

      {isPIModal ? (
        <ProductInqueryModal
          setVisiable={setIsPIModal}
          isScroll={false}
          merchId={merchId}
          onYClick={() => {
            askListFunc();
          }}
          preData={preData}
          setPreData={setPreData}
        />
      ) : null}
    </>
  );
}

export default QAList;

const QAListContain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
`;
