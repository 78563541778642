import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";
import MainBtn from "../../components/atoms/button/mainBtn";
import GrayBorderBtn from "../../components/atoms/button/grayBorderBtn";
import TextArea from "../../components/atoms/input/textarea";
import CameraFileForm from "../../components/atoms/fileForm/cameraFileForm";
import AddressModal from "../../components/templates/modals/addressModal";
import ExchangeCard from "../../components/molecules/mypage/exchangeCard";
import MypageMenuM from "../../components/templates/mypageMenuM";
import RightLabelRadio from "../../components/molecules/radio/rightLabelRadio";
import CommonApi from "../../api/commonApi";
import { errorHandler } from "../../util/errorHandler";
import OrderApi from "../../api/orderApi";
import moment from "moment";

let addressType = 1;
const ExchangeApply = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { merchInfo, orderInfo } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const [subCategoryList, setSubCategoryList] = useState([
    {
      title: "Không còn nhu cầu",
      isActive: true,
    },
    { title: "Đã nhận Sản phẩm khác với sản phẩm đã đặt", isActive: false },
    { title: "Đơn hàng gửi thiếu", isActive: false },
    { title: "Sản phẩm không như mô tả", isActive: false },
    { title: "Sản phẩm bị hư hỏng / Đã qua sử dụng", isActive: false },
    { title: "Chất lượng sản phẩm bất thường", isActive: false },
  ]);
  const [reason, setReason] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [exchangeType, setExchangeType] = useState("exchange");
  const [isADModal, setIsADodal] = useState(false);
  const [requiredDays, setRequiredDays] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState({
    address: "",
    addressDetail: "",
    name: "",
    phone: "",
  });
  const [returnAddress, setReturnAddress] = useState({
    address: "",
    addressDetail: "",
    name: "",
    phone: "",
  });

  const subCategoryClickFunc = (title) => {
    let temp = subCategoryList.map((item) => {
      return item.title === title
        ? {
            ...item,
            isActive: true,
          }
        : {
            ...item,
            isActive: false,
          };
    });

    setSubCategoryList(temp);
  };

  useEffect(() => {
    settingFunc();
    let defaultAddress = {
      address: orderInfo.address,
      addressDetail: orderInfo.detailAddress,
      name: orderInfo.recipient,
      phone: orderInfo.phoneNumber,
    };

    setDeliveryAddress(defaultAddress);
    setReturnAddress(defaultAddress);
  }, []);

  const settingFunc = async () => {
    try {
      const response = (await OrderApi.exchangeSetting()).data.data.content;

      setRequiredDays(response.requiredDays);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const handleChange = async (e) => {
    if (selectedFiles.length >= 3) {
      toast("Tối đa 3 trang");

      return;
    }

    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 3) {
      toast("Tối đa 3 trang");
      return;
    }
    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop()
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          imageOriginFileName: file.name,
          imageServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setSelectedFiles((prevFiles) => [...prevFiles, fileObject]);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType) => {
    try {
      const obj = {
        path: "exchange/",
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const deleteFile = (fileName) => {
    let temp = selectedFiles.filter(
      (item) => item.imageServerFileName != fileName
    );
    setSelectedFiles(temp);
  };

  const exchangeFunc = async () => {
    try {
      let images = selectedFiles.map((item) => {
        return {
          imageOriginFileName: item.imageOriginFileName,
          imageServerFileName: item.imageServerFileName,
        };
      });

      const params = {
        exchangeType: subCategoryList[0].isActive
          ? "CHANGE_OF_MIND"
          : subCategoryList[1].isActive
          ? "WRONG_ITEM_DELIVERED"
          : subCategoryList[2].isActive
          ? "MISSING_COMPONENTS"
          : subCategoryList[3].isActive
          ? "NOT_AS_DESCRIBED"
          : subCategoryList[4].isActive
          ? "DAMAGED_PRODUCT"
          : subCategoryList[5].isActive
          ? "DEFECTIVE_PRODUCT"
          : "CHANGE_OF_MIND",
        reason: reason,
        images: images,
        isExchange: exchangeType === "exchange" ? true : false,
        addressDelivery: deliveryAddress.address,
        detailAddressDelivery: deliveryAddress.addressDetail,
        phoneNumberDelivery: deliveryAddress.phone,
        recipientDelivery: deliveryAddress.name,
        expectedDeliveryDate: moment()
          .add("days", requiredDays + requiredDays)
          .format("YYYY-MM-DD"),
        addressReturn: returnAddress.address,
        detailAddressReturn: returnAddress.addressDetail,
        phoneNumberReturn: returnAddress.phone,
        recipientReturn: returnAddress.name,
        expectedReturnDate: moment()
          .add("days", requiredDays)
          .format("YYYY-MM-DD"),
      };

      const response = await OrderApi.orderExchange(id, params);

      navigation(route.mypage_order_list_exchange_complete, {
        state: {
          merchInfo: merchInfo,
        },
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const addressChangeFunc = (ad, adD, rep, pho, isBase) => {
    let addressTemp = "";

    if (ad[0]) {
      addressTemp += ad[0].provinceName;
    }

    if (ad[1]) {
      addressTemp += ` ${ad[1].districtName}`;
    }

    if (ad[2]) {
      addressTemp += ` ${ad[2].wardName}`;
    }

    let temp = {
      address: addressTemp,
      addressDetail: adD,
      name: rep,
      phone: pho,
    };

    if (addressType === 1) {
      setDeliveryAddress(temp);
    } else {
      setReturnAddress(temp);
    }

    setIsADodal(false);
  };

  return (
    <>
      <ExchangeApplySection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "0px 20px 0px 20px" : "0px",
            }}
          >
            <div
              onClick={() => {
                navigation(-1);
              }}
              style={{
                marginTop: isSmall ? 28 : 8,
                marginBottom: isMobile ? 29 : 27,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/keyboard_left.png"
                alt="keyboard_left"
              />
              <PretendardText
                style={{
                  marginLeft: 8,
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 500,
                }}
              >
                Yêu cầu Trả hàng / Hoàn tiền
              </PretendardText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: isMobile ? "0px" : "60px 100px",
                borderRadius: 8,
                background: "#fff",
              }}
            >
              <PretendardText
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#222222",
                  fontWeight: 600,
                  marginBottom: isMobile ? 40 : 60,
                }}
              >
                Yêu cầu Trả hàng / Hoàn tiền
              </PretendardText>

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>1</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Vui lòng kiểm tra lại thông tin sản phẩm yêu cầu Trả hàng /
                  Hoàn tiền
                </SectionTitleText>
              </SectionTitleDiv>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                {merchInfo && merchInfo.length > 0
                  ? merchInfo.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            marginBottom:
                              merchInfo.length === index + 1 ? 0 : 16,
                          }}
                        >
                          <img
                            style={{
                              width: isMobile ? 72 : 120,
                              height: isMobile ? 72 : 120,
                              borderRadius: 6,
                              marginRight: isMobile ? 12 : 20,
                            }}
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                            alt="banner02"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <ProductTitle $isMobile={isMobile}>
                              {item.title}
                            </ProductTitle>
                            {item.optionFlag ? (
                              JSON.parse(item.optionInfo).map(
                                (item02, index) => {
                                  return (
                                    <PretendardText
                                      key={index}
                                      style={{
                                        fontSize: 14,
                                        lineHeight: "19.6px",
                                        color: "#939393",
                                        fontWeight: 400,
                                        marginBottom:
                                          item.optionInfo.length === index + 1
                                            ? 0
                                            : 5,
                                      }}
                                    >
                                      {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                    </PretendardText>
                                  );
                                }
                              )
                            ) : (
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  lineHeight: "19.6px",
                                  color: "#939393",
                                  fontWeight: 400,
                                }}
                              >
                                {`Số lượng: ${item.noOptionQuantity}`}
                              </PretendardText>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <GrayBorderBtn
                onClick={() => {
                  navigation(-1);
                }}
                style={{
                  height: isMobile ? 55 : 48,
                  marginBottom: isMobile ? 36 : 80,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "19.09px",
                    color: "#222222",
                    fontWeight: 500,
                  }}
                >
                  Chọn sản phẩm khác
                </PretendardText>
              </GrayBorderBtn>

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>2</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Lý do yêu cầu Trả hàng / Hoàn tiền
                </SectionTitleText>
              </SectionTitleDiv>

              {isMobile ? (
                <div>
                  {subCategoryList.map((item, index) => {
                    return (
                      <RightLabelRadio
                        key={index}
                        textStyle={{
                          fontWeight: item.isActive ? 500 : 400,
                          color: item.isActive ? "#313131" : "#626262",
                        }}
                        inputStyle={{
                          marginBottom: 20,
                        }}
                        checked={item.isActive}
                        onClick={() => {
                          subCategoryClickFunc(item.title);
                        }}
                        text={item.title}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 16,
                    marginBottom: 32,
                  }}
                >
                  {subCategoryList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: isTablet ? "48.2%" : 332,
                          height: 48,
                          cursor: "pointer",
                          border: item.isActive
                            ? "1px solid #FF3D83"
                            : "1px solid #DCDCDC",
                          borderRadius: 8,
                          background: item.isActive ? "#FFF6FA" : "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          subCategoryClickFunc(item.title);
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            fontWeight: item.isActive ? 500 : 400,
                            color: item.isActive ? "#313131" : "#626262",
                            textAlign: "center",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      </div>
                    );
                  })}
                </div>
              )}

              <div
                style={{
                  position: "relative",
                  marginBottom: isMobile ? 20 : 16,
                }}
              >
                <TextArea
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  placeholder={""}
                  style={{
                    height: isMobile ? 140 : 120,
                  }}
                />
                {reason ? null : (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      top: 14,
                      left: 14,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "23.2px",
                        color: "#FF5A5A",
                        fontWeight: 400,
                      }}
                    >
                      (Bắt buộc)&nbsp;
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "23.2px",
                        color: "#626262",
                        fontWeight: 400,
                      }}
                    >
                      Vui lòng ghi lý do chi tiết.
                    </PretendardText>
                  </div>
                )}
              </div>

              {selectedFiles.length > 0 ? (
                <MenuOverflowXHidden>
                  {selectedFiles.map((item, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          onClick={() => {
                            deleteFile(item.imageServerFileName);
                          }}
                          style={{
                            width: isMobile ? 90 : 72,
                            height: isMobile ? 90 : 72,
                            cursor: "pointer",
                            borderRadius: 8,
                          }}
                          src={item.fileUrl}
                          alt="file"
                        />

                        <img
                          onClick={() => {
                            deleteFile(item.imageServerFileName);
                          }}
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                            position: "absolute",
                            top: 3,
                            right: 3,
                          }}
                          src="/assets/icons/subBTn_7_deleteBTN.png"
                          alt="subBTn_7_deleteBTN"
                        />
                      </div>
                    );
                  })}
                </MenuOverflowXHidden>
              ) : null}

              <CameraFileForm
                multiple={"multiple"}
                handleChange={handleChange}
              />
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.8px",
                  color: "#919191",
                  fontWeight: 400,
                  marginTop: isMobile ? 11 : 8,
                  marginBottom: isMobile ? 36 : 80,
                }}
              >
                Nếu bạn có một bức ảnh có thể giúp bạn hiểu được tình trạng của
                sản phẩm, vui lòng đính kèm nó.. (Tối đa N, NNMB hoặc ít hơn)
              </PretendardText>

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>3</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Tôi có thể giúp gì cho vấn đề của bạn?
                </SectionTitleText>
              </SectionTitleDiv>

              {isMobile ? (
                <div
                  style={{
                    marginBottom: 36,
                  }}
                >
                  <RightLabelRadio
                    textStyle={{
                      fontWeight: exchangeType === "exchange" ? 500 : 400,
                      color:
                        exchangeType === "exchange" ? "#313131" : "#626262",
                    }}
                    inputStyle={{
                      marginBottom: 20,
                    }}
                    checked={exchangeType === "exchange"}
                    onClick={() => {
                      setExchangeType("exchange");
                    }}
                    text={"Đổi hàng"}
                  />
                  <RightLabelRadio
                    textStyle={{
                      fontWeight: exchangeType === "return" ? 500 : 400,
                      color: exchangeType === "return" ? "#313131" : "#626262",
                    }}
                    inputStyle={{}}
                    checked={exchangeType === "return"}
                    onClick={() => {
                      setExchangeType("return");
                    }}
                    text={"Trả hàng Hoàn tiền"}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 16,
                    marginBottom: 80,
                  }}
                >
                  <div
                    style={{
                      width: isTablet ? "48.2%" : 332,
                      height: 48,
                      cursor: "pointer",
                      border:
                        exchangeType === "exchange"
                          ? "1px solid #FF3D83"
                          : "1px solid #DCDCDC",
                      borderRadius: 8,
                      background:
                        exchangeType === "exchange" ? "#FFF6FA" : "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setExchangeType("exchange");
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "23.2px",
                        fontWeight: exchangeType === "exchange" ? 500 : 400,
                        color:
                          exchangeType === "exchange" ? "#313131" : "#626262",
                      }}
                    >
                      Đổi hàng
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isTablet ? "48%" : 332,
                      height: 48,
                      cursor: "pointer",
                      border:
                        exchangeType === "return"
                          ? "1px solid #FF3D83"
                          : "1px solid #DCDCDC",
                      borderRadius: 8,
                      background:
                        exchangeType === "return" ? "#FFF6FA" : "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setExchangeType("return");
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "23.2px",
                        fontWeight: exchangeType === "return" ? 500 : 400,
                        color:
                          exchangeType === "return" ? "#313131" : "#626262",
                      }}
                    >
                      Trả hàng Hoàn tiền
                    </PretendardText>
                  </div>
                </div>
              )}

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>4</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Vui lòng kiểm tra thông tin giao hàng / lấy hàng
                </SectionTitleText>
              </SectionTitleDiv>

              {exchangeType === "exchange" ? (
                <>
                  <ExchangeCard
                    onClick={() => {
                      addressType = 1;
                      setIsADodal(true);
                    }}
                    title={"Địa chỉ giao hàng"}
                    subTitle={"Ngày giao hàng dự kiến"}
                    orderInfo={orderInfo}
                    address={deliveryAddress}
                    requiredDays={requiredDays}
                    date={moment()
                      .add("days", requiredDays + requiredDays)
                      .format("YYYY. MM. DD")}
                  />

                  <ExchangeCard
                    onClick={() => {
                      addressType = 2;
                      setIsADodal(true);
                    }}
                    title={"Địa chỉ lấy hàng"}
                    subTitle={"Ngày lấy hàng dự kiến"}
                    orderInfo={orderInfo}
                    address={returnAddress}
                    requiredDays={requiredDays}
                    date={moment()
                      .add("days", requiredDays)
                      .format("YYYY. MM. DD")}
                  />
                </>
              ) : (
                <>
                  <ExchangeCard
                    onClick={() => {
                      addressType = 2;
                      setIsADodal(true);
                    }}
                    title={"Địa chỉ lấy hàng"}
                    subTitle={"Ngày giao hàng dự kiến"}
                    address={returnAddress}
                    orderInfo={orderInfo}
                    date={moment()
                      .add("days", requiredDays)
                      .format("YYYY. MM. DD")}
                  />

                  {/* <ExchangeCard
                    returnType={true}
                    orderInfo={orderInfo}
                    address={returnAddress}
                    onClick={() => {}}
                    title={"thông tin hoàn tiền"}
                    subTitle={"Số tiền hoàn trả dự kiến"}
                  /> */}
                </>
              )}

              <MainBtn
                disabled={reason ? false : true}
                onClick={() => {
                  exchangeFunc();
                }}
                style={{
                  marginTop: isMobile ? 16 : 80,
                  height: isMobile ? 55 : 48,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  Áp dụng
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ExchangeApplySection>

      {isADModal ? (
        <AddressModal
          setVisiable={setIsADodal}
          isScroll={false}
          onClick={addressChangeFunc}
          noUse={true}
        />
      ) : null}
    </>
  );
};

export default ExchangeApply;

const ExchangeApplySection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SectionTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  margin-bottom: ${(props) => (props.$isMobile ? "20px" : "24px")};
`;

const SectionTitleNumDiv = styled.div`
  height: 24px;
  width: 24px;
  background: rgb(255, 236, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 4px;
`;

const SectionTitleNumText = styled(PretendardText)`
  text-align: center;
  font-size: 14px;
  line-height: 19.6px;
  color: rgb(169, 0, 59);
  font-weight: 500;
`;

const SectionTitleText = styled(PretendardText)`
  text-align: ${(props) => (props.$isMobile ? "left" : "center")};
  font-size: 16px;
  line-height: 23.2px;
  color: #313131;
  font-weight: 600;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 16px;
  color: rgb(49, 49, 49);
  font-weight: 500;
  line-height: 23.2px;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "24px")};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props.$isMobile ? "1" : "2"}; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  width: 100%;

  gap: 12px;

  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
