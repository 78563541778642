import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";
import OrderListItem from "../../components/organisms/mypage/orderListItem";
import MypageMenuM from "../../components/templates/mypageMenuM";
import OrderListItemM from "../../components/organisms/mypage/orderListItemM";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import OrderApi from "../../api/orderApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";

import Empty from "../../components/organisms/empty";
import Pagination from "../../components/organisms/pagination";
import InputSearch from "../../components/atoms/input/inputSearch";
import DateInput from "../../components/atoms/input/dateInput";
import moment from "moment";

const OrderList = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [orderList, setOrderList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setStartDate(
      searchParams.get("startDate") ? searchParams.get("startDate") : ""
    );

    orderListFunc();
  }, [location]);

  const orderListFunc = async () => {
    try {
      setOrderList([]);

      let data = {
        page: page,
        size: pagination.size,
        orderStatus: "ORDER",
        startDate: searchParams.get("startDate")
          ? searchParams.get("startDate")
          : "",
        endDate: searchParams.get("startDate")
          ? moment(searchParams.get("startDate"))
              .add(1, "days")
              .format("YYYY-MM-DD")
          : "",
        keyword: searchParams.get("keyword") ? searchParams.get("keyword") : "",
      };

      const response = (await OrderApi.orderList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setOrderList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const orderCancelFunc = async (orderId) => {
    try {
      const response = (await OrderApi.orderCancel(orderId)).data.data;

      toast("Đơn đặt hàng đã hủy.");
      orderListFunc();
    } catch (error) {
      toast("Không thể hủy đơn đặt hàng.");
    }
  };

  return (
    <>
      <OrderListSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "16px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <MenuOverflowXHidden $isSmall={isSmall}>
              <div
                style={{
                  width: isMobile ? "100%" : 480,
                  marginTop: isSmall ? 12 : 0,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: 8,
                    marginBottom: isMobile ? 10 : 0,
                    width: isMobile ? "100%" : 240,
                  }}
                >
                  <DateInput
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);

                      navigate(
                        `${
                          route.mypage_order_list
                        }/1?keyword=${keyword}&startDate=${moment(
                          e.target.value
                        ).format("YYYY-MM-DD")}`
                      );
                    }}
                    placeholder={"YYYY-MM-DD"}
                  />
                </div>

                <InputSearch
                  style={{
                    background: "#fff",
                    fontSize: "16px",
                    lineHeight: "19.09px",
                  }}
                  placeholder={
                    isMobile
                      ? "Hãy tìm kiếm tên sản phẩm."
                      : "Hãy tìm kiếm tên sản phẩm."
                  }
                  value={keyword}
                  onChange={(value) => {
                    setKeyword(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.mypage_order_list}/1?&keyword=${keyword}&startDate=${startDate}`
                      );
                    }
                  }}
                  searchStyle={{
                    top: 9,
                  }}
                  setDataToKeyword={() => {
                    navigate(
                      `${route.mypage_order_list}/1?&keyword=${keyword}&startDate=${startDate}`
                    );
                  }}
                />
              </div>
            </MenuOverflowXHidden>
            {orderList.length > 0 ? (
              orderList.map((item, index) => {
                return isMobile ? (
                  <OrderListItemM
                    key={index}
                    style={{
                      marginBottom: orderList.length === index + 1 ? 0 : 60,
                    }}
                    data={item}
                    orderCancelFunc={orderCancelFunc}
                  />
                ) : (
                  <OrderListItem
                    key={index}
                    style={{
                      marginBottom: orderList.length === index + 1 ? 0 : 60,
                    }}
                    data={item}
                    orderCancelFunc={orderCancelFunc}
                  />
                );
              })
            ) : (
              <Empty title={"Không có sản phẩm đã đặt."} />
            )}

            {orderList.length > 0 && pagination.totalCount > 5 ? (
              <div
                style={{
                  marginTop: 44,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  route={route.mypage_order_list}
                  queryString={`keyword=${keyword}&startDate=${startDate}`}
                  currentPage={Number(page)}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                />
              </div>
            ) : null}
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </OrderListSection>
    </>
  );
};

export default OrderList;

const OrderListSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: ${(props) => (props.$isSmall ? "100%" : "100%")};
  justify-content: ${(props) => (props.$isSmall ? "flex-start" : "flex-end")};
  margin-bottom: ${(props) => (props.$isSmall ? "28px" : "32px")};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
