import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import GrayBorderBtn from "../../components/atoms/button/grayBorderBtn";
import MypageMenuM from "../../components/templates/mypageMenuM";

const ExchangeComplete = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const location = useLocation();
  const { merchInfo } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const isSmall = isMobile || isTablet;

  return (
    <>
      <ExchangeCompleteSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "0px 20px 0px 20px" : "0px",
            }}
          >
            <div
              onClick={() => {
                navigation(-1);
              }}
              style={{
                marginTop: isSmall ? 28 : 8,
                marginBottom: isMobile ? 29 : 27,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/keyboard_left.png"
                alt="keyboard_left"
              />
              <PretendardText
                style={{
                  marginLeft: 8,
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 500,
                }}
              >
                Yêu cầu Trả hàng / Hoàn tiền
              </PretendardText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: isMobile ? "0px" : "60px 100px",
                borderRadius: 8,
                background: "#fff",
              }}
            >
              <PretendardText
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#222222",
                  fontWeight: 600,
                  marginBottom: isMobile ? 43 : 40,
                }}
              >
                Yêu cầu Trả hàng / Hoàn tiền hoàn thành
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  background: isMobile ? "#fff" : "#FAFAFA",
                  border: isMobile ? "none" : "1px solid #EEEEEE",
                  padding: isMobile ? 0 : "20px 24px",
                  borderRadius: 8,
                }}
              >
                {merchInfo && merchInfo.length > 0
                  ? merchInfo.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            marginBottom:
                              merchInfo.length === index + 1 ? 0 : 16,
                          }}
                        >
                          <img
                            style={{
                              width: isMobile ? 72 : 120,
                              height: isMobile ? 72 : 120,
                              borderRadius: 6,
                              marginRight: isMobile ? 12 : 20,
                            }}
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                            alt="banner02"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <ProductTitle $isMobile={isMobile}>
                              {item.title}
                            </ProductTitle>
                            {item.optionFlag ? (
                              JSON.parse(item.optionInfo).map(
                                (item02, index) => {
                                  return (
                                    <PretendardText
                                      key={index}
                                      style={{
                                        fontSize: 14,
                                        lineHeight: "19.6px",
                                        color: "#939393",
                                        fontWeight: 400,
                                        marginBottom:
                                          item.optionInfo.length === index + 1
                                            ? 0
                                            : 5,
                                      }}
                                    >
                                      {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                    </PretendardText>
                                  );
                                }
                              )
                            ) : (
                              <PretendardText
                                style={{
                                  fontSize: 14,
                                  lineHeight: "19.6px",
                                  color: "#939393",
                                  fontWeight: 400,
                                }}
                              >
                                {`Số lượng: ${item.noOptionQuantity}`}
                              </PretendardText>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>

              <GrayBorderBtn
                onClick={() => {
                  navigation(`${route.mypage_order_list}/1`);
                }}
                style={{
                  height: isMobile ? 55 : 48,
                  marginTop: 20,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "19.09px",
                    color: "#222222",
                    fontWeight: 500,
                  }}
                >
                  Yêu cầu sản phẩm khác
                </PretendardText>
              </GrayBorderBtn>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ExchangeCompleteSection>
    </>
  );
};

export default ExchangeComplete;

const ExchangeCompleteSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 16px;
  color: rgb(49, 49, 49);
  font-weight: 500;
  line-height: 23.2px;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "24px")};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props.$isMobile ? "1" : "2"}; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
