import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { json, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import MypageMenuM from "../../components/templates/mypageMenuM";
import OrderApi from "../../api/orderApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import ImageZoomModal from "../../components/templates/modals/imageZoomModal";

let imageSrc = "";

const InquiryDetailPage = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { id } = useParams();
  const [dataInfo, setDataInfo] = useState();
  const [merchInfo, setMerchInfo] = useState([]);
  const [isModalVisiable, setIsModalVisiable] = useState(false);

  useEffect(() => {
    myInquiryDetailFunc();
  }, []);

  const myInquiryDetailFunc = async () => {
    try {
      let response = (await OrderApi.orderAskDetail(id)).data.data;

      setDataInfo(response.content);
      setMerchInfo(JSON.parse(response.content.order.merchInfo));
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <InquiryDetailPageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "0px 20px 0px 20px" : "0px 0px 0px 0px",
            }}
          >
            <div
              onClick={() => {
                navigation(-1);
              }}
              style={{
                marginTop: isSmall ? 28 : 8,
                marginBottom: isMobile ? 29 : 27,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/keyboard_left.png"
                alt="keyboard_left"
              />
              <PretendardText
                style={{
                  marginLeft: 8,
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 500,
                }}
              >
                Chi tiết yêu cầu
              </PretendardText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: isMobile ? "0px" : isTablet ? 20 : "32px",
                borderRadius: 8,
                background: "#fff",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                  marginBottom: isMobile ? 12 : 16,
                }}
              >
                yêu cầu sản phẩm
              </PretendardText>

              {merchInfo && merchInfo.length > 0
                ? merchInfo.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom:
                            merchInfo.length !== index + 1
                              ? 16
                              : isMobile
                              ? 28
                              : 32,
                        }}
                      >
                        <img
                          style={{
                            width: isMobile ? 72 : 88,
                            height: isMobile ? 72 : 88,
                            borderRadius: 6,
                            marginRight: isMobile ? 12 : 20,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.merch.imageServerFileName}`}
                          alt="banner02"
                        />
                        <div>
                          <ProductTitle>{item.merch.title}</ProductTitle>
                          {item.merch.optionFlag ? (
                            item.optionInfo.map((item02, index) => {
                              return (
                                <PretendardText
                                  key={index}
                                  style={{
                                    fontSize: 12,
                                    color: "#939393",
                                    fontWeight: 400,
                                  }}
                                >
                                  {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                </PretendardText>
                              );
                            })
                          ) : (
                            <PretendardText
                              style={{
                                fontSize: 12,
                                color: "#939393",
                                fontWeight: 400,
                              }}
                            >
                              {`Số lượng: ${item.merch.noOptionQuantity}`}
                            </PretendardText>
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                  marginBottom: isMobile ? 12 : 16,
                }}
              >
                Loại yêu cầu
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 500,
                  marginBottom: isMobile ? 28 : 32,
                }}
              >
                {dataInfo?.askType === "MERCH"
                  ? "Hỏi đáp sản phẩm"
                  : dataInfo?.askType === "CHANGE"
                  ? "Yêu cầu trao đổi/trả lại"
                  : dataInfo?.askType === "CANCEL"
                  ? "Yêu cầu hủy/hoàn tiền"
                  : dataInfo?.askType === "DELIVERY"
                  ? "Yêu cầu giao hàng"
                  : "Các yêu cầu khác"}
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                  marginBottom: isMobile ? 12 : 16,
                }}
              >
                Loại yêu cầu
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.8px",
                  color: "#313131",
                  fontWeight: 400,
                  wordBreak: "break-all",
                  marginBottom: isMobile ? 12 : 16,
                }}
              >
                {dataInfo?.question}
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: isMobile ? 12 : 16,
                }}
              >
                {dataInfo && dataInfo?.images.length > 0
                  ? dataInfo.images.map((item, index) => {
                      return (
                        <img
                          onClick={() => {
                            setIsModalVisiable(true);
                            imageSrc = item.imageServerFileName;
                          }}
                          style={{
                            width: isMobile ? 72 : 80,
                            height: isMobile ? 72 : 80,
                            cursor: "pointer",
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="banner02"
                        />
                      );
                    })
                  : null}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: isMobile ? 0 : isTablet ? 20 : "32px",
                borderRadius: 8,
                background: "#fff",
                marginTop: isMobile ? 28 : 20,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                  marginBottom: isMobile ? 12 : 16,
                }}
              >
                Loại yêu cầu
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "16.8px",
                  color: dataInfo?.answer ? "#313131" : "#adadad",
                  fontWeight: 400,
                }}
              >
                {dataInfo?.answer
                  ? dataInfo?.answer
                  : "Vẫn chưa có câu trả lời."}
              </PretendardText>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </InquiryDetailPageSection>

      {isModalVisiable ? (
        <ImageZoomModal
          data={imageSrc}
          setVisible={setIsModalVisiable}
          isScroll={false}
        />
      ) : null}
    </>
  );
};

export default InquiryDetailPage;

const InquiryDetailPageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 14px;
  color: #626262;
  font-weight: 500;
  line-height: 19.6px;
  margin-bottom: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
