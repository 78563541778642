import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";

function RegisterTypeItem({
  style,
  title,
  content,
  iconD,
  iconA,
  onClick,
  isOn,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <RegisterItem
        style={style}
        onClick={onClick}
        $isOn={isOn}
        $isMobile={isMobile}
      >
        <img
          style={{
            width: isMobile ? 60 : 86,
            height: isMobile ? 60 : 86,
            marginBottom: 20,
          }}
          src={isOn ? iconA : iconD}
          alt="ic_register_86"
        />
        <PretendardText
          style={{
            fontSize: isMobile ? 16 : 18,
            lineHeight: isMobile ? "23.2px" : "26.1px",
            color: isOn ? "#313131" : "#939393",
            textAlign: "center",
            fontWeight: 600,
            marginBottom: 8,
          }}
        >
          {title}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: isMobile ? 14 : 16,
            lineHeight: isMobile ? "19.6px" : "23.2px",
            color: isOn ? "#626262" : "#ABABAB",
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {content}
        </PretendardText>
      </RegisterItem>
    </>
  );
}

export default RegisterTypeItem;

const RegisterItem = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "350px")};
  height: ${(props) => (props.$isMobile ? "230px" : "280px")};
  cursor: pointer;
  border-radius: 12px;
  border: ${(props) =>
    props.$isOn ? "2px solid #FF3D83" : "2px solid rgb(232, 232, 232)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  box-shadow: ${(props) =>
    props.$isOn ? "2px 2px 20px 4px #d8789a33" : "none"};
`;
