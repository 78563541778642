import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import Pagination from "../../components/organisms/pagination";
import ProductItem from "../../components/templates/product/productItem";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";

import MerchApi from "../../api/merchApi";
import { errorHandler } from "../../util/errorHandler";
import useDisplay from "../../hooks/useDisplay";
import Empty from "../../components/organisms/empty";
import { route } from "../../router/route";

const ExhibitionDetail = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const { id, page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [bannerImage, setBannerImage] = useState();
  const [categoryProductList, setcategoryProductList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 20, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    setBannerImage(
      searchParams.get("bannerImage") ? searchParams.get("bannerImage") : ""
    );

    merchListFunc();
  }, [location]);

  const merchListFunc = async () => {
    try {
      setcategoryProductList([]);

      const params = {
        page: page,
        size: pagination.size,
        promotionId: id,
      };

      const response = (await MerchApi.getList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setcategoryProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ExhibitionDetailSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: isSmall ? "40px 20px 80px 20px" : "56px 0px 80px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: isMobile ? 198 : 352,
              border: isMobile ? "1px solid #E8E8E8" : "2px solid #E8E8E8",
              borderRadius: isMobile ? 12 : 20,
            }}
          >
            <img
              style={{
                width: "100%",
                height: isMobile ? 198 : 352,
              }}
              src={`${process.env.REACT_APP_MEDIA_URL}${bannerImage}`}
              alt="plan01"
            />
          </div>

          <div
            style={{
              marginTop: isMobile ? 40 : 62,
              display: "flex",
              width: "100%",
              gap: isMobile ? "24px 15px" : "40px 20px",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: isSmall ? "space-between" : "flex-start",
            }}
          >
            {categoryProductList.length > 0 ? (
              categoryProductList.map((item, index) => {
                return <ProductItem key={index} data={item} />;
              })
            ) : (
              <Empty title={"Vẫn chưa có phần thưởng."} />
            )}
          </div>

          {categoryProductList.length > 0 && pagination.totalCount > 20 ? (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Pagination
                route={route.exhibition_list_detail}
                queryString={``}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </ExhibitionDetailSection>
    </>
  );
};

export default ExhibitionDetail;

const ExhibitionDetailSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
