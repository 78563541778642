import { toast } from "react-toastify";

function errorHandler(error) {
  switch (error.response?.status) {
    case 500:
      toast(`${"Lỗi máy chủ. Hãy thử lại sau vài phút nữa."}`);
      break;
    case 400:
      if (error.response.data.code === 4001) {
        toast(`Không thể tìm thấy thông tin.`);
      } else {
        toast(`${"Giá trị nhập không chính xác."}`);
      }

      break;
    default:
      break;
  }
}

export { errorHandler };
