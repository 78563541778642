import api from "./api.js";

export default class CustomerCenterApi {
  static async getFAQList(data) {
    return await api.get(`faq`, data);
  }

  static async getNoticeList(data) {
    return await api.get(`notice`, data);
  }
}
