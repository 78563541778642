import useDisplay from "../../../hooks/useDisplay";

function PCContainer(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          maxWidth: 1180,
          width: isMobile ? "100%" : "auto",
          display: "flex",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default PCContainer;
