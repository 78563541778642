import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import { terms } from "../../../constant/provision";
import useDisplay from "../../../hooks/useDisplay";
import TermApi from "../../../api/termApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";

function TermModal({
  visiable,
  setVisiable,
  isScroll,
  termCategory,
  isDetail,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [content, setContent] = useState("");

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  useEffect(() => {
    termListFunc();
  }, [termCategory]);

  const termListFunc = async () => {
    try {
      const response = (await TermApi.termDetail(termCategory)).data.data
        .content;

      setContent(response?.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <TermModalWrapper $isMobile={isMobile}>
      <TermModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: 40,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            ></PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
              }}
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
              height: 700,
              overflowY: "auto",
              padding: isMobile ? "0px 10px" : "0px 40px",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                fontWeight: 400,
                color: "#313131",
              }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </TermModalContainer>
    </TermModalWrapper>
  );
}

export default TermModal;

const TermModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 70px 0; */
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const TermModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "800px")};
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;
