import { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";

function BannerSwiper({ bannerList }) {
  const swiperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div
        style={{
          position: "relative",
          marginBottom: 40,
          width: "100%",
        }}
      >
        <Swiper
          modules={[Autoplay, Navigation]}
          onSwiper={(it) => {
            swiperRef.current = it;
          }}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={0}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {bannerList?.length > 0
            ? bannerList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        width: "100%",
                        height: isMobile ? "auto" : isTablet ? 330 : "380px",
                        position: "relative",
                        border: isMobile
                          ? "1px solid #E8E8E8"
                          : "2px solid #E8E8E8",
                        borderRadius: 20,
                        objectFit: "contain",
                      }}
                    >
                      <img
                        onClick={() => {
                          if (item.newWindow) {
                            window.open(
                              item.link,
                              "_blank",
                              "noopener, noreferrer"
                            );
                          } else {
                            window.location.href = item.link;
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                        }}
                        src={
                          isMobile
                            ? `${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileNameMo}`
                            : `${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileNamePc}`
                        }
                        alt="banner01"
                      />
                    </div>
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>

        <img
          onClick={() => swiperRef.current?.slidePrev()}
          style={{
            position: "absolute",
            cursor: "pointer",
            left: isMobile ? 24 : 40,
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 4,
          }}
          src="/assets/icons/btn-left-arrow-40.png"
          alt="btn-left-arrow-40"
        />
        <img
          onClick={() => swiperRef.current?.slideNext()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: isMobile ? -6 : 0,
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 4,
          }}
          src="/assets/icons/btn-right-arrow-40.png"
          alt="btn-right-arrow-40"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            bottom: isMobile ? 10 : 20,
            right: 16,
            zIndex: 4,
          }}
        >
          {bannerList.map((item02, index02) => {
            return (
              <div
                key={index02}
                style={{
                  width:
                    index02 === activeIndex && !isMobile
                      ? 20
                      : index02 === activeIndex && isMobile
                      ? 13
                      : isMobile
                      ? 5
                      : 8,
                  height: isMobile ? 5 : 8,
                  borderRadius: 99,
                  background: index02 === activeIndex ? "#FF8BB5" : "#DCDCDC",
                  marginRight: 4,
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BannerSwiper;
