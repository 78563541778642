import api from "./api.js";

export default class AuthApi {
  static async join(data) {
    return await api.post(`auth/member/sign`, data);
  }

  static async socialJoin(data) {
    return await api.post(`auth/member/sign/social`, data);
  }

  static async login(data) {
    return await api.post(`auth/member/login`, data);
  }

  static async socialLogin(data) {
    return await api.post(`auth/member/social/login`, data);
  }

  static async emailCheck(data) {
    return await api.post(`auth/member/check/email`, data);
  }

  static async nicknameCheck(data) {
    return await api.post(`auth/member/check/nickname`, data);
  }

  static async sendEmail(data) {
    return await api.post(`auth/member/send/email`, data);
  }

  static async pwChange(data) {
    return await api.post(`auth/member/change/password`, data);
  }

  static async recommendCheck(data) {
    return await api.post(`auth/member/check/recommend`, data);
  }

  static async getNewToken() {
    return await api.post(`auth/member/token/refresh`);
  }
}
