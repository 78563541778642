import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import MypageMenuM from "../../components/templates/mypageMenuM";
import Empty from "../../components/organisms/empty";
import OrderApi from "../../api/orderApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Pagination from "../../components/organisms/pagination";

const InquiryListPage = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useParams();

  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    myInquiryListFunc();
  }, [location]);

  const myInquiryListFunc = async () => {
    try {
      setDataList([]);

      const data = {
        page: page,
        size: pagination.size,
      };

      let response = (await OrderApi.orderAskList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <InquiryListPageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : "32px",
                background: "#fff",
                borderRadius: 8,
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  const merchInfo = item.memberMerch;

                  return isMobile ? (
                    <div
                      onClick={() => {
                        navigate(
                          `${route.mypage_inquiry_list_detail}/${item.id}`
                        );
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: dataList.length === index + 1 ? 0 : 20,
                        paddingBottom: dataList.length === index + 1 ? 0 : 20,
                        borderBottom:
                          dataList.length === index + 1
                            ? "none"
                            : "1px solid #DCDCDC",
                        cursor: "pointer",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 16,
                        }}
                      >
                        <img
                          style={{
                            width: 72,
                            height: 72,
                            borderRadius: 6,
                            marginRight: 12,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${merchInfo.imageServerFileName}`}
                          alt="banner02"
                        />
                        <div>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "23.2px",
                              color: "#313131",
                              fontWeight: 500,
                              marginBottom: 4,
                            }}
                          >
                            {merchInfo.title}
                          </PretendardText>
                          {merchInfo.optionFlag ? (
                            JSON.parse(merchInfo.optionInfo).map(
                              (item02, index) => {
                                return (
                                  <PretendardText
                                    key={index}
                                    style={{
                                      fontSize: 14,
                                      lineHeight: "20.3px",
                                      color: "#999999",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                  </PretendardText>
                                );
                              }
                            )
                          ) : (
                            <PretendardText
                              style={{
                                fontSize: 14,
                                lineHeight: "20.3px",
                                color: "#999999",
                                fontWeight: 400,
                              }}
                            >
                              {`Số lượng: ${merchInfo.noOptionQuantity}`}
                            </PretendardText>
                          )}
                        </div>
                      </div>

                      <PretendardText
                        style={{
                          fontSize: 12,
                          lineHeight: "16.8px",
                          color: "#313131",
                          fontWeight: 500,
                          marginBottom: 8,
                        }}
                      >
                        {item.askType === "MERCH"
                          ? "Hỏi đáp sản phẩm"
                          : item.askType === "CHANGE"
                          ? "Yêu cầu trao đổi/trả lại"
                          : item.askType === "CANCEL"
                          ? "Yêu cầu hủy/hoàn tiền"
                          : item.askType === "DELIVERY"
                          ? "Yêu cầu giao hàng"
                          : "Các yêu cầu khác"}
                      </PretendardText>
                      <InquiryContent>{item.question}</InquiryContent>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        navigate(
                          `${route.mypage_inquiry_list_detail}/${item.id}`
                        );
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: dataList.length === index + 1 ? 0 : 20,
                        paddingBottom: dataList.length === index + 1 ? 0 : 20,
                        borderBottom:
                          dataList.length === index + 1
                            ? "none"
                            : "1px solid #DCDCDC",
                        cursor: "pointer",
                      }}
                      key={index}
                    >
                      <img
                        style={{
                          minWidth: 120,
                          maxWidth: 120,
                          minHeight: 120,
                          maxHeight: 120,
                          borderRadius: 6,
                          marginRight: 20,
                        }}
                        src={`${process.env.REACT_APP_MEDIA_URL}${merchInfo.imageServerFileName}`}
                        alt="banner02"
                      />
                      <div
                        style={{
                          height: 120,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color: "#626262",
                            fontWeight: 500,
                            marginBottom: 8,
                          }}
                        >
                          {merchInfo.title}
                        </PretendardText>
                        {merchInfo.optionFlag ? (
                          JSON.parse(merchInfo.optionInfo).map(
                            (item02, index) => {
                              return (
                                <PretendardText
                                  key={index}
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "16.8px",
                                    color: "#939393",
                                    fontWeight: 400,
                                    marginBottom: 8,
                                  }}
                                >
                                  {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                </PretendardText>
                              );
                            }
                          )
                        ) : (
                          <PretendardText
                            style={{
                              fontSize: 12,
                              lineHeight: "16.8px",
                              color: "#939393",
                              fontWeight: 400,
                              marginBottom: 8,
                            }}
                          >
                            {`Số lượng: ${merchInfo.noOptionQuantity}`}
                          </PretendardText>
                        )}

                        <PretendardText
                          style={{
                            fontSize: 12,
                            lineHeight: "16.8px",
                            color: "#313131",
                            fontWeight: 500,
                            marginBottom: 8,
                          }}
                        >
                          {item.askType === "MERCH"
                            ? "Hỏi đáp sản phẩm"
                            : item.askType === "CHANGE"
                            ? "Yêu cầu trao đổi/trả lại"
                            : item.askType === "CANCEL"
                            ? "Yêu cầu hủy/hoàn tiền"
                            : item.askType === "DELIVERY"
                            ? "Yêu cầu giao hàng"
                            : "Các yêu cầu khác"}
                        </PretendardText>
                        <InquiryContent>{item.question}</InquiryContent>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty title={"Không có thông tin liên quan đến câu hỏi."} />
              )}

              {dataList.length > 0 && pagination.totalCount > 5 ? (
                <div
                  style={{
                    marginTop: 44,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    route={route.mypage_inquiry_list}
                    queryString={``}
                    currentPage={Number(page)}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </InquiryListPageSection>
    </>
  );
};

export default InquiryListPage;

const InquiryListPageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const InquiryContent = styled(PretendardText)`
  font-size: 12px;
  color: #313131;
  font-weight: 400;
  line-height: 16.8px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
