import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import MerchApi from "../../../api/merchApi";

function Guide({ style }) {
  const [guideInfo01, setGuideIngo01] = useState();
  const [guideInfo02, setGuideIngo02] = useState();
  const [guideInfo03, setGuideIngo03] = useState();
  const [guideInfo04, setGuideIngo04] = useState();

  useEffect(() => {
    guideDetailFunc();
  }, []);

  const guideDetailFunc = async () => {
    try {
      const response = (await MerchApi.getGuide()).data.data.content;

      let guideInfo01 = response.filter(
        (item) => item.shippingSettingType === "SHIPPING"
      );
      setGuideIngo01(guideInfo01);

      let guideInfo02 = response.filter(
        (item) => item.shippingSettingType === "GUIDE"
      );
      setGuideIngo02(guideInfo02);

      let guideInfo03 = response.filter(
        (item) => item.shippingSettingType === "RULES"
      );
      setGuideIngo03(guideInfo03);

      let guideInfo04 = response.filter(
        (item) => item.shippingSettingType === "SELLER"
      );
      setGuideIngo04(guideInfo04);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <GuideContain style={style}>
        {guideInfo01 && guideInfo01.length > 0 ? (
          <>
            <GuideTitle>Thông tin vận chuyển</GuideTitle>
            <GuideContent>{guideInfo01[0].content}</GuideContent>
          </>
        ) : null}

        {/* 두번째 */}

        {guideInfo02 && guideInfo02.length > 0 ? (
          <>
            <GuideTitle
              style={{
                marginTop: 30,
              }}
            >
              Hướng dẫn trả hàng / hoàn tiền
            </GuideTitle>
            <GuideContent>{guideInfo02[0].content}</GuideContent>
          </>
        ) : null}

        {/* 세번째 */}
        {guideInfo03 && guideInfo03.length > 0 ? (
          <>
            <GuideTitle
              style={{
                marginTop: 30,
              }}
            >
              Mục hạn chế trả hàng / hoàn tiền
            </GuideTitle>
            <GuideContent>{guideInfo03[0].content}</GuideContent>
          </>
        ) : null}

        {/* 네번째 */}
        {guideInfo04 && guideInfo04.length > 0 ? (
          <>
            <GuideTitle
              style={{
                marginTop: 30,
              }}
            >
              Thông tin người bán
            </GuideTitle>
            <GuideContent>{guideInfo04[0].content}</GuideContent>
          </>
        ) : null}
      </GuideContain>
    </>
  );
}

export default Guide;

const GuideContain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
`;

const GuideTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  color: #313131;
  margin-bottom: 15px;
`;

const GuideContentD = styled(PretendardText)`
  width: 25px;
  font-size: 8px;
  font-weight: 400;
  line-height: 19.6px;
  color: #626262;
  text-align: center;
`;

const GuideContent = styled(PretendardText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #626262;
`;
