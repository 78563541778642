import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import MypageMenuM from "../../components/templates/mypageMenuM";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Pagination from "../../components/organisms/pagination";
import Empty from "../../components/organisms/empty";
import moment from "moment";

const NotificationListPage = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useParams();

  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    alarmListFunc();
  }, [location]);

  const alarmListFunc = async () => {
    try {
      setDataList([]);

      const data = {
        page: page,
        size: pagination.size,
      };

      let response = (await MemberApi.alarmList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <NotificationListPageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : "32px",
                background: "#fff",
                borderRadius: 8,
              }}
            >
              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        // navigation(route.mypage_inquiry_list_detail);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: dataList.length === index + 1 ? 0 : 20,
                        paddingBottom: dataList.length === index + 1 ? 0 : 20,
                        borderBottom:
                          dataList.length === index + 1
                            ? "none"
                            : "1px solid #DCDCDC",
                      }}
                      key={index}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "23.2px",
                          color: "#313131",
                          fontWeight: 600,
                          marginBottom: 8,
                        }}
                      >
                        {item.title}
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "19.6px",
                          color: "#626262",
                          fontWeight: 400,
                          marginBottom: 20,
                        }}
                      >
                        {item.content}
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "16.71px",
                          color: "#939393",
                          fontWeight: 400,
                        }}
                      >
                        {moment(item.createdAt).format("YYYY.MM.DD")}
                      </PretendardText>
                    </div>
                  );
                })
              ) : (
                <Empty title={"Không có thông báo."} />
              )}

              {dataList.length > 0 && pagination.totalCount > 5 ? (
                <div
                  style={{
                    marginTop: 44,
                  }}
                >
                  <Pagination
                    route={route.mypage_notification_list}
                    queryString={``}
                    currentPage={Number(page)}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </NotificationListPageSection>
    </>
  );
};

export default NotificationListPage;

const NotificationListPageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
