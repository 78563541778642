import { useEffect, useState } from "react";

import PretendardText from "../../atoms/text/pretendardText";

import GrayBorderBtn from "../../atoms/button/grayBorderBtn";
import moment from "moment";
import ConfirmModal from "../../templates/modals/confirmModal";
import MerchAskApi from "../../../api/merchAskApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";

function QAItemM({ style, data, setIsNotiModal, askListFunc, askEditFunc }) {
  const [isCheck, setIsCheck] = useState(data.answer ? true : false);
  const [isLock, setIsLock] = useState(data.secretFlag);
  const [isDetail, setIsDetail] = useState(false);
  const [isMy, setIsMy] = useState(false);
  const [email, setEmail] = useState("");

  const [isDelConfirmModal, setIsDelCofirmModal] = useState(false);

  useEffect(() => {
    let email = sessionStorage.getItem("email");

    setEmail(email);
  }, []);

  useEffect(() => {
    if (email === data.email) {
      setIsMy(true);
    } else {
      setIsMy(false);
    }
  }, [email]);

  function maskLastThree(str) {
    if (str.length <= 2) {
      return "*".repeat(str.length); // 문자열 길이가 2 이하인 경우 전부 *로 변환
    }

    const visiblePart = str.slice(0, -2); // 마지막 세 글자를 제외한 부분
    const maskedPart = "*".repeat(2); // 마지막 세 글자 대신 *로 변환

    return visiblePart + maskedPart;
  }

  const askDelFunc = async () => {
    try {
      const params = {
        ids: [data.id],
      };

      await MerchAskApi.delAsk(params);
      await askListFunc();
      toast(`Đã xóa.`);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #DCDCDC",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "18px 20px",
          }}
        >
          <div
            style={{
              width: 58,
              height: 24,
              borderRadius: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: isCheck ? "#BA77EF" : "#ABABAB",
              marginBottom: 6,
            }}
          >
            <PretendardText
              style={{
                fontSize: 10,
                color: "#FFFFFF",
                fontWeight: 600,
              }}
            >
              {isCheck ? "Check" : "Inquiring"}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 6,
            }}
          >
            <PretendardText
              style={{
                fontSize: 14,
                color: "#939393",
                fontWeight: 400,
                marginRight: 8,
              }}
            >
              {maskLastThree(data.name)}
            </PretendardText>
            <PretendardText
              style={{
                fontSize: 14,
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {moment(data.createdAt).format("YYYY.MM.DD")}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isLock ? (
                <img
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 3,
                  }}
                  src="/assets/icons/ic-lock-20.png"
                  alt="ic-lock-20"
                />
              ) : null}

              <PretendardText
                style={{
                  fontSize: 16,
                  color: "#313131",
                  fontWeight: 600,
                  flex: 1,
                }}
              >
                {data.title}
              </PretendardText>
            </div>

            <img
              onClick={() => {
                if (isMy || !data.secretFlag) {
                  setIsDetail(!isDetail);
                } else {
                  setIsNotiModal(true);
                }
              }}
              style={{
                width: 22,
                height: 22,
                cursor: "pointer",
              }}
              src={
                isDetail
                  ? "/assets/icons/btn-arrow-up-40.png"
                  : "/assets/icons/btn-arrow-down-40.png"
              }
              alt="btn-arrow-up-40"
            />
          </div>
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                padding: "18px 20px",
                borderTop: "1px solid #DCDCDC",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {data.question}
              </PretendardText>

              {isMy ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <GrayBorderBtn
                    onClick={() => {}}
                    style={{
                      width: 94,
                      height: 32,
                      marginRight: 12,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "19.6px",
                        color: "#313131",
                        fontWeight: 600,
                      }}
                    >
                      sự thụ tinh
                    </PretendardText>
                  </GrayBorderBtn>
                  <GrayBorderBtn
                    onClick={() => {
                      setIsDelCofirmModal(true);
                    }}
                    style={{
                      width: 94,
                      height: 32,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "19.6px",
                        color: "#313131",
                        fontWeight: 600,
                      }}
                    >
                      sự xoá bỏ
                    </PretendardText>
                  </GrayBorderBtn>
                </div>
              ) : null}
            </div>
            {data.answer ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  background: "rgba(243, 241, 242, 0.5)",
                  borderTop: "1px solid #DCDCDC",
                  padding: "18px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      color: "#939393",
                      fontWeight: 400,
                      marginRight: 10,
                    }}
                  >
                    sự trả lời
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 14,
                      color: "#939393",
                      fontWeight: 400,
                    }}
                  >
                    {moment(data.updatedAt).format("YYYY.MM.DD")}
                  </PretendardText>
                </div>
                <PretendardText
                  style={{
                    fontSize: 14,
                    color: "#626262",
                    fontWeight: 400,
                  }}
                >
                  {data.answer}
                </PretendardText>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  background: "rgba(243, 241, 242, 0.5)",
                  borderTop: "1px solid #DCDCDC",
                  padding: "50px 20px",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    color: "#626262",
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  Vẫn chưa có câu trả lời.
                </PretendardText>
              </div>
            )}
          </div>
        ) : null}
      </div>

      {isDelConfirmModal ? (
        <ConfirmModal
          isScroll={false}
          title={"Hay là xóa bài đăng đi?"}
          onClick={() => {
            setIsDelCofirmModal(false);
          }}
          onYClick={() => {
            setIsDetail(false);
            askDelFunc();
            setIsDelCofirmModal(false);
          }}
        />
      ) : null}
    </>
  );
}

export default QAItemM;
