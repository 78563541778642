import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import Pagination from "../../components/organisms/pagination";
import InputSearch from "../../components/atoms/input/inputSearch";
import DashboardSellTable from "../../components/templates/influencer/dashboardSellTable";

import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Empty from "../../components/organisms/empty";
import moment from "moment";

const Dashboard = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [myID, setMyID] = useState("");

  const [attriMemberList, setAttriMemberList] = useState([]);
  const [followingList, setFollowingList] = useState([]);

  const [sellList, setSellList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });
  const [keyword, setKeyword] = useState("");

  const [categoryValueInfo, setCategoryValueInfo] = useState();
  const [categoryValue, setCategoryValue] = useState("Tất cả");
  const [subCategoryList, setSubCategoryList] = useState([{ title: "Tất cả" }]);

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category") ? searchParams.get("category") : "Tất cả"
    );
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");

    sellListFunc();
  }, [location]);

  const sellListFunc = async () => {
    try {
      setSellList([]);

      let data;
      if (
        searchParams.get("category") === null ||
        searchParams.get("category") === "Tất cả"
      ) {
        data = {
          page: page,
          size: pagination.size,
          keyword: searchParams.get("keyword"),
        };
      } else {
        data = {
          page: page,
          size: pagination.size,
          keyword: searchParams.get("keyword"),
          historyCategoryId: categoryValueInfo.id,
        };
      }

      let response = await (await MemberApi.sellHistory(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setSellList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }
      errorHandler(error);
    }
  };

  useEffect(() => {
    const myID = sessionStorage.getItem("id");
    const recommendID = sessionStorage.getItem("recommendID");

    setMyID(myID);
    saleCategoryListFunc();
    attributionMemberListFunc(recommendID);
    followerListFunc();
  }, []);

  const saleCategoryListFunc = async () => {
    try {
      const response = (await MemberApi.saleCategory()).data.data.content;

      setSubCategoryList([...subCategoryList, ...response]);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const attributionMemberListFunc = async (recommendID) => {
    try {
      const data = {
        page: 1,
        size: 0,
        recommendID: recommendID,
      };

      const response = (await MemberApi.memberList(data)).data.data;
      setAttriMemberList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const followerListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };

      const response = (await MemberApi.followerList(data)).data.data;
      setFollowingList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <DashboardSection>
        <PCContainer
          style={{
            flexDirection: "column",
            width: "100%",
            padding: isSmall ? "40px 20px 80px 20px" : "80px 0px 80px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              justifyContent: "space-between",
              marginBottom: isSmall ? 40 : 60,
            }}
          >
            <div
              style={{
                width: isSmall ? "100%" : 580,
                height: isMobile ? "auto" : 160,
                background: "#FFECF3",
                borderRadius: 8,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "space-between",
                marginRight: isMobile ? 0 : 20,
                padding: isMobile ? "20px" : "0px 30px",
                marginBottom: isSmall ? 20 : 0,
              }}
            >
              <div
                style={{
                  width: isMobile ? "100%" : 433,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 20,
                    lineHeight: "28px",
                    color: "#313131",
                    fontWeight: 700,
                    marginBottom: 8,
                  }}
                >
                  Xem hồ sơ của tôi
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#626262",
                    fontWeight: 400,
                    marginBottom: isMobile ? 12 : 0,
                  }}
                >
                  Bạn có thể xem nhanh các video đã tải lên của tôi và các sản
                  phẩm liên quan!
                </PretendardText>
              </div>

              <img
                onClick={() => {
                  navigate(`${route.influencer_list_detail}/${myID}/1`);
                }}
                style={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                }}
                src="/assets/icons/btn-right-arrow-40.png"
                alt="btn-right-arrow-40"
              />
            </div>

            <div
              style={{
                width: isSmall ? "100%" : 580,
                height: isMobile ? "auto" : 160,
                background: "rgba(186, 119, 239, 0.1)",
                borderRadius: 8,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "space-between",
                padding: isMobile ? "20px" : "0px 30px",
              }}
            >
              <div
                style={{
                  width: isMobile ? "100%" : 433,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 20,
                    lineHeight: "28px",
                    color: "#313131",
                    fontWeight: 700,
                    marginBottom: 8,
                  }}
                >
                  Tải lên video
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#626262",
                    fontWeight: 400,
                    marginBottom: isMobile ? 12 : 0,
                  }}
                >
                  Tải lên video của riêng bạn!
                </PretendardText>
              </div>

              <img
                onClick={() => {
                  navigate(route.video_register);
                }}
                style={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                }}
                src="/assets/icons/btn-right-arrow-40.png"
                alt="btn-right-arrow-40"
              />
            </div>
          </div>

          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 24,
              lineHeight: isMobile ? "28px" : "33.6px",
              color: "#222222",
              fontWeight: 600,
              marginBottom: 20,
            }}
          >
            Quản lý thành viên gia nhập
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <YScrollCard
              style={{
                marginRight: isMobile ? 0 : 20,
                marginBottom: isMobile ? 20 : 0,
              }}
              $isMobile={isMobile}
            >
              <YScrollCardTitleRow $isMobile={isMobile}>
                <YScrollCardTitle $isMobile={isMobile}>
                  Thành viên gia nhập
                </YScrollCardTitle>
                <YScrollCardTitle $isMobile={isMobile}>
                  {Number(attriMemberList.length).toLocaleString()} người
                </YScrollCardTitle>
              </YScrollCardTitleRow>

              <div style={{}}>
                {attriMemberList.length > 0 ? (
                  attriMemberList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginBottom: 10,
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          justifyContent: "space-between",
                          alignItems: isMobile ? "flex-start" : "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            color: "#313131",
                            fontWeight: 400,
                            marginBottom: isMobile ? 4 : 0,
                          }}
                        >
                          {item.email}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            color: "#939393",
                            fontWeight: 400,
                          }}
                        >
                          {moment(item.createdAt).format("YYYY.MM.DD")}
                        </PretendardText>
                      </div>
                    );
                  })
                ) : (
                  <Empty title={"Không có thành viên liên kết."} />
                )}
              </div>
            </YScrollCard>

            <YScrollCard $isMobile={isMobile}>
              <YScrollCardTitleRow $isMobile={isMobile}>
                <YScrollCardTitle $isMobile={isMobile}>
                  Follower
                </YScrollCardTitle>
                <YScrollCardTitle $isMobile={isMobile}>
                  {Number(followingList.length).toLocaleString()} người
                </YScrollCardTitle>
              </YScrollCardTitleRow>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: isMobile ? "10px 0px" : "16px 20px",
                }}
              >
                {followingList.length > 0 ? (
                  followingList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: 256,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            item.following.imageServerFileName
                              ? `${process.env.REACT_APP_MEDIA_URL}${item.following.imageServerFileName}`
                              : "/assets/icons/empty_person.png"
                          }
                          alt="user"
                          style={{
                            width: 72,
                            height: 72,
                            borderRadius: 8,
                            marginRight: 12,
                          }}
                        />
                        <div style={{ flex: 1 }}>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "23.2px",
                              color: "#313131",
                              fontWeight: 600,
                              marginBottom: 4,
                            }}
                          >
                            {item.following.nickname}
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "23.2px",
                              color: "#626262",
                              fontWeight: 400,
                            }}
                          >
                            {item.following.email}
                          </PretendardText>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Empty title={"Chưa có dữ liệu."} />
                )}
              </div>
            </YScrollCard>
          </div>

          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 24,
              lineHeight: isMobile ? "28px" : "33.6px",
              color: "#222222",
              fontWeight: 600,
              marginTop: isMobile ? 40 : 60,
              marginBottom: isMobile ? 16 : 20,
            }}
          >
            Lịch sử mua hàng
          </PretendardText>

          <OverflowXHidden
            style={{
              marginBottom: isMobile ? 20 : 40,
            }}
          >
            {subCategoryList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    padding: isMobile ? "4px 12px" : "12px 20px",
                    border:
                      categoryValue === item.title
                        ? "2px solid #FF649C"
                        : "2px solid #E8E8E8",
                    borderRadius: 6,
                    marginRight:
                      subCategoryList.length === index + 1
                        ? 0
                        : isMobile
                        ? 8
                        : 15,
                    background:
                      categoryValue === item.title ? "#FF649C" : "#ffffff",
                  }}
                  onClick={() => {
                    setCategoryValueInfo(item);
                    navigate(
                      `${route.dashboard}/1?category=${item.title}&keyword=${keyword}`
                    );
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 14 : 16,
                      lineHeight: isMobile ? "19.6px" : "23.2px",
                      fontWeight: categoryValue === item.title ? 700 : 500,
                      color:
                        categoryValue === item.title ? "#FFFFFF" : "#313131",
                      width: "max-content",
                    }}
                  >
                    {item.title}
                  </PretendardText>
                </div>
              );
            })}
          </OverflowXHidden>

          <div
            style={{
              marginBottom: isMobile ? 28 : 20,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: isMobile ? "100%" : 480,
              }}
            >
              <InputSearch
                placeholder={"Hãy tìm kiếm tên sản phẩm."}
                value={keyword}
                onChange={(value) => {
                  setKeyword(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.dashboard}/1?category=${categoryValue}&keyword=${keyword}`
                    );
                  }
                }}
                searchStyle={{
                  top: 9,
                }}
                setDataToKeyword={() => {
                  navigate(
                    `${route.dashboard}/1?category=${categoryValue}&keyword=${keyword}`
                  );
                }}
              />
            </div>
          </div>

          {sellList.length > 0 ? (
            <DashboardSellTable data={sellList} />
          ) : (
            <Empty title={"Chưa có lịch sử bán hàng."} />
          )}

          {sellList.length > 0 && pagination.totalCount > 10 ? (
            <div
              style={{
                marginTop: 40,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                route={route.dashboard}
                queryString={`category=${categoryValue}&keyword=${keyword}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </DashboardSection>
    </>
  );
};

export default Dashboard;

const DashboardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const YScrollCard = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "580px")};
  padding: 24px 20px;
  background: rgb(249, 249, 249);
  border-radius: 12px;
  height: 469px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const YScrollCardTitleRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  justify-content: space-between;
  border-bottom: 1px solid rgb(220, 220, 220);
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const YScrollCardTitle = styled(PretendardText)`
  font-size: 18px;
  line-height: 26.1px;
  color: rgb(49, 49, 49);
  font-weight: 600;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "0px")};
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
