import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

function InputSearch(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        ...props.parentStyle,
      }}
    >
      <InputWrapper
        id={props.id ? props.id : "inputSearch"}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
      />

      <img
        onClick={() => {
          props.setDataToKeyword();
        }}
        src="/assets/icons/search.png"
        alt=""
        style={{
          position: "absolute",
          top: "10px",
          right: "20px",
          width: 24,
          height: 24,
          cursor: "pointer",
          ...props.searchStyle,
        }}
      />
    </div>
  );
}

export default InputSearch;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 44px;
  border-radius: 8px;
  padding: 20px 12px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;

  font-family: "Pretendard";
  color: #313131;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.09px;

  &:focus {
    outline: 1px solid #ff649c;
  }

  &::placeholder {
    color: #b6b6b6;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
  }
`;
