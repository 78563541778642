import api from "./api.js";

export default class MemberApi {
  static async getMyInfo() {
    return await api.get(`member/login/info`);
  }

  static async updateMyInfo(data) {
    return await api.patch(`member/my-info`, data);
  }

  static async retired(data) {
    return await api.post(`member/retired-member`, data);
  }

  static async getMyCouponPossible() {
    return await api.get(`member/member-coupon/possible`, {
      page: 1,
      size: 999,
    });
  }

  static async getMyCoupon() {
    return await api.get(`member/member-coupon`, {
      page: 1,
      size: 999,
    });
  }

  static async updateAddressInfo(id, data) {
    return await api.patch(`member/address/${id}`, data);
  }

  static async memberList(data) {
    return await api.get(`all/member`, data);
  }

  static async memberDetail(id) {
    return await api.get(`all/member/${id}`);
  }

  static async followTrigger(id) {
    return await api.post(`member/follow/${id}`);
  }

  static async followingList(data) {
    return await api.get(`member/follow/following`, data);
  }

  static async followerList(data) {
    return await api.get(`member/follow/follower`, data);
  }

  static async videoRegister(id, data) {
    return await api.post(`member/video/${id}`, data);
  }

  static async saleCategory() {
    return await api.get(`member/sale-category/my`, {
      page: 1,
      size: 0,
    });
  }

  static async sellHistory(data) {
    return await api.get(`member/point-history/my`, data);
  }

  static async alarmList(data) {
    return await api.get(`member/member-alarm/my`, data);
  }

  static async getPointList(data) {
    return await api.get(`member/point/my`, data);
  }

  static async getCouponList(data) {
    return await api.get(`member/member-coupon/my`, data);
  }

  static async getSettlement(data) {
    return await api.get(`member/settlement/my`, data);
  }

  static async addSettlement(data) {
    return await api.post(`member/settlement`, data);
  }
}
