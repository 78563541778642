import React, { useEffect, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import Pagination from "../../components/organisms/pagination";

import ProductItem from "../../components/templates/product/productItem";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import { route } from "../../router/route";
import SelectM from "../../components/atoms/select/selectM";
import BannerApi from "../../api/bannerApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import MerchApi from "../../api/merchApi";
import Empty from "../../components/organisms/empty";

const EventProduct = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [bannerInfo, setBannerInfo] = useState();
  const [eventProductList, setEventProductList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 20, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    {
      value: "Gần nhất",
      label: "Gần nhất",
    },
    {
      value: "Bán chạy",
      label: "Bán chạy",
    },
    {
      value: "Yêu thích",
      label: "Yêu thích",
    },
  ]);

  useEffect(() => {
    bannerListFunc();
  }, []);

  useEffect(() => {
    setFilterValue(
      searchParams.get("filterValue") ? searchParams.get("filterValue") : ""
    );

    merchListFunc();
  }, [location]);

  const bannerListFunc = async () => {
    try {
      const data = {
        subBannerType: "EVENT",
      };
      const response = await BannerApi.getSubList(data);
      setBannerInfo(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const merchListFunc = async () => {
    try {
      setEventProductList([]);

      const params = {
        page: page,
        size: pagination.size,
        eventFlag: true,
        orderType:
          searchParams.get("filterValue") === "Yêu thích"
            ? 3
            : searchParams.get("filterValue") === "Bán chạy"
            ? 2
            : 1,
      };

      const response = (await MerchApi.getList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setEventProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <EventProductSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isSmall ? "40px 20px 80px 20px" : "55px 0px 80px 0px",
            width: "100%",
          }}
        >
          {bannerInfo && bannerInfo?.imageServerFileNamePc ? (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: isSmall ? "auto" : 330,
                objectFit: "contain",
                marginBottom: 40,
              }}
            >
              <img
                onClick={() => {
                  window.location.href = bannerInfo.link;
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 20,
                  cursor: "pointer",
                }}
                src={
                  isMobile
                    ? `${process.env.REACT_APP_MEDIA_URL}${bannerInfo.imageServerFileNameMo}`
                    : `${process.env.REACT_APP_MEDIA_URL}${bannerInfo.imageServerFileNamePc}`
                }
                alt="new-banner03"
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <HomeIconMenuContain
              style={{
                marginRight: isMobile ? 16 : 20,
              }}
              onClick={() => {
                navigate(`${route.new_product}/1`);
              }}
              $isMobile={isMobile}
            >
              <img
                style={{
                  width: isMobile ? 54 : 72,
                  height: isMobile ? 54 : 72,
                }}
                src="/assets/icons/ic-new-72.png"
                alt="ic-new-72"
              />
              <HomeIconMenuText $isMobile={isMobile}>
                Sản phẩm mới
              </HomeIconMenuText>
            </HomeIconMenuContain>
            <HomeIconMenuContain
              onClick={() => {
                navigate(`${route.best_product}/1`);
              }}
              $isMobile={isMobile}
            >
              <img
                style={{
                  width: isMobile ? 54 : 72,
                  height: isMobile ? 54 : 72,
                }}
                src="/assets/icons/ic-best-72.png"
                alt="ic-best-72"
              />
              <HomeIconMenuText $isMobile={isMobile}>Bán chạy</HomeIconMenuText>
            </HomeIconMenuContain>
            <HomeIconMenuContain
              style={{
                marginRight: 0,
              }}
              $isMobile={isMobile}
            >
              <img
                style={{
                  width: isMobile ? 54 : 72,
                  height: isMobile ? 54 : 72,
                }}
                src="/assets/icons/ic-coupon-72-a.png"
                alt="ic-coupon-72-a"
              />
              <HomeIconMenuText
                $isMobile={isMobile}
                style={{
                  color: "#FF649C",
                }}
              >
                Sự kiện
              </HomeIconMenuText>
            </HomeIconMenuContain>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "space-between",
                marginTop: isMobile ? 60 : 57,
                marginBottom: isMobile ? 24 : 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: isMobile ? 40 : 0,
                }}
              >
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 10,
                  }}
                  src="/assets/icons/ic-event-24.png"
                  alt="ic-event-24"
                />
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: isMobile ? "26.1px" : "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  EVENT! Đừng bỏ lỡ Deal HOT
                </PretendardText>
              </div>
              {isSmall ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  <SelectM
                    style={{}}
                    setValue={(value) => {
                      navigate(`${route.event_product}/1?filterValue=${value}`);
                    }}
                    value={filterValue}
                    dataList={filterList}
                    placeholder={"Lựa chọn"}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {filterList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CategoryText
                          onClick={() => {
                            navigate(
                              `${route.event_product}/1?filterValue=${item.value}`
                            );
                          }}
                          $isActive={filterValue === item.value}
                        >
                          {item.label}
                        </CategoryText>
                        {filterList.length === index + 1 ? null : (
                          <div
                            style={{
                              height: 12,
                              width: 1,
                              background: "#C4C4C4",
                              margin: "0px 8px",
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                gap: isMobile ? "24px 15px" : "40px 20px",
                justifyContent: isSmall ? "space-between" : "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {eventProductList.length > 0 ? (
                eventProductList.map((item, index) => {
                  return <ProductItem key={index} data={item} />;
                })
              ) : (
                <Empty title={"Vẫn chưa có phần thưởng."} />
              )}
            </div>
          </div>

          {eventProductList.length > 0 && pagination.totalCount > 20 ? (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Pagination
                route={route.event_product}
                queryString={`filterValue=${filterValue}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </EventProductSection>
    </>
  );
};

export default EventProduct;

const EventProductSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const HomeIconMenuContain = styled.div`
  display: flex;
  margin-right: ${(props) => (props.$isMobile ? "16px" : "40px")};
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-width: ${(props) => (props.$isMobile ? "85px" : "auto")};
`;

const HomeIconMenuText = styled(PretendardText)`
  margin-top: 14px;
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
  font-weight: 500;
  color: rgb(49, 49, 49);
  text-align: center;
`;

const CategoryText = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#ff649c" : "rgb(98, 98, 98)")};

  cursor: pointer;

  &:hover {
    color: #ff649c;
  }
`;
