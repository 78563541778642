import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import Checkbox from "../../atoms/checkbox/checkbox";
import GrayBorderBtn from "../../atoms/button/grayBorderBtn";

import { route } from "../../../router/route";

function ProductBasketItemM({
  data,
  style,
  setDIDList,
  dIDList,
  basketProductList,
  setAllCheck,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();

  let optionInfo = JSON.parse(data.optionInfo);

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  function amountCalFunc(optionFlag) {
    let amount = 0;

    if (optionFlag) {
      for (let i = 0; i < optionInfo.length; i++) {
        amount +=
          optionInfo[i].optionValue.price * optionInfo[i].optionValue.quantity;
      }
      amount += data.merch.salePrice;
    } else {
      amount = data.noOptionQuantity * data.merch.salePrice;
    }

    // 소수점 첫째 자리에서 반올림
    return amount;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          position: "relative",
          padding: isTablet ? "24px 40px" : 20,
          borderTop: "1px solid #DCDCDC",
          flex: 1,
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Checkbox
            checked={dIDList.some((item) => item === data.id)}
            size={24}
            onChange={() => {
              let temp;
              if (dIDList.some((item) => item === data.id)) {
                temp = dIDList.filter((item) => item !== data.id);
                setDIDList(temp);
              } else {
                temp = [...dIDList, data.id];
                setDIDList(temp);
              }

              const idList = basketProductList.map((item) => item.id);
              const allValuesExist =
                temp.length > 0 &&
                idList.every((value) => temp.includes(value));

              if (allValuesExist) {
                setAllCheck(true);
              } else {
                setAllCheck(false);
              }
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: isMobile ? "flex-start" : "center",
              marginLeft: isMobile ? 10 : 15,
            }}
          >
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}${data.merch.imageServerFileName}`}
              className="sample04"
              style={{
                width: isMobile ? 65 : 120,
                height: isMobile ? 65 : 120,
                borderRadius: 8,
                marginRight: isMobile ? 10 : 15,
              }}
            />
            <div
              style={{
                width: "100%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 16,
                  lineHeight: isMobile ? "19.6px" : "23.2px",
                  color: "#222222",
                  fontWeight: 600,
                  marginBottom: isMobile ? 5 : 4,
                }}
              >
                {data.merch.title}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 12 : 16,
                  lineHeight: isMobile ? "16.8px" : "23.2px",
                  color: "#939393",
                  fontWeight: 600,
                  marginBottom: isMobile ? 5 : 4,
                }}
              >
                {data.merch.brand}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 16,
                  lineHeight: isMobile ? "19.6px" : "23.2px",
                  color: "#ABABAB",
                  fontWeight: 400,
                  marginBottom: isMobile ? 5 : 2,
                  textDecoration: "line-through",
                }}
              >
                {Number(data.merch.originalPrice).toLocaleString()} đồng
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    lineHeight: isMobile ? "19.6px" : "26.1px",
                    color: "#313131",
                    fontWeight: 700,
                    marginRight: isMobile ? 5 : 6,
                  }}
                >
                  {Number(data.merch.salePrice).toLocaleString()} đồng
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    lineHeight: isMobile ? "19.6px" : "26.1px",
                    color: "#FF649C",
                    fontWeight: 700,
                  }}
                >
                  {calculateDiscountRate(
                    Number(data.merch.originalPrice),
                    Number(data.merch.salePrice)
                  )}
                  %
                </PretendardText>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "16px 0px",
            height: 1,
            background: "#DCDCDC",
            width: "100%",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            {data.optionFlag ? (
              optionInfo.map((item, index) => {
                return (
                  <PretendardText
                    key={index}
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#939393",
                      fontWeight: 400,
                      marginBottom: optionInfo.length === index + 1 ? 10 : 0,
                      textAlign: "left",
                    }}
                  >
                    {`${item.option.title}: ${item.optionValue.title}, ${item.optionValue.quantity}`}
                  </PretendardText>
                );
              })
            ) : (
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#939393",
                  fontWeight: 400,
                  marginBottom: 10,
                  textAlign: "left",
                }}
              >
                Số lượng: {data.noOptionQuantity}
              </PretendardText>
            )}
          </div>
          <GrayBorderBtn
            onClick={() => {
              navigation(`${route.product_detail}/${data.merch.id}`, {
                state: {
                  optionInfo: optionInfo,
                  amountState: data.noOptionQuantity,
                },
              });
            }}
            style={{
              width: isMobile ? 134 : 165,
              height: isMobile ? 41 : 47,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 12 : 16,
                lineHeight: isMobile ? "16.8px" : "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Sửa đổi tùy chọn
            </PretendardText>
          </GrayBorderBtn>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 14,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "19.09px",
                color: "#222222",
                fontWeight: 400,
              }}
            >
              Tổng tiền hàng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "19.09px",
                color: "#222222",
                fontWeight: 700,
              }}
            >
              {amountCalFunc(data.optionFlag).toLocaleString()} đồng
            </PretendardText>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "19.09px",
                color: "#222222",
                fontWeight: 400,
              }}
            >
              Phí giao hàng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "19.09px",
                color: "#222222",
                fontWeight: 700,
              }}
            >
              {Number(data.merch.deliveryCost).toLocaleString()} đồng
            </PretendardText>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ProductBasketItemM;
