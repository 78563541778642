import api from "./api.js";

export default class MerchAskApi {
  static async addAsk(id, data) {
    return await api.post(`member/merchAsk/${id}`, data);
  }

  static async updateAsk(id, data) {
    return await api.patch(`member/merchAsk/${id}`, data);
  }

  static async delAsk(data) {
    return await api.del(`member/merchAsk`, data);
  }
}
