import api from "./api.js";

export default class ReviewApi {
  static async getList(id, data) {
    return await api.get(`review/${id}`, data);
  }

  static async myReviewList(data) {
    return await api.get(`member/member-merch/my`, data);
  }

  static async myReviewDetail(id) {
    return await api.get(`member/review/${id}`);
  }

  static async myReviewEdit(id, data) {
    return await api.patch(`member/review/${id}`, data);
  }

  static async reviewAdd(id, data) {
    return await api.post(`member/review/${id}`, data);
  }

  static async getPointSetting() {
    return await api.get(`point-setting`);
  }
}
