import React, { useState, useEffect } from "react";
import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";

function TextArea(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <TextAreaWrapper
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
      />
    </>
  );
}

export default TextArea;

const TextAreaWrapper = styled.textarea.attrs((props) => {})`
  width: 100%;
  box-sizing: border-box;

  height: 185px;

  border-radius: 8px;
  padding: 16px 15px;
  background: #ffffff;
  border: 2px solid #e8e8e8;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  resize: none;

  &:focus {
    background: #fff;
    outline: 2px solid #ff649c;
  }

  &:disabled {
    outline: none;
    background: #adb4ba;
    color: #778088;
  }

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: #c4c4c4;
  }
`;
