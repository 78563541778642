import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useNavigate } from "react-router-dom";

function Pagination({
  route,
  queryString,
  totalPages,
  currentPage,
  rangeSize,
  style,
  setPage,
}) {
  const navigate = useNavigate();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize);

    let firstNumber = pageGroup * rangeSize - (rangeSize - 1);
    if (firstNumber <= 0) {
      firstNumber = 1;
    }

    let lastNumber = pageGroup * rangeSize;
    if (lastNumber > totalPages) {
      lastNumber = totalPages;
    }

    const pageList = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i);
    }

    return pageList;
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {pageList.length > 0 ? (
        <PaginationWrapper style={style}>
          <ArrowContainer>
            <ArrowBox
              onClick={() => {
                if (currentPage > rangeSize) {
                  if (route) {
                    navigate(
                      route + `/${Number(currentPage) - 5}?${queryString}`
                    );
                  } else {
                    setPage(Number(currentPage) - 5);
                  }
                }
              }}
            >
              <img
                src="/assets/icons/page/left_arrow_2.png"
                alt="left_arrow_2"
                style={{
                  width: 32,
                  height: 32,
                  filter:
                    currentPage > rangeSize
                      ? "invert(75%) sepia(0%) saturate(1%) hue-rotate(33deg) brightness(93%) contrast(88%)"
                      : "invert(13%) sepia(7%) saturate(82%) hue-rotate(314deg) brightness(94%) contrast(81%)",
                }}
              />
            </ArrowBox>
            <ArrowBox
              onClick={() => {
                if (currentPage == 1) {
                  return;
                }

                if (route) {
                  navigate(
                    route + `/${Number(currentPage) - 1}?${queryString}`
                  );
                } else {
                  setPage(Number(currentPage) - 1);
                }
              }}
            >
              <img
                src="/assets/icons/page/left_arrow.png"
                alt="left_arrow"
                style={{
                  width: 32,
                  height: 32,
                  filter:
                    currentPage == 1
                      ? "invert(13%) sepia(7%) saturate(82%) hue-rotate(314deg) brightness(94%) contrast(81%)"
                      : "invert(75%) sepia(0%) saturate(1%) hue-rotate(33deg) brightness(93%) contrast(88%)",
                }}
              />
            </ArrowBox>
          </ArrowContainer>
          <NumberWrapper>
            {pageList.map((item, index) => {
              return (
                <PageWrapper
                  key={index}
                  style={{
                    background:
                      Number(currentPage) === item ? "#FFECF3" : "transparent",
                  }}
                  onClick={() => {
                    if (route) {
                      navigate(route + `/${item}?${queryString}`);
                    } else {
                      setPage(Number(item));
                    }
                  }}
                >
                  <PageText
                    style={{
                      color:
                        Number(currentPage) === item ? "#313131" : "#939393",
                    }}
                  >
                    {item}
                  </PageText>
                </PageWrapper>
              );
            })}
          </NumberWrapper>
          <ArrowContainer>
            <ArrowBox
              onClick={() => {
                if (currentPage == totalPages) {
                  return;
                }

                if (route) {
                  navigate(
                    route + `/${Number(currentPage) + 1}?${queryString}`
                  );
                } else {
                  setPage(Number(currentPage) + 1);
                }
              }}
            >
              <img
                src="/assets/icons/page/right_arrow.png"
                alt="right_arrow"
                style={{
                  width: 32,
                  height: 32,
                  filter:
                    currentPage !== totalPages
                      ? "invert(13%) sepia(7%) saturate(82%) hue-rotate(314deg) brightness(94%) contrast(81%)"
                      : "invert(75%) sepia(0%) saturate(1%) hue-rotate(33deg) brightness(93%) contrast(88%)",
                }}
              />
            </ArrowBox>
            <ArrowBox
              onClick={() => {
                if (totalPages > currentPage + rangeSize) {
                  if (route) {
                    navigate(
                      route + `/${Number(currentPage) + 5}?${queryString}`
                    );
                  } else {
                    setPage(Number(currentPage) + 5);
                  }

                  return;
                }

                if (totalPages > Number(pageList[pageList.length - 1])) {
                  if (route) {
                    navigate(
                      route +
                        `/${
                          Number(pageList[pageList.length - 1]) + 1
                        }?${queryString}`
                    );
                  } else {
                    setPage(Number(pageList[pageList.length - 1]) + 1);
                  }

                  return;
                }
              }}
            >
              <img
                src="/assets/icons/page/right_arrow_2.png"
                alt="right_arrow"
                style={{
                  width: 32,
                  height: 32,
                  filter:
                    totalPages > currentPage + rangeSize ||
                    totalPages > Number(pageList[pageList.length - 1])
                      ? "invert(13%) sepia(7%) saturate(82%) hue-rotate(314deg) brightness(94%) contrast(81%)"
                      : "invert(75%) sepia(0%) saturate(1%) hue-rotate(33deg) brightness(93%) contrast(88%)",
                }}
              />
            </ArrowBox>
          </ArrowContainer>
        </PaginationWrapper>
      ) : null}
    </>
  );
}

export default Pagination;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ArrowBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;
`;

const PageWrapper = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PageText = styled(PretendardText)`
  color: #939393;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
`;
