import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../../atoms/text/pretendardText";
import GrayBtn from "../../atoms/button/grayBtn";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import MemberApi from "../../../api/memberApi";

function InfluencerSPItem({ data }) {
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [isFollow, setIsFollow] = useState(data.isFollowing);
  const [followerCount, setFollowerCount] = useState(data.followerCount);

  const followTriggerFunc = async () => {
    try {
      await MemberApi.followTrigger(data.id);
      setIsFollow(!isFollow);

      if (isFollow) {
        setFollowerCount(followerCount - 1);
      } else {
        setFollowerCount(followerCount + 1);
        toast("Đã theo dõi");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: isTablet ? "center" : "flex-start",
          flex: 1,
          width: "100%",
          height: isMobile ? 330 : "362px",
          position: "relative",
          borderRadius: 12,
          padding: 20,
          background: "#fff",
        }}
      >
        <img
          style={{
            width: isMobile ? 175 : 205,
            height: isMobile ? 175 : 205,
            borderRadius: data.imageServerFileName ? 12 : 999,
          }}
          src={
            data.imageServerFileName
              ? `${process.env.REACT_APP_MEDIA_URL}${data.imageServerFileName}`
              : "/assets/icons/empty_person.png"
          }
          alt=""
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 14,
            marginBottom: 8,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 16 : 18,
              lineHeight: isMobile ? "23.2px" : "26.1px",
              fontWeight: 600,
              color: "#313131",
            }}
          >
            {data.nickname}
          </PretendardText>
          {sessionStorage.getItem("id") == data.id ? null : (
            <div
              onClick={() => {
                followTriggerFunc();
              }}
              style={{
                cursor: "pointer",
                background: isFollow ? "#FF5A5A" : "#FFECF3",
                padding: "4px 10px",
                borderRadius: 6,
                marginLeft: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 10 : 12,
                  fontWeight: 600,
                  color: isFollow ? "#FFFFFF" : "#FF5190",
                }}
              >
                {isFollow ? "UNFOLLOW" : "FOLLOW"}
              </PretendardText>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 14,
          }}
        >
          <img
            style={{
              width: 14,
              height: 14,
              marginRight: 4,
            }}
            src="/assets/icons/user.png"
            alt="user"
          />
          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 16,
              lineHeight: isMobile ? "16.6px" : "19.2px",
              fontWeight: 500,
              color: "#ABABAB",
            }}
          >
            Followers
          </PretendardText>
          <div
            style={{
              margin: "0px 8px",
              height: 12,
              width: 1,
              background: "#DCDCDC",
            }}
          />
          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 16,
              lineHeight: isMobile ? "16.6px" : "19.2px",
              fontWeight: 500,
              color: "#626262",
            }}
          >
            {Number(followerCount).toLocaleString()} người
          </PretendardText>
        </div>

        <GrayBtn
          onClick={() => {
            navigate(`${route.influencer_list_detail}/${data.id}/1`);
          }}
          style={{
            height: 36,
          }}
        >
          <PretendardText
            style={{
              fontSize: 14,
              lineHeight: "19.6px",
              color: "#000000",
              fontWeight: 600,
            }}
          >
            Xem chi tiết
          </PretendardText>
        </GrayBtn>
      </div>
    </>
  );
}

export default InfluencerSPItem;
