import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import MainBorderBtn from "../../atoms/button/mainBorderBtn";
import { useEffect, useState } from "react";
import Pagination from "../pagination";
import ReviewItem from "../../molecules/review/reviewItem";
import SelectM from "../../atoms/select/selectM";
import ReviewApi from "../../../api/reviewApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import Empty from "../empty";

function ReviewList({ merchId }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    {
      value: "Gần nhất",
      label: "Gần nhất",
    },
    {
      value: "thứ tự cao điểm",
      label: "thứ tự cao điểm",
    },
    {
      value: "thứ tự sao thấp",
      label: "thứ tự sao thấp",
    },
  ]);

  const [page, setPage] = useState(1);
  const [reviewList, setReviewList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    reviewListFunc();
  }, [page]);

  const reviewListFunc = async () => {
    try {
      const params = {
        page: page,
        size: pagination.size,
        orderType:
          filterValue === "thứ tự sao thấp"
            ? 3
            : filterValue === "thứ tự cao điểm"
            ? 2
            : 1,
      };

      const response = (await ReviewApi.getList(merchId, params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setReviewList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ReviewListContain
        style={{
          borderBottom:
            pagination.totalCount > 5 ? "1px solid #dcdcdc" : "none",
        }}
      >
        {isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: 24,
            }}
          >
            <SelectM
              style={{
                zIndex: 80,
              }}
              setValue={(value) => {
                setFilterValue(value);
              }}
              value={filterValue}
              dataList={filterList}
              placeholder={"Lựa chọn"}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            {filterList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CategoryText
                    onClick={() => {
                      setFilterValue(item.value);
                    }}
                    $isActive={filterValue === item.value}
                  >
                    {item.label}
                  </CategoryText>
                  {filterList.length === index + 1 ? null : (
                    <div
                      style={{
                        height: 12,
                        width: 1,
                        background: "#C4C4C4",
                        margin: "0px 8px",
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}

        {reviewList.length > 0 ? (
          reviewList.map((item, index) => {
            return <ReviewItem key={index} data={item} />;
          })
        ) : (
          <Empty title={"Vẫn chưa có review."} />
        )}

        {reviewList.length > 0 && pagination.totalCount > 5 ? (
          <div
            style={{
              marginTop: 40,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              setPage={setPage}
              queryString={``}
              currentPage={Number(page)}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        ) : null}
      </ReviewListContain>
    </>
  );
}

export default ReviewList;

const ReviewListContain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 40px;
`;

const CategoryText = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#ff649c" : "rgb(98, 98, 98)")};
  cursor: pointer;

  &:hover {
    color: #ff649c;
  }
`;
