import React, { useEffect, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import MainBtn from "../../components/atoms/button/mainBtn";
import GrayBorderBtn from "../../components/atoms/button/grayBorderBtn";

import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";
import Pagination from "../../components/organisms/pagination";

import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import ConfirmModal from "../../components/templates/modals/confirmModal";
import ProductBasketItem from "../../components/templates/product/productBasketItem";

import { route } from "../../router/route";
import ProductBasketItemM from "../../components/templates/product/productBasketItemM";
import CartApi from "../../api/cartApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Empty from "../../components/organisms/empty";

const ShopBasket = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [basketProductList, setBasketProductList] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [dIDList, setDIDList] = useState([]);
  const [deletedIDList, setDeletedIDList] = useState([]);

  const [totalProductCost, setTotalProductCost] = useState(0);
  const [totalDeliveryCost, setTotalDeliveryCost] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (allCheck) {
      let temp = basketProductList.map((item) => item.id);
      setDIDList(temp);
    }
  }, [allCheck]);

  useEffect(() => {
    cartListFunc();
  }, []);

  useEffect(() => {
    const filteredData = basketProductList.filter(
      (item) => !deletedIDList.includes(item.id)
    );
    setBasketProductList(filteredData);
  }, [deletedIDList]);

  useEffect(() => {
    amountCalFunc();
  }, [dIDList]);

  const cartListFunc = async () => {
    try {
      const params = {
        page: 1,
        size: 999,
      };

      const response = (await CartApi.getList(params)).data.data;

      setBasketProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const allDeleteFunc = async () => {
    try {
      for (let i = 0; i < dIDList.length; i++) {
        await CartApi.cartDel(dIDList[i]);
      }

      await cartListFunc();
      setAllCheck(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  function amountCalFunc() {
    let amount = 0;
    let deliveryCost = 0;
    let productCost = 0;

    const filteredItems = basketProductList.filter((item) =>
      dIDList.includes(item.id)
    );

    for (let i = 0; i < filteredItems.length; i++) {
      let optionInfo = JSON.parse(filteredItems[i].optionInfo);

      if (filteredItems[i].optionFlag) {
        for (let j = 0; j < optionInfo.length; j++) {
          amount += optionInfo[j].optionValue.price;
        }
        amount += filteredItems[i].merch.salePrice;
      } else {
        amount +=
          filteredItems[i].noOptionQuantity * filteredItems[i].merch.salePrice;
      }

      deliveryCost += filteredItems[i].merch.deliveryCost;
    }

    productCost = amount + deliveryCost;

    setTotalProductCost(amount);
    setTotalDeliveryCost(deliveryCost);
    setTotalAmount(productCost);
  }

  function paymentMoveFunc() {
    if (dIDList.length == 0) {
      toast("Vui lòng chọn sản phẩm.");
      return;
    }

    const filteredItems = basketProductList.filter((item) =>
      dIDList.includes(item.id)
    );

    let merchInfoTemp = filteredItems.map((item) => {
      return {
        merch: {
          id: item.merch.id,
          title: item.merch.title,
          salePrice: item.merch.salePrice,
          originalPrice: item.merch.originalPrice,
          deliveryCost: item.merch.deliveryCost,
          imageOriginFileName: item.merch.imageOriginFileName,
          imageServerFileName: item.merch.imageServerFileName,
          optionFlag: item.optionFlag, // 옵션있으면 true
          noOptionQuantity: item.noOptionQuantity, // 옵션이 없을 경우 주문수량
          weight: item.merch.weight,
          bundled: item.merch.bundled,
        },
        optionInfo: JSON.parse(item.optionInfo),
      };
    });

    sessionStorage.setItem("orderInfo", JSON.stringify(merchInfoTemp));

    navigation(route.product_payment);
  }

  return (
    <>
      <ShopBasketSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: isSmall ? "80px 20px 80px 20px" : "80px 0px 90px 0px",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
              width: "100%",
              marginBottom: isMobile ? 24 : 40,
            }}
          >
            Giỏ hàng
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: isMobile ? 24 : 30,
            }}
          >
            <RightLabelCheckbox
              checked={allCheck}
              size={20}
              onChange={() => {
                setAllCheck(!allCheck);

                if (allCheck) {
                  setDIDList([]);
                }
              }}
              textStyle={{
                fontSize: "18px",
              }}
              text={"Chọn tất cả"}
            />

            <GrayBorderBtn
              onClick={() => {
                setIsCofirmModal(true);
              }}
              style={{
                width: 92,
                height: 47,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                }}
              >
                Xóa bỏ
              </PretendardText>
            </GrayBorderBtn>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {basketProductList.length > 0 ? (
              basketProductList.map((item, index) => {
                return isSmall ? (
                  <ProductBasketItemM
                    setDIDList={setDIDList}
                    dIDList={dIDList}
                    key={index}
                    data={item}
                    style={{
                      borderBottom:
                        basketProductList.length === index + 1
                          ? "1px solid #DCDCDC"
                          : "none",
                    }}
                    basketProductList={basketProductList}
                    setAllCheck={setAllCheck}
                  />
                ) : (
                  <ProductBasketItem
                    setDIDList={setDIDList}
                    dIDList={dIDList}
                    key={index}
                    data={item}
                    style={{
                      borderBottom:
                        basketProductList.length === index + 1
                          ? "1px solid #DCDCDC"
                          : "none",
                    }}
                    basketProductList={basketProductList}
                    setAllCheck={setAllCheck}
                  />
                );
              })
            ) : (
              <Empty title={"Không có sản phẩm nào hấp dẫn cả."} />
            )}
          </div>

          {basketProductList.length > 0 ? (
            <>
              <div
                style={{
                  width: "100%",
                  background: "rgba(243, 241, 242, 0.5)",
                  padding: isMobile ? 15 : 20,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "column",
                      justifyContent: "space-between",
                      width: isMobile ? "100%" : "auto",
                      marginBottom: isMobile ? 16 : 0,
                    }}
                  >
                    <BottomBoxCardTitle $isMobile={isMobile}>
                      Tổng tiền hàng
                    </BottomBoxCardTitle>
                    <BottomBoxCardPrice $isMobile={isMobile}>
                      {Number(totalProductCost).toLocaleString()} đồng
                    </BottomBoxCardPrice>
                  </div>

                  {/* {isMobile ? null : (
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "0px 52px",
                      }}
                      src="/assets/icons/plus.png"
                      alt="plus"
                    />
                  )} */}

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "column",
                      justifyContent: "space-between",
                      width: isMobile ? "100%" : "auto",
                      marginBottom: isMobile ? 30 : 0,
                    }}
                  >
                    <BottomBoxCardTitle $isMobile={isMobile}>
                      Phí vận chuyển
                    </BottomBoxCardTitle>
                    <BottomBoxCardPrice $isMobile={isMobile}>
                      {Number(totalDeliveryCost).toLocaleString()} đồng
                    </BottomBoxCardPrice>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    justifyContent: "space-between",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  <BottomBoxCardTitle $isMobile={isMobile}>
                    Tổng thanh toán
                  </BottomBoxCardTitle>
                  <BottomBoxCardPrice
                    $isMobile={isMobile}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {Number(totalProductCost).toLocaleString()} đồng
                  </BottomBoxCardPrice>
                </div>
              </div>

              <MainBtn
                onClick={() => {
                  paymentMoveFunc();
                }}
                style={{
                  width: 115,
                  height: 50,
                  marginTop: 40,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "26.1px",
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  Đặt hàng
                </PretendardText>
              </MainBtn>
            </>
          ) : null}
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ShopBasketSection>

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={false}
          title={"Anh sẽ xóa nó khỏi giỏ hàng chứ?"}
          onClick={() => {
            setIsCofirmModal(false);
          }}
          onYClick={() => {
            setIsCofirmModal(false);
            allDeleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default ShopBasket;

const ShopBasketSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BottomBoxCardTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  color: rgb(98, 98, 98);
  font-weight: 500;
  margin-bottom: ${(props) => (props.$isMobile ? "0px" : "10px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
  width: ${(props) => (props.$isMobile ? "136px" : "auto")};
`;

const BottomBoxCardPrice = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  color: rgb(49, 49, 49);
  font-weight: 700;
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
`;
