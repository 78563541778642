import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import Pagination from "../../organisms/pagination";
import { useLocation, useParams } from "react-router-dom";
import Empty from "../../organisms/empty";
import { route } from "../../../router/route";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import MemberApi from "../../../api/memberApi";
import moment from "moment";

function CouponList({ parentCategory }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const location = useLocation();
  const { page } = useParams();

  const [useDataList, setUseDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    couponListFunc();
  }, [location]);

  const couponListFunc = async () => {
    try {
      const data = {
        page: page,
        size: pagination.size,
      };

      let response = (await MemberApi.getCouponList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setUseDataList([]);
      setUseDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
          position: "relative",
          marginTop: isMobile ? 8 : 28,
        }}
      >
        {useDataList.length > 0 ? (
          useDataList.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                  borderBottom:
                    useDataList.length === index + 1
                      ? "none"
                      : "1px solid #DCDCDC",
                  padding: "20px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      fontWeight: 600,
                      color:
                        item.couponStatus !== "POSSIBLE"
                          ? "#C4C4C4"
                          : "#313131",
                      marginBottom: 8,
                    }}
                  >
                    {item.coupon.title}
                  </PretendardText>
                  {/* <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "19.6px",
                      fontWeight: 400,
                      color: item.couponStatus !== "POSSIBLE" ? "#C4C4C4" : "#939393",
                      marginBottom: 4,
                    }}
                  >
                    {item.content}
                  </PretendardText> */}
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "19.6px",
                      fontWeight: 400,
                      color:
                        item.couponStatus !== "POSSIBLE"
                          ? "#C4C4C4"
                          : "#939393",
                      marginBottom: isMobile ? 16 : 0,
                    }}
                  >
                    {moment(item.coupon.endDate).format("YYYY.MM.DD")} tới
                  </PretendardText>
                </div>
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "33.6px",
                    fontWeight: 600,
                    color:
                      item.couponStatus !== "POSSIBLE" ? "#C4C4C4" : "#FF649C",
                  }}
                >
                  {item.coupon.couponType === "DELIVERY"
                    ? ""
                    : Number(item.coupon.amount).toLocaleString()}
                  {item.coupon.couponType === "RATIO" ? "%" : ""}
                </PretendardText>
              </div>
            );
          })
        ) : (
          <Empty title={"Vẫn chưa có phiếu giảm giá."} />
        )}

        {useDataList.length > 0 && pagination.totalCount > 10 ? (
          <div
            style={{
              marginTop: 48,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              route={route.mypage_point_manage}
              queryString={`category=${parentCategory}`}
              currentPage={Number(page)}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default CouponList;
