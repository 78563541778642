import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import useDisplay from "../../../hooks/useDisplay";

function ConfirmModal({
  visiable,
  setVisiable,
  title,
  content,
  isScroll,
  nText,
  yText,
  onClick,
  onYClick,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  return (
    <ConfirmModalWrapper $isMobile={isMobile}>
      <ConfirmModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PretendardText
            style={{
              color: "#222222",
              fontSize: "20px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {title ? title : ""}
          </PretendardText>
          {content ? (
            <PretendardText
              style={{
                color: "#222222",
                fontSize: "16px",
                fontWeight: 400,
                textAlign: "center",
                marginTop: 16,
              }}
            >
              {content ? content : ""}
            </PretendardText>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              marginTop: 36,
            }}
          >
            <MainBtn
              onClick={onClick}
              style={{
                background: "#DCDCDC",
                border: "1px solid #DCDCDC",
                height: 48,
                marginRight: 20,
              }}
            >
              <PretendardText
                style={{
                  color: "#ABABAB",
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {nText ? nText : "sự huỷ bỏ"}
              </PretendardText>
            </MainBtn>
            <MainBtn
              onClick={onYClick}
              style={{
                height: 48,
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {yText ? yText : "sự xác nhận"}
              </PretendardText>
            </MainBtn>
          </div>
        </div>
      </ConfirmModalContainer>
    </ConfirmModalWrapper>
  );
}

export default ConfirmModal;

const ConfirmModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
`;

const ConfirmModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "560px")};
  padding: ${(props) => (props.$isMobile ? "28px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;
