import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import useDisplay from "../../hooks/useDisplay";
import UseDetails from "../../components/templates/pointManage/useDetails";
import CouponList from "../../components/templates/pointManage/couponList";
import PointRefund from "../../components/templates/pointManage/pointRefund";

import { route } from "../../router/route";
import MypageMenuM from "../../components/templates/mypageMenuM";
import Pagination from "../../components/organisms/pagination";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";

const PointManagePage = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [categoryValue, setCategoryValue] = useState("Lịch sử sử dụng điểm");
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    let role = sessionStorage.getItem("role");

    if (role === "INFLUENCER") {
      setCategoryList([
        { title: "Lịch sử sử dụng điểm" },
        { title: "Lịch sử Mã giảm giá" },
        { title: "Lịch sử đổi điểm" },
      ]);
    } else {
      setCategoryList([
        { title: "Lịch sử sử dụng điểm" },
        { title: "Lịch sử Mã giảm giá" },
      ]);
    }
  }, []);

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category")
        ? searchParams.get("category")
        : "Lịch sử sử dụng điểm"
    );
  }, [location]);

  return (
    <>
      <PointManagePageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : "32px 24px",
                background: "#fff",
                borderRadius: 8,
              }}
            >
              <MenuOverflowXHidden $isMobile={isMobile}>
                {categoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        padding: isMobile ? 12 : "15px 25px",
                        borderBottom:
                          categoryValue === item.title
                            ? "2px solid #FF649C"
                            : "none",
                      }}
                      onClick={() => {
                        navigate(
                          `${route.mypage_point_manage}/1?category=${item.title}`
                        );
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 18,
                          lineHeight: "26.1px",
                          fontWeight: categoryValue === item.title ? 600 : 500,
                          color:
                            categoryValue === item.title
                              ? "#313131"
                              : "#939393",
                          width: "max-content",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    </div>
                  );
                })}
              </MenuOverflowXHidden>

              {categoryValue === "Lịch sử sử dụng điểm" ? (
                <UseDetails parentCategory={categoryValue} />
              ) : null}
              {categoryValue === "Lịch sử Mã giảm giá" ? (
                <CouponList parentCategory={categoryValue} />
              ) : null}
              {categoryValue === "Lịch sử đổi điểm" ? (
                <PointRefund parentCategory={categoryValue} />
              ) : null}
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </PointManagePageSection>
    </>
  );
};

export default PointManagePage;

const PointManagePageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
