import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import Checkbox from "../../atoms/checkbox/checkbox";
import GrayBorderBtn from "../../atoms/button/grayBorderBtn";

function ProductWishItemM({
  data,
  style,
  wishTriggerFunc,
  setDIDList,
  dIDList,
  setAllCheck,
  wishProdcutList,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          padding: 20,
          borderTop: "1px solid #DCDCDC",
          flex: 1,
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Checkbox
            checked={dIDList.some((item) => item === data.merch.id)}
            size={24}
            onChange={() => {
              let temp;
              if (dIDList.some((item) => item === data.merch.id)) {
                temp = dIDList.filter((item) => item !== data.merch.id);
                setDIDList(temp);
              } else {
                temp = [...dIDList, data.merch.id];
                setDIDList(temp);
              }

              const idList = wishProdcutList.map((item) => item.merch.id);
              const allValuesExist =
                temp.length > 0 &&
                idList.every((value) => temp.includes(value));

              if (allValuesExist) {
                setAllCheck(true);
              } else {
                setAllCheck(false);
              }
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: isMobile ? "flex-start" : "center",
              marginLeft: isMobile ? 10 : 15,
            }}
          >
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}${data.merch.imageServerFileName}`}
              className="sample04"
              style={{
                width: isMobile ? 65 : 120,
                height: isMobile ? 65 : 120,
                borderRadius: 8,
                marginRight: isMobile ? 10 : 15,
              }}
            />
            <div
              style={{
                width: "100%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 16,
                  lineHeight: isMobile ? "19.6px" : "23.2px",
                  color: "#222222",
                  fontWeight: 600,
                  marginBottom: isMobile ? 5 : 4,
                }}
              >
                {data.merch.title}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 12 : 16,
                  lineHeight: isMobile ? "16.8px" : "23.2px",
                  color: "#939393",
                  fontWeight: 600,
                  marginBottom: isMobile ? 5 : 4,
                }}
              >
                {data.merch.brand}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 16,
                  lineHeight: isMobile ? "19.6px" : "23.2px",
                  color: "#ABABAB",
                  fontWeight: 400,
                  marginBottom: isMobile ? 5 : 2,
                  textDecoration: "line-through",
                }}
              >
                {Number(data.merch.originalPrice).toLocaleString()}
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    lineHeight: isMobile ? "19.6px" : "26.1px",
                    color: "#313131",
                    fontWeight: 700,
                    marginRight: isMobile ? 5 : 6,
                  }}
                >
                  {Number(data.merch.salePrice).toLocaleString()}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    lineHeight: isMobile ? "19.6px" : "26.1px",
                    color: "#FF649C",
                    fontWeight: 700,
                  }}
                >
                  {calculateDiscountRate(
                    Number(data.merch.originalPrice),
                    Number(data.merch.salePrice)
                  )}
                  %
                </PretendardText>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <GrayBorderBtn
            onClick={() => {
              navigation(`${route.product_detail}/${data.merch.id}`);
            }}
            style={{
              width: 133,
              height: 44,
              marginRight: 10,
            }}
          >
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Mua hàng
            </PretendardText>
          </GrayBorderBtn>
          <GrayBorderBtn
            onClick={() => {
              wishTriggerFunc(data.merch.id);
            }}
            style={{
              width: 133,
              height: 44,
            }}
          >
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Xóa bỏ
            </PretendardText>
          </GrayBorderBtn>
        </div>
      </div>
    </>
  );
}

export default ProductWishItemM;
