import { useState } from "react";
import moment from "moment";

import PretendardText from "../../atoms/text/pretendardText";

function CustomerCenterItem({ style, data }) {
  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #DCDCDC",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 20px",
            height: 80,
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              color: "#313131",
              fontWeight: 500,
              flex: 1,
            }}
          >
            {data.title}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: 16,
              color: "#939393",
              fontWeight: 400,
              marginRight: 20,
            }}
          >
            {moment(data.createdAt).format("YYYY-MM-DD")}
          </PretendardText>
          <img
            onClick={() => {
              setIsDetail(!isDetail);
            }}
            style={{
              width: 22,
              height: 22,
              cursor: "pointer",
            }}
            src={
              isDetail
                ? "/assets/icons/btn-arrow-up-40.png"
                : "/assets/icons/btn-arrow-down-40.png"
            }
            alt="btn-arrow-up-40"
          />
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              background: "#F9F9F9",
            }}
          >
            <div
              style={{
                padding: "20px",
                borderTop: "1px solid #DCDCDC",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {data.content}
              </PretendardText>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default CustomerCenterItem;
