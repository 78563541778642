import { atom } from "recoil";

export const headerTriggerState = atom({
  key: "headerTriggerState",
  default: false,
});

export const headerMenuState = atom({
  key: "headerMenuState",
  default: [],
});

export const isLoginTrigger = atom({
  key: "isLoginTrigger",
  default: false,
});
