import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";

import PretendardText from "../components/atoms/text/pretendardText";
import PCContainer from "../components/atoms/layout/pcContainer";
import Pagination from "../components/organisms/pagination";
import FooterBtnGroup from "../components/templates/footerBtnGroup";

import { route } from "../router/route";
import useDisplay from "../hooks/useDisplay";
import InputSearch from "../components/atoms/input/inputSearch";
import CustomerCenterItem from "../components/molecules/customerCenter/customerCenterItem";
import CustomerCenterItemM from "../components/molecules/customerCenter/customerCenterItemM";
import Empty from "../components/organisms/empty";
import { toast } from "react-toastify";
import { errorHandler } from "../util/errorHandler";
import CustomerCenterApi from "../api/customerCenterApi";

const CustomerCenter = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [keyword, setKeyword] = useState("");

  const [categoryValue, setCategoryValue] = useState("Thông báo");
  const [subCategoryList, setSubCategoryList] = useState([
    { title: "Thông báo" },
    { title: "Câu hỏi thường gặp" },
  ]);

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category") ? searchParams.get("category") : "Thông báo"
    );
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");

    customerCenterListFunc();
  }, [location]);

  const customerCenterListFunc = async () => {
    try {
      setDataList([]);

      const data = {
        page: page,
        size: pagination.size,
        keyword: searchParams.get("keyword"),
      };

      let response;

      if (searchParams.get("category") === "Thông báo") {
        response = (await CustomerCenterApi.getNoticeList(data)).data.data;
      } else {
        response = (await CustomerCenterApi.getFAQList(data)).data.data;
      }

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <CustomerCenterSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: "100%",
            marginBottom: isMobile ? 28 : 60,
            padding: isSmall ? "0px 20px" : "0px",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 32,
              lineHeight: isMobile ? "28px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
            }}
          >
            Hỗ trợ khách hàng
          </PretendardText>
        </PCContainer>

        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: isSmall ? "flex-end" : "center",
              marginBottom: isMobile ? 20 : 40,
              padding: isSmall ? "0px 20px" : "0px",
            }}
          >
            <OverflowXHidden>
              {subCategoryList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      padding: isMobile ? "12px" : "15px 25px",
                      borderBottom:
                        categoryValue === item.title
                          ? "2px solid #FF649C"
                          : "none",
                    }}
                    onClick={() => {
                      navigate(
                        `${route.customer_center}/1?category=${item.title}`
                      );
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 18,
                        lineHeight: "26.1px",
                        fontWeight: categoryValue === item.title ? 600 : 500,
                        color:
                          categoryValue === item.title ? "#313131" : "#939393",
                      }}
                    >
                      {item.title}
                    </PretendardText>
                  </div>
                );
              })}
            </OverflowXHidden>

            <div
              style={{
                width: isMobile ? "100%" : 480,
                marginTop: isSmall ? 20 : 0,
              }}
            >
              <InputSearch
                placeholder={"Vui lòng tìm kiếm tiêu đề."}
                value={keyword}
                onChange={(value) => {
                  setKeyword(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.customer_center}/1?category=${categoryValue}&keyword=${keyword}`
                    );
                  }
                }}
                searchStyle={{
                  top: 9,
                }}
                setDataToKeyword={() => {
                  navigate(
                    `${route.customer_center}/1?category=${categoryValue}&keyword=${keyword}`
                  );
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: isTablet ? "0px 20px" : "0px",
            }}
          >
            {dataList.length > 0 ? (
              dataList.map((item, index) => {
                return isMobile ? (
                  <CustomerCenterItemM key={index} data={item} />
                ) : (
                  <CustomerCenterItem
                    key={index}
                    data={item}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "1px solid #DCDCDC"
                          : "none",
                    }}
                  />
                );
              })
            ) : (
              <Empty title={"Vẫn chưa có bình luận."} />
            )}
          </div>

          {dataList.length > 0 && pagination.totalCount > 5 ? (
            <div
              style={{
                marginTop: 44,
              }}
            >
              <Pagination
                route={route.customer_center}
                queryString={`category=${categoryValue}&keyword=${keyword}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </CustomerCenterSection>
    </>
  );
};

export default CustomerCenter;

const CustomerCenterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${(props) =>
    props.$isSmall ? "40px 0px 80px 0px" : "80px 0px 80px 0px"};
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
