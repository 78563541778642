import React, { useRef } from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../text/pretendardText";

function ProfileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png"
        ref={imageInput}
        onChange={props.handleChange}
        multiple={props.multiple}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          width: 64,
          height: 64,
          marginBottom: 8,
          position: "relative",
          borderRadius: 100,
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 99,
          }}
          src={props.profile ? props.profile : "/assets/icons/empty_person.png"}
          alt="empty_person"
        />
        <img
          onClick={() => {}}
          style={{
            width: "24px",
            height: "24px",
            borderRadius: 99,
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          src="/assets/icons/edit.png"
          alt="edit"
        />
      </div>
    </>
  );
}

export default ProfileForm;
