import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useDisplay from "../../../hooks/useDisplay";

function ProductSwiper({ dataList, setActiveIndex, clickedIndex }) {
  const swiperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    if (swiperRef) {
      swiperRef.current.slideTo(clickedIndex, 1000);
    }
  }, [clickedIndex]);

  const getVideoEmbedUrl = (link) => {
    if (link) {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = link.match(regex);

      const videoId = match ? match[1] : "";
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return "";
    }

    // 추후 틱톡
    // return `https://www.tiktok.com/embed/${url.split('/')[5]}`;
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          marginBottom: 20,
          width: isMobile ? "100%" : "640px",
          height: isMobile ? 455 : "869px",
          borderRadius: 8,
        }}
      >
        <Swiper
          modules={[Autoplay, Navigation]}
          onSwiper={(it) => {
            swiperRef.current = it;
          }}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={0}
          loop={true}
          // autoplay={{
          //   delay: 5000,
          //   disableOnInteraction: false,
          // }}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
        >
          {dataList?.length > 0
            ? dataList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        width: isMobile ? "100%" : "640px",
                        height: isMobile ? 455 : "869px",
                        position: "relative",
                        borderRadius: 8,
                      }}
                    >
                      {item.title ? (
                        item.link ? (
                          <div
                            style={{
                              width: isMobile ? "100%" : "640px",
                              height: isMobile ? 455 : "869px",
                              borderRadius: 8,
                            }}
                          >
                            <iframe
                              style={{
                                borderRadius: 8,
                              }}
                              width="100%"
                              height={"100%"}
                              src={`${getVideoEmbedUrl(
                                item.link
                              )}?autoplay=1&mute=1`}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              title="YouTube video player"
                            />
                          </div>
                        ) : (
                          <video
                            muted
                            autoPlay
                            style={{
                              width: isMobile ? "100%" : "640px",
                              height: isMobile ? 455 : "869px",
                              objectFit: "cover",
                              borderRadius: 8,
                            }}
                          >
                            <source
                              src={`${process.env.REACT_APP_MEDIA_URL}${item.videoServerFileName}`}
                              type="video/mp4"
                            />
                          </video>
                        )
                      ) : (
                        <img
                          style={{
                            width: isMobile ? "100%" : "640px",
                            height: isMobile ? 455 : "869px",
                            borderRadius: 8,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="product_main"
                        />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>

        <img
          onClick={() => swiperRef.current?.slidePrev()}
          style={{
            position: "absolute",
            cursor: "pointer",
            left: isMobile ? 40 : 60,
            width: isMobile ? 40 : 60,
            height: isMobile ? 40 : 60,
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 97,
          }}
          src="/assets/icons/btn-left-arrow-40.png"
          alt="btn-left-arrow-40"
        />
        <img
          onClick={() => swiperRef.current?.slideNext()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: 0,
            width: isMobile ? 40 : 60,
            height: isMobile ? 40 : 60,
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 97,
          }}
          src="/assets/icons/btn-right-arrow-40.png"
          alt="btn-right-arrow-40"
        />
      </div>
    </>
  );
}

export default ProductSwiper;
