import { useMediaQuery } from "react-responsive";
import { responsiveSize } from "../constant/responsiveSize";

const useDisplay = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width:${responsiveSize.DESKTOP_MIN_WIDTH}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width:${responsiveSize.TABLET_MIN_WIDTH}px) and
    (max-width:${responsiveSize.DESKTOP_MIN_WIDTH - 1}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width:${responsiveSize.MODILE_MAX_WIDTH}px)`,
  });

  //특수한 경우
  const isMiddleNav = useMediaQuery({
    query: `(max-width:${responsiveSize.MIDDLE_NAV_WIDTH}px)`,
  });

  const isCard = useMediaQuery({
    query: `(max-width: 1060px)`,
  });

  const certification3 = useMediaQuery({
    query: `(max-width: 1399px)`,
  });

  const certification2 = useMediaQuery({
    query: `(max-width: 1159px)`,
  });

  const certification1 = useMediaQuery({
    query: `(max-width: 809px)`,
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isMiddleNav,
    isCard,
    certification3,
    certification2,
    certification1,
  };
};

export default useDisplay;
