import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../../atoms/text/pretendardText";

const Select = ({
  dataList,
  value,
  setValue,
  placeholder,
  style,
  setAllValue,
  disabled,
}) => {
  const { isMobile } = useDisplay();
  const [itemClicked, setItemClicked] = useState(false);

  return (
    <>
      <SelectWrapper
        $disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }

          setItemClicked(!itemClicked);
        }}
        style={{
          border: itemClicked ? "2px solid #FF649C" : "2px solid #e8e8e8",
          ...style,
        }}
      >
        <SelectText isselect={value}>{value ? value : placeholder}</SelectText>
        <div style={{}}>
          {itemClicked ? (
            <img
              style={{
                width: 24,
                height: 24,
                marginLeft: 19,
              }}
              src="/assets/icons/ic_arrow-up-20.png"
              alt="ic_arrow-up-20"
            />
          ) : (
            <img
              style={{
                width: 24,
                height: 24,
                marginLeft: 19,
              }}
              src="/assets/icons/down_arrow.png"
              alt="down_arrow"
            />
          )}
        </div>
        {itemClicked ? (
          <SelectItemContain>
            {dataList.map((item, index) => {
              return (
                <SelectSpanText
                  key={index}
                  onClick={() => {
                    setValue(item.value);

                    if (setAllValue) {
                      setAllValue(item);
                    }
                  }}
                >
                  <SelectValueText>{item.label}</SelectValueText>
                </SelectSpanText>
              );
            })}
          </SelectItemContain>
        ) : null}
      </SelectWrapper>
    </>
  );
};

export default Select;

const SelectWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 55px;
  background: ${(props) =>
    props.$disabled ? "#f5f5f5" : "rgb(255, 255, 255)"};

  border-radius: 8px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 90;
`;

const SelectText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;
  color: ${(props) => (props.isselect ? "#313131" : "#C4C4C4")};

  white-space: nowrap;
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;

  color: #313131;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;

const SelectItemContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
