import React, { useEffect, useState } from "react";
import useDisplay from "../hooks/useDisplay";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../components/atoms/text/pretendardText";
import PCContainer from "../components/atoms/layout/pcContainer";
import BannerSwiper from "../components/templates/home/bannerSwiper";
import ProductItem from "../components/templates/product/productItem";
import PickSwiper from "../components/templates/home/pickSwiper";
import FooterBtnGroup from "../components/templates/footerBtnGroup";

import BannerApi from "../api/bannerApi";
import { errorHandler } from "../util/errorHandler";
import MainInfoApi from "../api/mainInfoApi";
import CategoryApi from "../api/categoryApi";
import { route } from "../router/route";
import MerchApi from "../api/merchApi";
import Empty from "../components/organisms/empty";
import MemberApi from "../api/memberApi";
import PopupModal from "../components/templates/modals/popupModal";
import PopupApi from "../api/popupApi";

const Home = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [bannerList, setBannerList] = useState([]);
  const [hotProductList, setHotProductList] = useState([]);
  const [newProductList, setNewProductList] = useState([]);
  const [pickList, setPickList] = useState([]);
  const [mdProductList, setMDProductList] = useState([]);
  const [mainInfoData, setMainInfoData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [popupList, setPopupList] = useState([]);
  const [popupDelList, setPopupDelList] = useState([]);

  useEffect(() => {
    bannerListFunc();
    mainInfoListFunc();
    categoryListFunc();

    hotMerchListFunc();
    newMerchListFunc();
    mdMerchListFunc();
    kovietPickIFListFunc();

    getPopupList();
  }, []);

  const bannerListFunc = async () => {
    try {
      const response = await BannerApi.getMainList();

      setBannerList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const mainInfoListFunc = async () => {
    try {
      const response = await MainInfoApi.getList();
      setMainInfoData(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const categoryListFunc = async () => {
    try {
      const response = await CategoryApi.getList();
      setCategoryData(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const hotMerchListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 4,
        orderType: 1,
        promotionId: 0, // 기획전이 있는 상품만 표시
      };

      const response = await MerchApi.getList(data);

      setHotProductList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const newMerchListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 4,
        newFlag: true,
        orderType: 1,
      };

      const response = await MerchApi.getList(data);

      setNewProductList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const mdMerchListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 4,
        mdFlag: true,
        orderType: 1,
      };

      const response = await MerchApi.getList(data);

      setMDProductList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const kovietPickIFListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
        approval: "APPROVE",
        role: "INFLUENCER",
        isPick: true,
      };

      const response = await MemberApi.memberList(data);

      setPickList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const getPopupList = async () => {
    try {
      const popupDate = localStorage.getItem("popup_date");
      let date = toStringByFormatting(new Date());
      let popupDelList;

      if (popupDate !== date) {
        localStorage.removeItem("popup_list");
      } else {
        popupDelList = JSON.parse(localStorage.getItem("popup_list"));
      }

      if (popupDelList) {
        let temp = popupDelList;
        const response = await PopupApi.getActivePopup();

        let data;
        if (response.data.data.content.length > 0) {
          data = response.data.data.content.map((item) => {
            if (temp.some((item02) => item02 == item.id)) {
              return null;
            } else {
              return item;
            }
          });
        } else {
          data = response.data.data.content;
        }

        setPopupList(data);
      } else {
        const response = await PopupApi.getActivePopup();
        setPopupList([...response.data.data.content]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function toStringByFormatting(source, delimiter = "-") {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return year + "/" + month + "/" + day;
  }

  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  const popupCloseFunc = (id) => {
    try {
      let date = toStringByFormatting(new Date());
      let popupId = localStorage.getItem("popup_list");

      if (popupId) {
        let popupIdTemp = JSON.parse(popupId);
        popupIdTemp.push(id);
        localStorage.setItem("popup_list", JSON.stringify(popupIdTemp));
      } else {
        let popupIdTemp = [id];
        localStorage.setItem("popup_list", JSON.stringify(popupIdTemp));
      }

      localStorage.setItem("popup_date", date);
    } catch (err) {}
  };

  return (
    <>
      <HomeSection $isMobile={isMobile}>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isSmall ? "0px 20px 80px 20px" : "0px 0px 80px 0px",
            width: isSmall ? "100%" : "auto",
          }}
        >
          {bannerList.length > 0 ? (
            <BannerSwiper bannerList={bannerList} />
          ) : null}

          <OverflowXHidden $isMobile={isMobile}>
            {categoryData.length > 0 &&
              categoryData.map((item, index) => {
                return (
                  <HomeIconMenuContain
                    key={index}
                    style={{
                      marginRight:
                        categoryData.length === index + 1
                          ? 0
                          : isMobile
                          ? 16
                          : 40,
                    }}
                    $isMobile={isMobile}
                    onClick={() => {
                      navigation(
                        `${route.category_product}/1?categoryValue=${item.id}`
                      );
                    }}
                  >
                    <img
                      style={{
                        width: isMobile ? 54 : 72,
                        height: isMobile ? 54 : 72,
                        borderRadius: isMobile ? 12 : 24,
                      }}
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileNameInActive}`}
                      alt="ic-skin-72"
                    />
                    <HomeIconMenuText $isMobile={isMobile}>
                      {item.title}
                    </HomeIconMenuText>
                  </HomeIconMenuContain>
                );
              })}
          </OverflowXHidden>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: isSmall ? "100%" : 1180,
            }}
          >
            {mainInfoData.length > 0 &&
            mainInfoData.find((item) => item.mainInfoType === "SPECIAL")
              .activation ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: isMobile ? 40 : 34,
                    marginBottom: 24,
                  }}
                >
                  <img
                    style={{
                      width: 28,
                      height: 28,
                      marginRight: 10,
                    }}
                    src="/assets/icons/ic-promotion-24.png"
                    alt="ic-promotion-24"
                  />
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 18 : 24,
                      lineHeight: isMobile ? "26.1px" : "33.6px",
                      fontWeight: 600,
                      color: "#313131",
                    }}
                  >
                    HOT! Sản phẩm yêu thích
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: isMobile ? "24px 15px" : 20,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: isSmall ? "space-between" : "flex-start",
                    width: "100%",
                  }}
                >
                  {hotProductList.length > 0 ? (
                    hotProductList.map((item, index) => {
                      return <ProductItem key={index} data={item} />;
                    })
                  ) : (
                    <Empty title={"Vẫn chưa có phần thưởng."} />
                  )}
                </div>
              </>
            ) : null}

            {mainInfoData.length > 0 &&
            mainInfoData.find((item) => item.mainInfoType === "NEW")
              .activation ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: isMobile ? 60 : 80,
                    marginBottom: 24,
                  }}
                >
                  <img
                    style={{
                      width: 28,
                      height: 28,
                      marginRight: 10,
                    }}
                    src="/assets/icons/ic-new-24.png"
                    alt="ic-new-24"
                  />
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 18 : 24,
                      lineHeight: isMobile ? "26.1px" : "33.6px",
                      fontWeight: 600,
                      color: "#313131",
                    }}
                  >
                    NEW! Sản phẩm mới
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: isMobile ? "24px 15px" : 20,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: isSmall ? "space-between" : "flex-start",
                  }}
                >
                  {newProductList.length > 0 ? (
                    newProductList.map((item, index) => {
                      return <ProductItem key={index} data={item} />;
                    })
                  ) : (
                    <Empty title={"Vẫn chưa có phần thưởng."} />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </PCContainer>
        {mainInfoData.length > 0 &&
        mainInfoData.find((item) => item.mainInfoType === "INFLUENCER")
          .activation ? (
          <PickWrapper>
            <PCContainer
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                width: isSmall ? "100%" : "auto",
                padding: isSmall ? "60px 20px 60px 20px" : "80px 0px 80px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 24,
                }}
              >
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 10,
                  }}
                  src="/assets/icons/ic-influencer-24.png"
                  alt="ic-influencer-24"
                />
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: isMobile ? "26.1px" : "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  KOVIET PICK!
                </PretendardText>
              </div>

              {pickList.length > 0 ? <PickSwiper dataList={pickList} /> : null}
            </PCContainer>
          </PickWrapper>
        ) : null}

        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "60px 20px 0px 20px" : "80px 0px 0px 0px",
          }}
        >
          {mainInfoData.length > 0 &&
          mainInfoData.find((item) => item.mainInfoType === "EVENT")
            .activation ? (
            <div
              style={{
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                width: "100%",
              }}
            >
              <img
                onClick={() => {
                  navigation(`${route.event_product}/1`);
                }}
                style={{
                  cursor: "pointer",
                  width: isSmall ? "100%" : 580,
                  height: isSmall ? "auto" : 222,
                  marginRight: isSmall ? 0 : 20,
                  marginBottom: isSmall ? 20 : 0,
                  objectFit: "contain",
                }}
                src={
                  isSmall
                    ? "/assets/imgs/coupon02_m.png"
                    : "/assets/imgs/coupon02.png"
                }
                alt="coupon02"
              />
              <img
                onClick={() => {
                  navigation(`${route.best_product}/1`);
                }}
                style={{
                  cursor: "pointer",
                  width: isSmall ? "100%" : 580,
                  height: isSmall ? "auto" : 222,
                  objectFit: "contain",
                }}
                src={
                  isSmall
                    ? "/assets/imgs/coupon01_m.png"
                    : "/assets/imgs/coupon01.png"
                }
                alt="coupon01"
              />
            </div>
          ) : null}

          {mainInfoData.length > 0 &&
          mainInfoData.find((item) => item.mainInfoType === "MD").activation ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: isMobile ? 60 : 80,
                  marginBottom: 24,
                }}
              >
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 10,
                  }}
                  src="/assets/icons/ic-md-24.png"
                  alt="ic-md-24"
                />
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: isMobile ? "26.1px" : "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  MD’s PICK! Sản phẩm được giới thiệu
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: isMobile ? "24px 15px" : 20,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: isSmall ? "space-between" : "flex-start",
                  width: "100%",
                }}
              >
                {mdProductList.length > 0 ? (
                  mdProductList.map((item, index) => {
                    return <ProductItem key={index} data={item} />;
                  })
                ) : (
                  <Empty title={"Vẫn chưa có phần thưởng."} />
                )}
              </div>
            </>
          ) : null}

          {mainInfoData.length > 0 &&
          mainInfoData.find((item) => item.mainInfoType === "NOTICE")
            .activation ? (
            <div
              style={{
                marginTop: isMobile ? 60 : 80,
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: isSmall ? "100%" : 580,
                  height: isMobile ? 92 : 160,
                  background: "#FFECF3",
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: isSmall ? 0 : 20,
                  padding: isMobile ? "0px 20px" : "0px 30px",
                  marginBottom: isSmall ? 20 : 0,
                }}
              >
                <div>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 10 : 16,
                      lineHeight: isMobile ? "13px" : "23.2px",
                      color: "#E27E98",
                      fontWeight: 600,
                      marginBottom: isMobile ? "4.6px" : 8,
                    }}
                  >
                    NOTICE
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 16 : 26,
                      lineHeight: isMobile ? "23.2px" : "36.4px",
                      color: "#313131",
                      fontWeight: isMobile ? 600 : 700,
                      marginBottom: isMobile ? "4.6px" : 8,
                    }}
                  >
                    Nội dung thông báo
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 10 : 16,
                      lineHeight: isMobile ? "13px" : "23.2px",
                      color: "#626262",
                      fontWeight: isMobile ? 500 : 400,
                    }}
                  >
                    Hãy kiểm tra thông tin cần thiết cho việc mua sắm.
                  </PretendardText>
                </div>

                <img
                  onClick={() => {
                    navigation(`${route.customer_center}/1?category=Thông báo`);
                  }}
                  style={{
                    cursor: "pointer",
                    width: isMobile ? 32 : 40,
                    height: isMobile ? 32 : 40,
                  }}
                  src="/assets/icons/btn-right-arrow-40.png"
                  alt="btn-right-arrow-40"
                />
              </div>

              <div
                style={{
                  width: isSmall ? "100%" : 580,
                  height: isMobile ? 92 : 160,
                  background: "rgba(186, 119, 239, 0.1)",
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: isMobile ? "0px 20px" : "0px 30px",
                }}
              >
                <div>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 10 : 14,
                      lineHeight: isMobile ? "13px" : "19.6px",
                      color: "#FF649C",
                      fontWeight: isMobile ? 600 : 700,
                      marginBottom: isMobile ? "4.6px" : 8,
                    }}
                  >
                    FAQ
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 16 : 26,
                      lineHeight: isMobile ? "23.2px" : "36.4px",
                      color: "#313131",
                      fontWeight: isMobile ? 600 : 700,
                      marginBottom: isMobile ? "4.6px" : 8,
                    }}
                  >
                    Câu hỏi hay hỏi
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 10 : 16,
                      lineHeight: isMobile ? "13px" : "23.2px",
                      color: "#626262",
                      fontWeight: isMobile ? 500 : 400,
                      marginRight: isMobile ? 40 : 0,
                    }}
                  >
                    Hãy xác nhận thông qua những câu hỏi thường xuyên được hỏi.
                  </PretendardText>
                </div>

                <img
                  onClick={() => {
                    navigation(
                      `${route.customer_center}/1?category=Câu hỏi thường gặp`
                    );
                  }}
                  style={{
                    cursor: "pointer",
                    width: isMobile ? 32 : 40,
                    height: isMobile ? 32 : 40,
                  }}
                  src="/assets/icons/btn-right-arrow-40.png"
                  alt="btn-right-arrow-40"
                />
              </div>
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />

        {popupList.length > 0
          ? popupList.map((item, index) => {
              if (item == null) {
                return;
              }

              if (popupDelList.some((item02) => item02 === item.id)) {
                return;
              }

              return (
                <PopupModal
                  key={index}
                  data={item}
                  btnOnPress={() => {
                    popupCloseFunc(item.id);
                    setPopupDelList([...popupDelList, item.id]);
                  }}
                  btnConfirmPress={() => {
                    setPopupDelList([...popupDelList, item.id]);
                  }}
                />
              );
            })
          : null}
      </HomeSection>
    </>
  );
};

export default Home;

const HomeSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${(props) => (props.$isMobile ? "40px 0px 80px 0px" : "80px 0px")};
`;

const HomeIconMenuContain = styled.div`
  display: flex;
  margin-right: ${(props) => (props.$isMobile ? "16px" : "40px")};
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-width: ${(props) => (props.$isMobile ? "90px" : "116px")};
  max-width: ${(props) => (props.$isMobile ? "90px" : "116px")};
`;

const HomeIconMenuText = styled(PretendardText)`
  margin-top: 14px;
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
  font-weight: 500;
  color: rgb(49, 49, 49);
  text-align: center;
`;

const PickWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f1f2;
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: ${(props) => (props.$isMobile ? "flex-start" : "center")};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
