import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import styled from "styled-components";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import TitleInput from "../../components/molecules/input/titleInput";
import MainBtn from "../../components/atoms/button/mainBtn";
import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";

import useDisplay from "../../hooks/useDisplay";
import { errorHandler } from "../../util/errorHandler";
import { route } from "../../router/route";

import MemberApi from "../../api/memberApi";
import AuthApi from "../../api/authApi";

const Login = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [cookies, setCookie, removeCookie] = useCookies(["autoLogin"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [emailErrorM, setEmailErrorM] = useState("");
  const [pw, setPw] = useState("");
  const [pwType, setPwType] = useState("password");
  const [pwErrorM, setPwErrorM] = useState("");
  const [autoLogin, setAutoLogin] = useState(false);

  // mounted
  useEffect(() => {
    if (searchParams.get("reflesh")) {
      const message401 = "Vui lòng đăng nhập.";
      toast(`${message401}`);
    }
  }, []);

  useEffect(() => {
    const allRefresh = searchParams.get("refresh");
    if (allRefresh) {
      removeCookie("autoLogin", { path: "/" });
    }
    if (!allRefresh && cookies.autoLogin) {
      autoLoginFunc();
    }
  }, []);

  const handleLogin = async () => {
    try {
      let obj = { email: email, password: pw };
      const response = await AuthApi.login(obj);

      sessionStorage.setItem("token", response.data.data.accessToken);
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      sessionStorage.setItem("isLogin", "true");
      sessionStorage.setItem("email", myInfoRP.email);
      sessionStorage.setItem("id", myInfoRP.id);
      sessionStorage.setItem("role", myInfoRP.role);
      sessionStorage.setItem("name", myInfoRP.name);
      sessionStorage.setItem("profile", myInfoRP.imageServerFileName);
      sessionStorage.setItem("recommendID", myInfoRP.recommendID);

      if (autoLogin) {
        setCookie("autoLogin", true, { maxAge: 3600 * 24 * 14, path: "/" }); //2주
      } else {
        removeCookie("autoLogin", { path: "/" });
      }

      navigation(route.home);
    } catch (error) {
      if (error?.response) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.code === 4001) {
            return toast(`Tài khoản không tồn tại.`);
          }
          if (error?.response?.data?.code === 4002) {
            return toast(
              `${error.response.data.message} Tài khoản đã đăng ký.`
            );
          }
          if (error?.response?.data?.code === 4003) {
            return toast(`Mật khẩu không đúng.`);
          }

          if (error?.response?.data?.code === 4004) {
            return toast(`Tài khoản vô hiệu hóa.`);
          }
          if (error?.response?.data?.code === 4005) {
            return toast(`Tài khoản chưa được phê duyệt.`);
          }
        }
        if (error?.response?.status === 404) {
          return toast(`Thành viên không tồn tại.`);
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const autoLoginFunc = async () => {
    try {
      const response = await AuthApi.getNewToken();
      const newToken = response.data.data.accessToken;
      // new 액세스 토큰 변수 저장
      sessionStorage.setItem("token", newToken);

      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      sessionStorage.setItem("isLogin", "true");
      sessionStorage.setItem("email", myInfoRP.email);
      sessionStorage.setItem("id", myInfoRP.id);
      sessionStorage.setItem("role", myInfoRP.role);
      sessionStorage.setItem("name", myInfoRP.name);
      sessionStorage.setItem("profile", myInfoRP.imageServerFileName);
      sessionStorage.setItem("recommendID", myInfoRP.recommendID);

      navigation(route.home);
    } catch (error) {
      window.location.href = route.login + "?refresh=true";

      if (error?.response?.status === 401) {
        return;
      }
      if (error?.response?.status === 404) {
        return toast(`Thành viên không tồn tại.`);
      }

      errorHandler(error);
    }
  };

  useEffect(() => {
    if (email.length > 0) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(email)) {
        return setEmailErrorM("Hình thức email không chính xác.");
      }

      setEmailErrorM("");
    }
  }, [email]);
  // 카카오 로그인

  const initKakao = () => {
    const jsKey = "cc02ae37823c491a02306433f95dcd37";
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
    }
  };

  useEffect(() => {
    initKakao();
  }, [isMobile]);

  const kakaoLogin = () => {
    window.Kakao.Auth.login({
      success() {
        window.Kakao.API.request({
          url: "/v2/user/me",
          success(res) {
            const socialId = res.id;
            const kakaoAccount = res.kakao_account;

            const userData = {
              email: kakaoAccount.email ? kakaoAccount.email : "",
            };
            handleSNSLogin(String(socialId), "KAKAO", userData);
          },
          fail(error) {},
        });
      },
      fail(error) {},
    });
  };

  // 구글로그인
  const googleClientId =
    "716508250918-2q3fpi029558hq3h0c4p483o96jpapn5.apps.googleusercontent.com";

  const onSuccess = (res) => {
    const googleInfo = jwtDecode(res.credential);

    const userData = {
      email: googleInfo.email ? googleInfo.email : "",
    };
    handleSNSLogin(String(googleInfo.sub), "GOOGLE", userData);
  };

  const onFailure = (err) => {
    // console.log("err", err);
  };

  const googleLogin = () => {
    const googleBtn = document.querySelector(
      '[aria-labelledby="button-label"]'
    );
    googleBtn?.click();
  };

  const responseFacebook = (response) => {
    if (response.status !== "unknown") {
      console.log("User info:", response);
      // 필요한 사용자 정보를 여기에 가져옵니다.
      // 예: response.name, response.email 등
    } else {
      console.error("Facebook login failed");
    }
  };

  const handleSNSLogin = async (socialId, joinType, userData) => {
    try {
      let obj = { socialId: socialId, joinType: joinType };
      const response = await AuthApi.socialLogin(obj);

      sessionStorage.setItem("token", response.data.data.accessToken);
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      sessionStorage.setItem("isLogin", "true");
      sessionStorage.setItem("email", myInfoRP.email);
      sessionStorage.setItem("id", myInfoRP.id);
      sessionStorage.setItem("role", myInfoRP.role);
      sessionStorage.setItem("name", myInfoRP.name);
      sessionStorage.setItem("profile", myInfoRP.imageServerFileName);
      sessionStorage.setItem("recommendID", myInfoRP.recommendID);

      // if (autoLogin) {
      //   setCookie("autoLogin", true, { maxAge: 3600 * 24 * 14, path: "/" }); //2주
      // } else {
      removeCookie("autoLogin", { path: "/" });
      // }

      navigation(route.home);
    } catch (error) {
      if (error?.response) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.code === 4001) {
            navigation(`${route.register_type}`, {
              state: {
                socialId: socialId,
                joinType: joinType,
                userData: userData,
              },
            });

            return;
          }
          if (error?.response?.data?.code === 4002) {
            return toast(
              `${error.response.data.message} Tài khoản đã đăng ký.`
            );
          }
          if (error?.response?.data?.code === 4003) {
            return toast(`Mật khẩu không đúng.`);
          }

          if (error?.response?.data?.code === 4004) {
            return toast(`Tài khoản vô hiệu hóa.`);
          }
          if (error?.response?.data?.code === 4005) {
            return toast(`Tài khoản chưa được phê duyệt.`);
          }
        }
        if (error?.response?.status === 404) {
          return toast(`Thành viên không tồn tại.`);
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <LoginSection>
        <PCContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : 440,
              padding: isMobile ? "0px 20px" : 0,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 24 : 32,
                lineHeight: isMobile ? "33.6px" : "44.8px",
                color: "#313131",
                textAlign: "center",
                fontWeight: 600,
                marginBottom: 40,
              }}
            >
              Đăng nhập
            </PretendardText>

            <TitleInput
              title={"Email"}
              essential={true}
              placeholder={"Vui lòng nhập email của bạn."}
              type={"text"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              warningMessage={emailErrorM}
              style={{
                marginBottom: isMobile ? 24 : 20,
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (pw && pwErrorM === "" && email && emailErrorM === "") {
                    handleLogin();
                  }
                }
              }}
            />

            <TitleInput
              inputType={"password"}
              setInputType={setPwType}
              type={pwType}
              title={"Mật khẩu"}
              essential={true}
              placeholder={"Vui lòng nhập mật khẩu của bạn."}
              onChange={(e) => {
                setPw(e.target.value);
              }}
              value={pw}
              warningMessage={pwErrorM}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (pw && pwErrorM === "" && email && emailErrorM === "") {
                    handleLogin();
                  }
                }
              }}
            />

            <MainBtn
              disabled={
                pw && pwErrorM === "" && email && emailErrorM === ""
                  ? false
                  : true
              }
              onClick={() => {
                handleLogin();
              }}
              style={{
                marginTop: isMobile ? 40 : 30,
                marginBottom: isMobile ? 20 : 15,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                Đăng nhập
              </PretendardText>
            </MainBtn>

            <RightLabelCheckbox
              checked={autoLogin}
              onChange={() => {
                setAutoLogin(!autoLogin);
              }}
              text={"Tự động đăng nhập"}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: isMobile ? 40 : 42,
                marginBottom: isMobile ? 30 : 32,
              }}
            >
              <PretendardText
                onClick={() => {
                  navigation(`${route.pw_find}`);
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  fontWeight: 600,
                  cursor: "pointer",
                  color: "#939393",
                }}
              >
                Quên mật khẩu
              </PretendardText>

              <div
                style={{
                  width: 1,
                  height: 12,
                  background: "#C4C4C4",
                  margin: "0px 12px",
                }}
              />

              <PretendardText
                onClick={() => {
                  navigation(`${route.register_type}`);
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  fontWeight: 600,
                  cursor: "pointer",
                  color: "#939393",
                }}
              >
                Đăng ký
              </PretendardText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <div
                style={{
                  flex: 1,
                  height: 1,
                  background: "#DCDCDC",
                }}
              />
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  fontWeight: 600,
                  color: "#313131",
                  margin: "0px 14px",
                }}
              >
                or
              </PretendardText>
              <div
                style={{
                  flex: 1,
                  height: 1,
                  background: "#DCDCDC",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                onClick={() => {
                  kakaoLogin();
                }}
                style={{
                  width: 48,
                  height: 48,
                  marginRight: 15,
                  cursor: "pointer",
                }}
                src="/assets/icons/ic_kakao_48.png"
                alt="ic_kakao_48"
              />
              <img
                onClick={() => {
                  googleLogin();
                }}
                style={{
                  width: 48,
                  height: 48,
                  marginRight: 15,
                  cursor: "pointer",
                }}
                src="/assets/icons/ic_google_48.png"
                alt="ic_google_48"
              />
              <div style={{ display: "none" }}>
                <GoogleOAuthProvider clientId={googleClientId}>
                  <GoogleLogin
                    onSuccess={(res) => {
                      onSuccess(res);
                    }}
                    onFailure={(err) => {
                      onFailure(err);
                    }}
                  />
                </GoogleOAuthProvider>
              </div>

              <FacebookLogin
                appId="877641550465288"
                callback={responseFacebook}
                render={(renderProps) => (
                  <img
                    onClick={renderProps.onClick}
                    style={{
                      width: 48,
                      height: 48,
                      marginRight: 15,
                      cursor: "pointer",
                    }}
                    src="/assets/icons/ic_facebook_48.png"
                    alt="ic_facebook_48"
                  />
                )}
              />

              <img
                onClick={() => {}}
                style={{
                  width: 48,
                  height: 48,
                  cursor: "pointer",
                }}
                src="/assets/icons/ic_apple_48.png"
                alt="ic_apple_48"
              />
            </div>
          </div>
        </PCContainer>
      </LoginSection>
    </>
  );
};

export default Login;

const LoginSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
`;
