import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import PickSwiper from "../../components/templates/home/pickSwiper";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import FollowSwiper from "../../components/templates/home/followSwiper";
import InfluencerItem from "../../components/templates/influencer/influencerItem";
import Pagination from "../../components/organisms/pagination";

import useDisplay from "../../hooks/useDisplay";
import SelectM from "../../components/atoms/select/selectM";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import { route } from "../../router/route";
import Empty from "../../components/organisms/empty";

const InfluencerList = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [isLogin, setIsLogin] = useState(false);
  const [followList, setFollowList] = useState([]);
  const [pickList, setPickList] = useState([]);

  const [influencerList, setInfluencerList] = useState([]);
  const [influencerListBottom, setInfluencerListBottom] = useState([]);
  const [pagination, setPagination] = useState({
    size: 16, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    {
      value: "Gần nhất",
      label: "Gần nhất",
    },
    {
      value: "Bán chạy",
      label: "Bán chạy",
    },
    {
      value: "Yêu thích",
      label: "Yêu thích",
    },
  ]);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");

    setIsLogin(isLogin);
    kovietPickIFListFunc();
    followIFListFunc();
  }, []);

  const followIFListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };

      const response = await MemberApi.followingList(data);

      setFollowList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const kovietPickIFListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 8,
        approval: "APPROVE",
        role: "INFLUENCER",
        isPick: true,
      };

      const response = await MemberApi.memberList(data);

      setPickList(response.data.data.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  useEffect(() => {
    setFilterValue(
      searchParams.get("filterValue") ? searchParams.get("filterValue") : ""
    );

    influencerListFunc();
  }, [location]);

  const influencerListFunc = async () => {
    try {
      setInfluencerList([]);
      setInfluencerListBottom([]);

      const params = {
        page: page,
        size: pagination.size,
        approval: "APPROVE",
        role: "INFLUENCER",
        orderType:
          searchParams.get("filterValue") === "Yêu thích"
            ? 3
            : searchParams.get("filterValue") === "Bán chạy"
            ? 2
            : 1,
      };

      const response = (await MemberApi.memberList(params)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      const chunkedArray = chunkArray(response.content, 8);

      setInfluencerList(chunkedArray[0] ? chunkedArray[0] : []);
      setInfluencerListBottom(chunkedArray[1] ? chunkedArray[1] : []);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  return (
    <>
      <InfluencerListSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isSmall ? "0px 20px 60px 20px" : "0px 0px 80px 0px",
            width: "100%",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
              marginBottom: 40,
            }}
          >
            TOP Influencers
          </PretendardText>

          {isLogin && followList.length > 0 ? (
            <div
              style={{
                background: "#F6F1F9",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: isMobile ? "20px 0px" : "30px 0px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 14 : 20,
                  lineHeight: isMobile ? "19.6px" : "28px",
                  fontWeight: 600,
                  color: "#313131",
                  marginLeft: isMobile ? 20 : 30,
                  marginBottom: isMobile ? 20 : 24,
                }}
              >
                Influencer mà Hing Kil Dong đã theo dõi
              </PretendardText>

              <div
                style={{
                  overflowX: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {followList.length > 0 ? (
                  <FollowSwiper dataList={followList} />
                ) : null}
              </div>
            </div>
          ) : null}

          {isSmall ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: 40,
                marginBottom: 24,
              }}
            >
              <SelectM
                style={{}}
                setValue={(value) => {
                  navigate(`${route.influencer_list}/1?filterValue=${value}`);
                }}
                value={filterValue}
                dataList={filterList}
                placeholder={"Lựa chọn"}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 50,
                marginBottom: 20,
                width: "100%",
              }}
            >
              {filterList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CategoryText
                      onClick={() => {
                        navigate(
                          `${route.influencer_list}/1?filterValue=${item.value}`
                        );
                      }}
                      $isActive={filterValue === item.value}
                    >
                      {item.label}
                    </CategoryText>
                    {filterList.length === index + 1 ? null : (
                      <div
                        style={{
                          height: 12,
                          width: 1,
                          background: "#C4C4C4",
                          margin: "0px 8px",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

          <div
            style={{
              display: "flex",
              gap: isMobile ? "24px 15px" : "40px 20px",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: isSmall ? "space-between" : "flex-start",
              width: "100%",
            }}
          >
            {influencerList.length > 0 ? (
              influencerList.map((item, index) => {
                return <InfluencerItem key={index} data={item} />;
              })
            ) : (
              <Empty title={"Vẫn chưa có cúm đã đăng ký."} />
            )}
          </div>
        </PCContainer>

        {pickList && pickList.length > 0 ? (
          <PickWrapper>
            <PCContainer
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                width: isSmall ? "100%" : "auto",
                padding: isSmall ? "60px 20px 60px 20px" : "80px 0px 80px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 24,
                }}
              >
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 10,
                  }}
                  src="/assets/icons/ic-influencer-24.png"
                  alt="ic-influencer-24"
                />
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: isMobile ? "26.1px" : "33.6px",
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  KOVIET PICK!
                </PretendardText>
              </div>

              {pickList.length > 0 ? <PickSwiper dataList={pickList} /> : null}
            </PCContainer>
          </PickWrapper>
        ) : null}

        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            padding: isSmall ? "60px 20px 0px 20px" : "80px 0px 0px 0px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: isMobile ? "24px 15px" : "40px 20px",
              flexDirection: "row",
              justifyContent: isSmall ? "space-between" : "flex-start",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {influencerListBottom.length > 0
              ? influencerListBottom.map((item, index) => {
                  return <InfluencerItem key={index} data={item} />;
                })
              : null}
          </div>

          {influencerList.length > 0 && pagination.totalCount > 16 ? (
            <div
              style={{
                marginTop: 50,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                route={route.influencer_list}
                queryString={`filterValue=${filterValue}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </InfluencerListSection>
    </>
  );
};

export default InfluencerList;

const InfluencerListSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
`;

const PickWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f1f2;
`;

const CategoryText = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#ff649c" : "rgb(98, 98, 98)")};
  cursor: pointer;

  &:hover {
    color: #ff649c;
  }
`;
