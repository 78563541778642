import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";

import TextArea from "../../components/atoms/input/textarea";
import MainBtn from "../../components/atoms/button/mainBtn";
import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";
import MypageMenuM from "../../components/templates/mypageMenuM";
import RightLabelRadio from "../../components/molecules/radio/rightLabelRadio";
import MemberApi from "../../api/memberApi";
import { errorHandler } from "../../util/errorHandler";
import ConfirmModal from "../../components/templates/modals/confirmModal";

const WithdrawPage = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [reasonList, setReasonList] = useState([
    {
      title: "Không có sản phẩm nào tôi thích",
      isActive: true,
    },
    { title: "Vì sản phẩm quá đắt", isActive: false },
    { title: "Không hài lòng về dịch vụ", isActive: false },
    { title: "Vì tôi không sử dụng nó thường xuyên", isActive: false },
  ]);
  const [betterPoint, setBetterPoint] = useState("");
  const [agree, setAgree] = useState(false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [isRetiredRequest, setIsRetiredRequest] = useState(false);

  const reasonListClickFunc = (title) => {
    let temp = reasonList.map((item) => {
      return item.title === title
        ? {
            ...item,
            isActive: true,
          }
        : {
            ...item,
            isActive: false,
          };
    });

    setReasonList(temp);
  };

  const logoutFunc = async () => {
    window.location.href = route.login + "?refresh=true";

    sessionStorage.clear();
  };

  const retiredFunc = async () => {
    try {
      const obj = {
        reason: reasonList.find((item) => item.isActive === true).title,
        improvements: betterPoint,
      };

      const response = await MemberApi.retired(obj);
      logoutFunc();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return;
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  useEffect(() => {
    myInfoFunc();
  }, []);

  const myInfoFunc = async () => {
    try {
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      setIsRetiredRequest(myInfoRP.isRetiredRequest);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <WithdrawPageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "0px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? "0px" : "60px 100px",
                background: "#fff",
                borderRadius: 8,
                marginTop: isMobile ? 31 : 0,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "28px",
                  color: "#222222",
                  fontWeight: 600,
                  textAlign: "center",
                  marginBottom: isMobile ? 40 : 60,
                }}
              >
                Hãy góp ý cho <br />
                Chúng tôi để cải thiện dịch vụ tốt hơn
              </PretendardText>

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>1</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Lý do bạn muốn xóa tài khoản là gì?
                </SectionTitleText>
              </SectionTitleDiv>

              {isMobile ? (
                <div
                  style={{
                    marginBottom: 40,
                  }}
                >
                  {reasonList.map((item, index) => {
                    return (
                      <RightLabelRadio
                        key={index}
                        textStyle={{
                          fontWeight: item.isActive ? 500 : 400,
                          color: item.isActive ? "#313131" : "#626262",
                        }}
                        inputStyle={{
                          marginBottom: 20,
                        }}
                        checked={item.isActive}
                        onClick={() => {
                          reasonListClickFunc(item.title);
                        }}
                        text={item.title}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 16,
                    marginBottom: 80,
                  }}
                >
                  {reasonList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: isTablet ? "48.2%" : 332,
                          height: 48,
                          cursor: "pointer",
                          border: item.isActive
                            ? "1px solid #FF3D83"
                            : "1px solid #DCDCDC",
                          borderRadius: 8,
                          background: item.isActive ? "#FFF6FA" : "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          reasonListClickFunc(item.title);
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            fontWeight: item.isActive ? 500 : 400,
                            color: item.isActive ? "#313131" : "#626262",
                            textAlign: "center",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      </div>
                    );
                  })}
                </div>
              )}

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>2</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Vui lòng cho chúng tôi biết Koviet cần cải thiện điều gì.
                </SectionTitleText>
              </SectionTitleDiv>

              <div
                style={{
                  marginBottom: isMobile ? 60 : 80,
                }}
              >
                <TextArea
                  value={betterPoint}
                  onChange={(e) => {
                    setBetterPoint(e.target.value);
                  }}
                  placeholder={"Hãy viết bình luận."}
                  style={{
                    height: 120,
                  }}
                />
              </div>

              <SectionTitleDiv $isMobile={isMobile}>
                <SectionTitleNumDiv>
                  <SectionTitleNumText>3</SectionTitleNumText>
                </SectionTitleNumDiv>
                <SectionTitleText $isMobile={isMobile}>
                  Vui lòng đồng ý với chính sách xóa tài khoản
                </SectionTitleText>
              </SectionTitleDiv>

              <div style={{}}>
                <YScrollCard
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "19.6px",
                      color: "#939393",
                      fontWeight: 400,
                    }}
                  >
                    Chính sách xử lý rút tiền thành viên Chính sách xử lý rút
                    tiền thành viên Chính sách xử lý rút tiền thành viên Chính
                    sách xử lý rút tiền thành viên Chính sách xử lý rút tiền
                    thành viên Chính sách xử lý rút tiền thành viên Chính sách
                    xử lý rút tiền thành viên Chính sách xử lý rút tiền thành
                    viên Chính sách xử lý rút tiền thành viên Chính sách xử lý
                    rút tiền thành viên Chính sách xử lý rút tiền thành viên
                    Chính sách xử lý rút tiền thành viên Chính sách xử lý Rút
                    tiền thành viên chính sách xử lý Chính sách xử lý rút tiền
                    thành viên Chính sách xử lý rút tiền thành viên Chính sách
                    xử lý rút tiền thành viên Chính sách xử lý rút tiền thành
                    viên Chính sách xử lý rút tiền thành viên Chính sách xử lý
                    rút tiền thành viên Chính sách xử lý rút tiền thành viên
                    Chính sách xử lý rút tiền thành viên Chính sách xử lý rút
                    tiền thành viên Chính sách xử lý rút tiền thành viên Chính
                    sách xử lý rút tiền thành viên Chính sách xử lý rút tiền
                    thành viên Chính sách xử lý rút tiền thành viên Chính sách
                    xử lý rút tiền thành viên Chính sách xử lý rút tiền thành
                    viên Chính sách xử lý rút tiền thành viên Chính sách xử lý
                    rút tiền thành viên Chính sách xử lý Rút tiền thành viên
                    chính sách xử lý Chính sách xử lý rút tiền thành viên Chính
                    sách xử lý rút tiền thành viên Chính sách xử lý rút tiền
                    thành viên Chính sách xử lý rút tiền thành viên Chính sách
                    xử lý rút tiền thành viên Chính sách xử lý rút tiền thành
                    viên Chính sách xử lý rút tiền thành viên Chính sách xử lý
                    rút tiền thành viên Chính sách xử lý rút tiền thành viên
                    Chính sách xử lý rút tiền thành viên Chính sách xử lý rút
                    tiền thành viên Chính sách xử lý rút tiền thành viên Chính
                    sách xử lý rút tiền thành viên Chính sách xử lý rút tiền
                    thành viên Chính sách xử lý rút tiền thành viên Chính sách
                    xử lý rút tiền thành viên Chính sách xử lý rút tiền thành
                    viên Chính sách xử lý Rút tiền thành viên chính sách xử lý
                    Chính sách xử lý rút tiền thành viên Chính sách xử lý rút
                    tiền thành viên Chính sách xử lý rút tiền thành viên Chính
                    sách xử lý rút tiền thành viên Chính sách xử lý rút tiền
                    thành viên
                  </PretendardText>
                </YScrollCard>
                <RightLabelCheckbox
                  checked={agree}
                  onChange={() => {
                    setAgree(!agree);
                  }}
                  text={"Tôi đồng ý."}
                />
              </div>
              <MainBtn
                disabled={agree === true ? false : true}
                onClick={() => {
                  setIsCofirmModal(true);
                }}
                style={{
                  marginTop: isMobile ? 60 : 80,
                  height: 48,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  Xóa tài khoản
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />

        {isConfirmModal ? (
          <ConfirmModal
            isScroll={false}
            title={"rút khỏi hội viên"}
            content={"Anh thật sự rút lui sao?"}
            onClick={() => {
              setIsCofirmModal(false);
            }}
            onYClick={() => {
              if (isRetiredRequest) {
                toast("Tài khoản đã xóa.");
              } else {
                retiredFunc();
              }

              setIsCofirmModal(false);
            }}
            yText={"Có ạ."}
            nText={"Không ạ."}
          />
        ) : null}
      </WithdrawPageSection>
    </>
  );
};

export default WithdrawPage;

const WithdrawPageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SectionTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$isMobile ? "flex-start" : "center")};
  margin-bottom: 24px;
`;

const SectionTitleNumDiv = styled.div`
  height: 24px;
  width: 24px;
  background: rgb(255, 236, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 4px;
`;

const SectionTitleNumText = styled(PretendardText)`
  text-align: center;
  font-size: 14px;
  line-height: 19.6px;
  color: rgb(169, 0, 59);
  font-weight: 500;
`;

const SectionTitleText = styled(PretendardText)`
  text-align: ${(props) => (props.$isMobile ? "left" : "center")};
  font-size: 16px;
  line-height: 23.2px;
  color: #313131;
  font-weight: 600;
`;

const YScrollCard = styled.div`
  width: 100%;
  padding: 16px 17px;
  border-radius: 8px;
  height: 172px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
