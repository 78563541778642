import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import { route } from "./route";

import Home from "./../pages/home";
import Search from "./../pages/search";

import Login from "../pages/auth/login";
import RegisterType from "../pages/auth/registerType";
import Register from "../pages/auth/register";
import PwFind from "../pages/auth/pwFind";
import PwChange from "../pages/auth/pwChange";

import NewProduct from "../pages/productList/newProduct";
import BestProduct from "../pages/productList/bestProduct";
import EventProduct from "../pages/productList/eventProduct";
import CategoryProduct from "../pages/productList/categoryProduct";

import InfluencerList from "../pages/influencer/influencerList";
import InfluencerDetail from "../pages/influencer/influencerDetail";
import Dashboard from "../pages/influencer/dashboard";

import ExhibitionList from "../pages/exhibition/exhibitionList";
import ExhibitionDetail from "../pages/exhibition/exhibitionDetail";

import ProductDetail from "../pages/product/productDetail";
import ProductPayment from "../pages/product/productPayment";
import ShopBasket from "../pages/product/shopBasket";
import WishList from "../pages/product/wishList";

import CustomerCenter from "../pages/customerCenter";
import Provision from "../pages/provision";
import VideoRegister from "../pages/influencer/videoRegister";

import OrderList from "../pages/mypage/orderList";
import ExchangeApply from "../pages/mypage/exchangeApply";
import ExchangeComplete from "../pages/mypage/exchangeComplete";
import DeliveryStatus from "../pages/mypage/deliveryStatus";
import ExchangeList from "../pages/mypage/exchangeList";
import ReviewListPage from "../pages/mypage/reviewListPage";
import InquiryListPage from "../pages/mypage/inquiryListPage";
import InquiryDetailPage from "../pages/mypage/inquiryDetailPage";
import NotificationListPage from "../pages/mypage/notificationListPage";
import PointManagePage from "../pages/mypage/pointManagePage";
import AccountManage from "../pages/mypage/accountManage";
import WithdrawPage from "../pages/mypage/withdrawPage";

const Router = () => {
  return (
    <Routes>
      {/* default */}
      <Route path={route.default} element={<Home />} />

      {/* 홈 */}
      <Route path={route.home} element={<Home />} />

      {/* 검색 */}
      <Route path={route.search + "/:page"} element={<Search />} />

      {/* 상품리스트 */}
      <Route path={route.new_product + "/:page"} element={<NewProduct />} />
      <Route path={route.best_product + "/:page"} element={<BestProduct />} />
      <Route path={route.event_product + "/:page"} element={<EventProduct />} />
      <Route
        path={route.category_product + "/:page"}
        element={<CategoryProduct />}
      />

      {/* 상품 */}
      <Route path={route.product_detail + "/:id"} element={<ProductDetail />} />
      <Route path={route.product_payment} element={<ProductPayment />} />
      <Route path={route.shop_basket} element={<ShopBasket />} />
      <Route path={route.wish_list} element={<WishList />} />

      {/* 인플루언서 */}
      <Route
        path={route.influencer_list + "/:page"}
        element={<InfluencerList />}
      />
      <Route
        path={route.influencer_list_detail + "/:id/:page"}
        element={<InfluencerDetail />}
      />
      <Route path={route.dashboard + "/:page"} element={<Dashboard />} />
      <Route path={route.video_register} element={<VideoRegister />} />

      {/* 기획전 */}
      <Route
        path={route.exhibition_list + "/:page"}
        element={<ExhibitionList />}
      />
      <Route
        path={route.exhibition_list_detail + "/:id/:page"}
        element={<ExhibitionDetail />}
      />

      {/* auth */}
      <Route path={route.login} element={<Login />} />
      <Route path={route.register_type} element={<RegisterType />} />
      <Route path={route.register} element={<Register />} />
      <Route path={route.pw_find} element={<PwFind />} />
      <Route path={route.pw_change} element={<PwChange />} />

      {/* 고객센터 */}
      <Route
        path={route.customer_center + "/:page"}
        element={<CustomerCenter />}
      />

      {/* 약관 */}
      <Route path={route.provision} element={<Provision />} />

      {/* 마이페이지 */}
      <Route
        path={route.mypage_order_list + "/:page"}
        element={<OrderList />}
      />
      <Route
        path={route.mypage_order_list_exchange + "/:id"}
        element={<ExchangeApply />}
      />
      <Route
        path={route.mypage_order_list_exchange_complete}
        element={<ExchangeComplete />}
      />
      <Route
        path={route.mypage_order_list_delivery_status + "/:id"}
        element={<DeliveryStatus />}
      />

      <Route
        path={route.mypage_exchange_list + "/:page"}
        element={<ExchangeList />}
      />
      <Route
        path={route.mypage_review_list + "/:page"}
        element={<ReviewListPage />}
      />
      <Route
        path={route.mypage_inquiry_list + "/:page"}
        element={<InquiryListPage />}
      />
      <Route
        path={route.mypage_inquiry_list_detail + "/:id"}
        element={<InquiryDetailPage />}
      />
      <Route
        path={route.mypage_notification_list + "/:page"}
        element={<NotificationListPage />}
      />
      <Route
        path={route.mypage_point_manage + "/:page"}
        element={<PointManagePage />}
      />
      <Route path={route.mypage_account_manage} element={<AccountManage />} />
      <Route path={route.mypage_withdraw} element={<WithdrawPage />} />

      {/* Not Found */}
      <Route path="*" element={<Navigate to={route.default} replace />} />
    </Routes>
  );
};

export default Router;
