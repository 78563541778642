import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import TitleInput from "../../molecules/input/titleInput";
import RightLabelCheckbox from "../../molecules/checkbox/rightLabelCheckbox";
import ConfirmModal from "./confirmModal";
import useDisplay from "../../../hooks/useDisplay";
import MerchAskApi from "../../../api/merchAskApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";

function ProductInqueryModal({
  visiable,
  setVisiable,
  isScroll,
  merchId,
  onYClick,
  preData,
  setPreData,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [title, setTitle] = useState(preData ? preData.title : "");
  const [titleErrorM, setTitleErrorM] = useState("");
  const [content, setContent] = useState(preData ? preData.question : "");
  const [contentErrorM, setContentErrorM] = useState("");

  const [agree, setAgree] = useState(preData ? preData.secretFlag : false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  const askAddFunc = async () => {
    try {
      const params = {
        secretFlag: agree,
        title: title,
        question: content,
      };

      const response = (await MerchAskApi.addAsk(merchId, params)).data.data;

      toast("Câu hỏi đã được đăng ký.");
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const askEditFunc = async () => {
    try {
      const params = {
        secretFlag: agree,
        title: title,
        question: content,
        answer: preData.answer,
      };

      const response = (await MerchAskApi.updateAsk(preData.id, params)).data
        .data;

      toast("Đã chỉnh sửa.");
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <ProductInqueryModalWrapper $isMobile={isMobile}>
      <ProductInqueryModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: 30,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Đặt câu hỏi về Sản phẩm
            </PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
                setPreData();
              }}
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <TitleInput
              title={"Tiêu đề"}
              essential={true}
              placeholder={"Hãy nhập tiêu đề."}
              type={"text"}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              warningMessage={titleErrorM}
              style={{
                marginBottom: 20,
              }}
            />

            <TitleInput
              isTextarea={true}
              title={"Nội dung"}
              essential={true}
              placeholder={"Vui lòng nhập nội dung."}
              type={"textare"}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
              warningMessage={contentErrorM}
              style={{
                marginBottom: 20,
              }}
              inputStyle={{
                height: 185,
              }}
            />

            <RightLabelCheckbox
              checked={agree}
              onChange={() => {
                setAgree(!agree);
              }}
              text={"Ẩn thông tin nội dung"}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 40,
              width: "100%",
            }}
          >
            <MainBtn
              disabled={content && title ? false : true}
              onClick={() => {
                setIsCofirmModal(true);
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Đặt câu hỏi
              </PretendardText>
            </MainBtn>
          </div>
        </div>
      </ProductInqueryModalContainer>

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={true}
          title={"Bạn sẽ đăng ký chứ?"}
          onClick={() => {
            setIsCofirmModal(false);
          }}
          onYClick={async () => {
            setIsCofirmModal(false);
            setVisiable(false);

            preData ? await askEditFunc() : await askAddFunc();
            await onYClick();
            setPreData();
          }}
        />
      ) : null}
    </ProductInqueryModalWrapper>
  );
}

export default ProductInqueryModal;

const ProductInqueryModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const ProductInqueryModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "520px")};
  padding: ${(props) => (props.$isMobile ? "40px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;
