import React, { useRef } from "react";

import PretendardText from "../text/pretendardText";

function CameraFileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .pdf, .hwp, .docx"
        ref={imageInput}
        onChange={props.handleChange}
        multiple={props.multiple}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          height: 48,
          width: "100%",
          background: "#ffffff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #DCDCDC",
          borderRadius: 8,
        }}
      >
        <img
          src="/assets/icons/camera.png"
          style={{
            height: 24,
            width: 24,
            marginRight: 10,
          }}
        />
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "23.2px",
            color: "#222222",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Đính kèm ảnh và video
        </PretendardText>
      </div>
    </>
  );
}

export default CameraFileForm;
