import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import TitleInput from "../../components/molecules/input/titleInput";
import MainBtn from "../../components/atoms/button/mainBtn";

import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";

let timer;

const PwFind = () => {
  let min = 3;
  let sec = 0;

  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [email, setEmail] = useState("");
  const [emailErrorM, setEmailErrorM] = useState("");
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailCheckM, setEmailCheckM] = useState("");

  const [certifiedNumSave, setCertifiedNumSave] = useState("");

  const [certifiedNum, setCertifiedNum] = useState("");
  const [certifiedNumErrorM, setCertifiedNumErrorM] = useState("");
  const [certifiedNumCheckM, setCertifiedNumCheckM] = useState("");

  const [minState, setMinState] = useState(3);
  const [secState, setSecState] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      clearTimer("타이머 종료");
    };
  }, []);

  const countTimerStart = () => {
    timer = setInterval(countTimer, 1000);
  };

  const countTimer = () => {
    if (sec != 0) {
      sec = sec - 1;
      setSecState(sec);
    } else {
      if (min != 0) {
        min = min - 1;
        sec = 59;

        setSecState(sec);
        setMinState(min);
      } else {
        clearTimer("타이머 종료");
        setCertifiedNumErrorM("Xác thực đã thất bại. Hãy thử xác nhận lại.");
      }
    }
  };

  function clearTimer(text) {
    clearInterval(timer);

    if (text === "타이머 종료") {
      min = 3;
      setMinState(3);
      sec = 0;
      setSecState(0);
    }
  }

  useEffect(() => {
    if (email.length > 0) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(email)) {
        return setEmailErrorM("Hình thức email không chính xác.");
      }

      setEmailErrorM("");
      setEmailCheckM("");
      setEmailCheck(false);
    }
  }, [email]);

  useEffect(() => {
    if (emailCheck) {
      setCertifiedNum("");
      setCertifiedNumErrorM("");
      setCertifiedNumCheckM("");
    }
  }, [emailCheck]);

  const emailCheckFunc = async () => {
    try {
      const obj = {
        email: email,
      };
      const response = await AuthApi.emailCheck(obj);

      setEmailCheck(false);
      setEmailErrorM("Không có tài khoản đã đăng ký thành viên.");
      setEmailCheckM("");
    } catch (error) {
      emailSendFunc();
    }
  };

  const emailSendFunc = async () => {
    try {
      const obj = {
        email: email,
      };
      const response = await AuthApi.sendEmail(obj);

      setCertifiedNumSave(response.data.data);
      setEmailCheck(true);
      countTimerStart();
      toast("Đã gửi mã xác thực qua email đăng ký.");
    } catch (error) {
      setEmailCheck(false);

      toast(`Đây là tài khoản đã gia nhập ${error.response.data.joinType}.`);
    }
  };

  const certifiedNumCheckFunc = () => {
    if (certifiedNumSave != certifiedNum) {
      setCertifiedNumErrorM("Mã xác thực không khớp.");
    } else {
      setCertifiedNumErrorM("");
      setCertifiedNumCheckM("Đã được chứng nhận.");

      clearTimer("타이머 종료");
    }
  };

  return (
    <>
      <PwFindSection>
        <PCContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : 440,
              padding: isMobile ? "0px 20px" : 0,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 24 : 32,
                lineHeight: isMobile ? "33.6px" : "44.8px",
                color: "#313131",
                textAlign: "center",
                fontWeight: 600,
                marginBottom: 15,
              }}
            >
              Quên mật khẩu
            </PretendardText>

            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#939393",
                textAlign: "center",
                fontWeight: 500,
                marginBottom: 40,
              }}
            >
              Vui lòng nhập email bạn đã đăng ký. <br />
              Mã sẽ được gửi tới mail của bạn.
            </PretendardText>

            <TitleInput
              title={"Email"}
              essential={true}
              placeholder={
                isMobile ? "thư điện tử" : "Vui lòng nhập email của bạn."
              }
              type={"text"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              warningMessage={emailErrorM}
              checkMessage={emailCheckM}
              isRightButton={true}
              isRightButtonText={"Xác minh"}
              isRightButtonStyle={{
                right: "-4%",
              }}
              rightButtonOnClick={() => {
                if (email && emailErrorM === "") {
                  emailCheckFunc();
                }
              }}
            />

            {emailCheck ? (
              <TitleInput
                title={"Mã xác thực"}
                essential={true}
                placeholder={
                  isMobile ? "Nhập mã xác thực" : "Vui lòng nhập mã xác thực"
                }
                type={"text"}
                onChange={(e) => {
                  setCertifiedNum(e.target.value);
                }}
                value={certifiedNum}
                warningMessage={certifiedNumErrorM}
                checkMessage={certifiedNumCheckM}
                style={{
                  marginTop: isMobile ? 24 : 20,
                }}
                isRightButton={true}
                isRightButtonText={"Xác nhận"}
                isRightButtonStyle={{
                  right: "-4%",
                }}
                isTimer={true}
                min={minState}
                sec={secState}
                timerStyle={{}}
                rightButtonOnClick={() => {
                  certifiedNumCheckFunc();
                }}
                maxLength={6}
              />
            ) : null}

            <MainBtn
              disabled={
                email && certifiedNum && certifiedNumCheckM !== ""
                  ? false
                  : true
              }
              onClick={() => {
                navigation(`${route.pw_change}/?email=${email}`);
              }}
              style={{
                marginTop: 40,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                Tiếp tục
              </PretendardText>
            </MainBtn>
          </div>
        </PCContainer>
      </PwFindSection>
    </>
  );
};

export default PwFind;

const PwFindSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 111px 0px;
`;
