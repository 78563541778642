import React from "react";

import styled from "styled-components";

function DateInput(props) {
  return (
    <>
      <div
        style={{
          background: "#fff",
          padding: "0px 12px",
          borderRadius: 8,
          border: "1px solid #f5f5f5",
        }}
      >
        <DateInputWrapper
          type="date"
          value={props.value}
          onChange={props.onChange}
          data-placeholder={props.placeholder}
          style={{
            ...props.style,
          }}
          disabled={props.disabled}
          required
          aria-required="true"
        />
      </div>
    </>
  );
}

export default DateInput;

const DateInputWrapper = styled.input.attrs((props) => {})`
  border: none; // 테두리 설정은 본인 맘대로
  position: relative; // 캘린더 아이콘을 클릭해야만 달력이 보이기 때문에 이 영역 자체를 제어하기 위해 설정

  padding-top: 1px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;

  width: 100%;
  height: 44px;

  background: url("/assets/icons/date.png") no-repeat right center / 24px auto; // 배경에 커스텀 아이콘을 넣어주자! x축은 오른쪽에서부터 10px 떨어지게 하고, y축은 가운데 정렬하고, width 35px에 height auto라는 크기를 부여한다.
  background-color: #fff;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  color: #191919;
  border: 0px;

  &:focus {
    background: #fff;
    outline: 1px solid #00aeef;
  }

  &:disabled {
    outline: none;
    background: #adb4ba;
    color: #778088;
  }

  // 실제 캘린더 아이콘을 클릭하는 영역을 의미하는 선택자
  // 이 영역을 확장해서 input의 어떤 곳을 클릭해도 캘린더를 클릭한 것과 같은 효과를 만들자!
  &::-webkit-calendar-picker-indicator {
    position: absolute; // 이를 설정하기 위해 사전에 relative를 설정한 것이다.
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent; // 배경은 투명하게,
    color: transparent; // 글자도 투명하게! 이 두 설정을 통해 캘린더 아이콘을 사라지게 만든다.
    cursor: pointer;
  }

  &:before {
    content: attr(data-placeholder) !important;
    width: 100%;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #b6b6b6;
    position: absolute;
  }

  &:focus::before,
  &:valid::before {
    display: ${(props) => (props.value ? "none" : "block")};
  }

  &:required:invalid::-webkit-datetime-edit {
    color: transparent;
  }
  &:focus::-webkit-datetime-edit {
    color: black !important;
  }
`;
