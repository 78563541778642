import React, { useEffect, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import Pagination from "../../components/organisms/pagination";

import ProductItem from "../../components/templates/product/productItem";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MainBtn from "../../components/atoms/button/mainBtn";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import { route } from "../../router/route";
import MerchApi from "../../api/merchApi";
import Empty from "../../components/organisms/empty";

const InfluencerDetail = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, page } = useParams();

  const [isMe, setIsMe] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isFollow, setIsFollow] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);

  const [categoryProductList, setCategoryProductList] = useState([]);

  const [categoryValue, setCategoryValue] = useState("Hình ảnh");
  const [subCategoryList, setSubCategoryList] = useState([
    { title: "Hình ảnh" },
    { title: "Sản phẩm" },
  ]);

  const [pagination, setPagination] = useState({
    size: 20, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category") ? searchParams.get("category") : "Hình ảnh"
    );

    merchListFunc();
  }, [location]);

  const merchListFunc = async () => {
    try {
      setCategoryProductList([]);

      const data = {
        page: page,
        size: pagination.size,
        memberId: id,
      };

      const response = (await MerchApi.getList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setCategoryProductList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  useEffect(() => {
    const myID = sessionStorage.getItem("id");

    if (Number(id) === Number(myID)) {
      setIsMe(true);
    } else {
      setIsMe(false);
    }

    userDetailFunc();
  }, []);

  const userDetailFunc = async () => {
    try {
      const response = (await MemberApi.memberDetail(id)).data.data.content;

      setUserInfo(response);
      setFollowerCount(response.followerCount);
      setIsFollow(response.isFollowing);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const followTriggerFunc = async () => {
    try {
      await MemberApi.followTrigger(userInfo.id);
      setIsFollow(!isFollow);

      if (isFollow) {
        setFollowerCount(followerCount - 1);
      } else {
        setFollowerCount(followerCount + 1);
        toast("Đã theo dõi");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <InfluencerDetailSection>
        <img
          style={{
            width: "100%",
            height: 175,
            position: "absolute",
            top: 0,
            zIndex: -1,
          }}
          src={isSmall ? "/assets/imgs/bg_m.png" : "/assets/imgs/bg.png"}
          alt="bg"
        />

        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: isSmall ? "0px 20px 0px 20px" : "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "100%",
              paddingTop: isMobile ? 20 : 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  width: isMobile ? 86 : "130px",
                  height: isMobile ? 86 : "130px",
                  borderRadius: "50%",
                  border: isMobile
                    ? "2px solid transparent"
                    : "3px solid transparent",
                  backgroundImage:
                    "linear-gradient(146.86deg, #FF3D83 7.41%, #BA77EF 52.86%)",
                  backgroundOrigin: "border-box",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                  src={
                    userInfo?.imageServerFileName
                      ? `${process.env.REACT_APP_MEDIA_URL}${userInfo.imageServerFileName}`
                      : "/assets/icons/empty_person.png"
                  }
                  alt="user-temp03"
                />
              </div>

              <div
                style={{
                  marginLeft: isMobile ? 12 : 15,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 16 : 24,
                    lineHeight: isMobile ? "23.2px" : "33.6px",
                    color: "#313131",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  {userInfo?.nickname}
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: isMobile ? 0 : 8,
                  }}
                >
                  <img
                    style={{
                      width: 14,
                      height: 14,
                      marginRight: 4,
                      marginBottom: isMobile ? 1 : 0,
                    }}
                    src="/assets/icons/user.png"
                    alt="user"
                  />
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 12 : 16,
                      lineHeight: isMobile ? "16.8px" : "23.2px",
                      fontWeight: 400,
                      color: "#ABABAB",
                    }}
                  >
                    Followers
                  </PretendardText>
                  <div
                    style={{
                      margin: "0px 8px",
                      height: 12,
                      width: 1,
                      background: "#DCDCDC",
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 12 : 16,
                      lineHeight: isMobile ? "16.8px" : "23.2px",
                      fontWeight: 400,
                      color: "#626262",
                    }}
                  >
                    {Number(followerCount).toLocaleString()} người
                  </PretendardText>
                </div>
              </div>
            </div>
            <MainBtn
              onClick={() => {
                if (isMe) {
                  navigate(route.video_register);
                } else {
                  followTriggerFunc();
                }
              }}
              style={{
                width: isMobile ? (isMe ? 86 : 65) : isMe ? 145 : 105,
                height: isMobile ? 25 : 47,
                marginBottom: isMobile ? 21 : 16,
                background: isMe ? "#FF649C" : isFollow ? "#FF5A5A" : "#FF649C",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 10 : 16,
                  lineHeight: isMobile ? "13px" : "23.2px",
                  color: "white",
                  fontWeight: 600,
                }}
              >
                {isMe ? "Tải video lên" : isFollow ? "UNFOLLOW" : "FOLLOW"}
              </PretendardText>
            </MainBtn>
          </div>

          <div
            style={{
              marginTop: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #DCDCDC",
            }}
          >
            {subCategoryList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    padding: isMobile ? "12px 16px" : "15px 25px",
                    borderBottom:
                      categoryValue === item.title
                        ? "2px solid #FF649C"
                        : "none",
                  }}
                  onClick={() => {
                    navigate(
                      `${route.influencer_list_detail}/${id}/1?category=${item.title}`
                    );
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: isMobile ? 14 : 18,
                      lineHeight: isMobile ? "19.6px" : "26.1px",
                      fontWeight: isMobile ? 500 : 600,
                      color:
                        categoryValue === item.title ? "#313131" : "#939393",
                    }}
                  >
                    {item.title}
                  </PretendardText>
                </div>
              );
            })}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                marginTop: isMobile ? 40 : 60,
                display: "flex",
                width: "100%",
                gap: isMobile ? "24px 15px" : "40px 20px",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: isSmall ? "space-between" : "flex-start",
              }}
            >
              {categoryProductList.length > 0 ? (
                categoryProductList.map((item, index) => {
                  return (
                    <ProductItem
                      key={index}
                      data={item}
                      noVideo={categoryValue === "Sản phẩm" ? true : false}
                    />
                  );
                })
              ) : (
                <Empty title={"Vẫn chưa có dữ liệu."} />
              )}
            </div>
          </div>

          {categoryProductList.length > 0 && pagination.totalCount > 20 ? (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Pagination
                route={route.influencer_list_detail}
                queryString={`category=${categoryValue}`}
                currentPage={Number(page)}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </div>
          ) : null}
        </PCContainer>

        <FooterBtnGroup />
      </InfluencerDetailSection>
    </>
  );
};

export default InfluencerDetail;

const InfluencerDetailSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 131px 0px 80px 0px;
`;
