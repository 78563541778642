import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";

import useDisplay from "../../hooks/useDisplay";

const MypageMenu = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const navigation = useNavigate();

  const [isMenuOn01, setIsMenuOn01] = useState(true);
  const [isMenuOn02, setIsMenuOn02] = useState(true);
  const [isMenuOn03, setIsMenuOn03] = useState(true);

  const [role, setRole] = useState("");

  useEffect(() => {
    let role = sessionStorage.getItem("role");

    setRole(role);
  }, [pathname]);

  const handleCopyClipBoard = async () => {
    try {
      let recommendID = sessionStorage.getItem("recommendID");

      await navigator.clipboard.writeText(
        // `https://lsl3jsvf4799.edge.naverncp.com/koviet/register?type=CONSUMER&recommendID=${recommendID}` // test
        `https://koviet.vn/koviet/register?type=CONSUMER&recommendID=${recommendID}` // 실제주소
      );
      toast("Liên kết người giới thiệu đã được sao chép.");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        width: 280,
        marginRight: 20,
      }}
    >
      <PretendardText
        style={{
          fontSize: 28,
          lineHeight: "39.2px",
          color: "#313131",
          fontWeight: 700,
          marginBottom: 20,
          height: 39,
        }}
      >
        Tài khoản của tôi
      </PretendardText>

      <MenuCard>
        <MenuTitleContainer
          onClick={() => {
            setIsMenuOn01(!isMenuOn01);
          }}
          style={{
            marginBottom: isMenuOn01 ? 8 : 0,
          }}
        >
          <MenuTitle>Đơn hàng của tôi</MenuTitle>
          {isMenuOn01 ? (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/down_arrow.png"
              alt="down_arrow"
            />
          ) : (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/ic_arrow-up-20.png"
              alt="ic_arrow-up-20"
            />
          )}
        </MenuTitleContainer>
        {isMenuOn01 ? (
          <>
            <MenuText
              onClick={() => {
                navigation(`${route.mypage_order_list}/1`);
              }}
              $isActive={pathname?.includes("/order_list").toString()}
            >
              Tất cả đơn hàng
            </MenuText>
            <MenuText
              onClick={() => {
                navigation(`${route.mypage_exchange_list}/1`);
              }}
              $isActive={pathname?.includes("/exchange_list").toString()}
              style={{
                marginBottom: 0,
              }}
            >
              Trả hàng / Hoàn tiền
            </MenuText>
          </>
        ) : null}
      </MenuCard>

      <MenuCard>
        <MenuTitleContainer
          onClick={() => {
            setIsMenuOn02(!isMenuOn02);
          }}
          style={{
            marginBottom: isMenuOn02 ? 8 : 0,
          }}
        >
          <MenuTitle>Hoạt động của tôi</MenuTitle>
          {isMenuOn02 ? (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/down_arrow.png"
              alt="down_arrow"
            />
          ) : (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/ic_arrow-up-20.png"
              alt="ic_arrow-up-20"
            />
          )}
        </MenuTitleContainer>
        {isMenuOn02 ? (
          <>
            {role === "INFLUENCER" ? (
              <MenuText
                onClick={() => {
                  navigation(`${route.dashboard}/1`);
                }}
              >
                Bảng điều khiển
              </MenuText>
            ) : null}

            <MenuText
              onClick={() => {
                navigation(`${route.mypage_review_list}/1`);
              }}
              $isActive={pathname?.includes("/review_list").toString()}
            >
              Quản lý đánh giá
            </MenuText>
            <MenuText
              onClick={() => {
                navigation(`${route.mypage_point_manage}/1`);
              }}
              $isActive={pathname?.includes("/point_manage").toString()}
            >
              Quản lý điểm/Phiếu giảm giá
            </MenuText>
            <MenuText
              onClick={() => {
                navigation(`${route.mypage_notification_list}/1`);
              }}
              $isActive={pathname?.includes("/notification_list").toString()}
            >
              Thông báo
            </MenuText>
            <MenuText
              onClick={() => {
                navigation(`${route.mypage_inquiry_list}/1`);
              }}
              $isActive={pathname?.includes("/inquiry_list").toString()}
              style={{
                marginBottom: 0,
              }}
            >
              Câu hỏi
            </MenuText>
          </>
        ) : null}
      </MenuCard>

      <MenuCard>
        <MenuTitleContainer
          onClick={() => {
            setIsMenuOn03(!isMenuOn03);
          }}
          style={{
            marginBottom: isMenuOn03 ? 8 : 0,
          }}
        >
          <MenuTitle>Thông tin của tôi</MenuTitle>
          {isMenuOn03 ? (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/down_arrow.png"
              alt="down_arrow"
            />
          ) : (
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/ic_arrow-up-20.png"
              alt="ic_arrow-up-20"
            />
          )}
        </MenuTitleContainer>
        {isMenuOn03 ? (
          <>
            <MenuText
              onClick={() => {
                navigation(route.mypage_account_manage);
              }}
              $isActive={pathname?.includes("/account_manage").toString()}
            >
              Quản lý tài khoản
            </MenuText>
            <MenuText
              onClick={() => {
                navigation(route.mypage_withdraw);
              }}
              $isActive={pathname?.includes("/withdraw").toString()}
              style={{
                marginBottom: 0,
              }}
            >
              Xóa tài khoản
            </MenuText>
          </>
        ) : null}
      </MenuCard>

      <div
        onClick={() => {
          handleCopyClipBoard();
        }}
        style={{
          cursor: "pointer",
          width: "100%",
          height: 56,
          border: "1px solid #FF649C",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 16px",
          borderRadius: 12,
          background: "#fff",
        }}
      >
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "23.2px",
            color: "#313131",
            fontWeight: 500,
          }}
        >
          Sao chép liên kết người giới thiệu
        </PretendardText>
        <img
          style={{
            width: 24,
            height: 24,
          }}
          src="/assets/icons/content_copy.png"
          alt="content_copy"
        />
      </div>
    </div>
  );
};

export default MypageMenu;

const MenuCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 12px;
  background-color: #ffffff;
  border-radius: 12px;

  margin-bottom: 20px;
`;

const MenuTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  cursor: pointer;
`;

const MenuTitle = styled(PretendardText)`
  font-size: 18px;
  line-height: 26.1px;
  color: rgb(49, 49, 49);
  font-weight: 700;
`;

const MenuText = styled(PretendardText)`
  align-items: center;
  display: flex;

  height: 39px;

  cursor: pointer;
  font-size: 16px;
  line-height: 23.2px;
  color: ${(props) => (props.$isActive === "true" ? "#ff3d83" : "#313131")};
  font-weight: ${(props) => (props.$isActive === "true" ? "600" : "400")};
  margin-bottom: 8px;

  &:hover {
    color: #ff3d83;
  }
`;
