import React, { useRef } from "react";
import { useRecoilValue } from "recoil";

import PretendardText from "../text/pretendardText";

function FileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .pdf, .hwp, .docx"
        ref={imageInput}
        onChange={props.handleChange}
        multiple={props.multiple}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          height: 47,
          width: 154,
          background: "#ffffff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #E8E8E8",
          borderRadius: 6,
        }}
      >
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "23.2px",
            color: "#313131",
            fontWeight: 600,
            marginRight: 2,
          }}
        >
          Tải tập tin
        </PretendardText>
        <img
          src="/assets/icons/attach.png"
          style={{
            height: 16,
            width: 16,
            marginBottom: 2,
          }}
        />
      </div>
    </>
  );
}

export default FileForm;
