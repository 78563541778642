import api from "./api.js";

export default class OrderApi {
  static async order(data) {
    return await api.post(`member/order`, data);
  }

  static async orderList(data) {
    return await api.get(`member/order/my`, data);
  }

  static async exchangeMerchList(data) {
    return await api.get(`member/member-merch/my`, data);
  }

  static async orderDeliveryDetail(id) {
    return await api.get(`member/member-merch/delivery/${id}`);
  }

  static async orderDetail(id) {
    return await api.get(`member/order/${id}`);
  }

  static async orderAddressEdit(id, data) {
    return await api.patch(`member/order/${id}`, data);
  }

  static async orderAskList(data) {
    return await api.get(`member/orderAsk/my`, data);
  }

  static async orderAsk(id, data) {
    return await api.post(`member/orderAsk/${id}`, data);
  }

  static async orderAskDetail(id) {
    return await api.get(`member/orderAsk/${id}`);
  }

  static async orderCancel(id) {
    return await api.post(`member/order/cancel/${id}`, {
      reason: "",
    });
  }

  static async pointSetting() {
    return await api.get(`point-setting`);
  }

  static async orderExchange(id, data) {
    return await api.post(`member/member-merch/exchange/${id}`, data);
  }

  static async exchangeSetting() {
    return await api.get(`env-setting`);
  }
}
