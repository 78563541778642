import React, { useEffect, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import GrayBorderBtn from "../../components/atoms/button/grayBorderBtn";

import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";
import Pagination from "../../components/organisms/pagination";

import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import ConfirmModal from "../../components/templates/modals/confirmModal";

import { route } from "../../router/route";
import ProductWishItem from "../../components/templates/product/productWishItem";
import ProductWishItemM from "../../components/templates/product/productWishItemM";
import Empty from "../../components/organisms/empty";
import WishApi from "../../api/wishApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";

const WishList = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [wishProdcutList, setWishProdcutList] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [dIDList, setDIDList] = useState([]);
  const [deletedIDList, setDeletedIDList] = useState([]);

  useEffect(() => {
    if (allCheck) {
      let temp = wishProdcutList.map((item) => item.merch.id);
      setDIDList(temp);
    }
  }, [allCheck]);

  useEffect(() => {
    wishListFunc();
  }, []);

  useEffect(() => {
    const filteredData = wishProdcutList.filter(
      (item) => !deletedIDList.includes(item.merch.id)
    );
    setWishProdcutList(filteredData);
  }, [deletedIDList]);

  const wishListFunc = async () => {
    try {
      const params = {
        page: 1,
        size: 999,
      };

      const response = (await WishApi.getList(params)).data.data;

      setWishProdcutList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const wishTriggerFunc = async (id) => {
    try {
      await WishApi.wishTrigger(id);
      setDeletedIDList([...deletedIDList, id]);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const allDeleteFunc = async () => {
    try {
      for (let i = 0; i < dIDList.length; i++) {
        await WishApi.wishTrigger(dIDList[i]);
      }

      await wishListFunc();
      setAllCheck(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <WishListSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: isSmall ? "80px 20px 80px 20px" : "80px 0px 80px 0px",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
              width: "100%",
              marginBottom: isMobile ? 24 : 40,
            }}
          >
            Danh sách sản phẩm yêu thích
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: isMobile ? 24 : 30,
            }}
          >
            <RightLabelCheckbox
              checked={allCheck}
              size={20}
              onChange={() => {
                setAllCheck(!allCheck);

                if (allCheck) {
                  setDIDList([]);
                }
              }}
              textStyle={{
                fontSize: "18px",
              }}
              text={"Chọn tất cả"}
            />

            <GrayBorderBtn
              onClick={() => {
                setIsCofirmModal(true);
              }}
              style={{
                width: 92,
                height: 47,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 600,
                }}
              >
                Xóa bỏ
              </PretendardText>
            </GrayBorderBtn>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {wishProdcutList.length > 0 ? (
              wishProdcutList.map((item, index) => {
                return isMobile ? (
                  <ProductWishItemM
                    setDIDList={setDIDList}
                    dIDList={dIDList}
                    key={index}
                    data={item}
                    style={{
                      borderBottom:
                        wishProdcutList.length === index + 1
                          ? "1px solid #DCDCDC"
                          : "none",
                    }}
                    wishTriggerFunc={wishTriggerFunc}
                    wishProdcutList={wishProdcutList}
                    setAllCheck={setAllCheck}
                  />
                ) : (
                  <ProductWishItem
                    setDIDList={setDIDList}
                    dIDList={dIDList}
                    key={index}
                    data={item}
                    style={{
                      borderBottom:
                        wishProdcutList.length === index + 1
                          ? "1px solid #DCDCDC"
                          : "none",
                    }}
                    wishTriggerFunc={wishTriggerFunc}
                    wishProdcutList={wishProdcutList}
                    setAllCheck={setAllCheck}
                  />
                );
              })
            ) : (
              <Empty title={"Không có sản phẩm nào hấp dẫn cả."} />
            )}
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </WishListSection>

      {isConfirmModal ? (
        <ConfirmModal
          isScroll={false}
          title={"Anh sẽ xóa nó khỏi wishlist hả?"}
          onClick={() => {
            setIsCofirmModal(false);
          }}
          onYClick={() => {
            setIsCofirmModal(false);
            allDeleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default WishList;

const WishListSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
