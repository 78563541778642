import api from "./api.js";

export default class MerchApi {
  static async getList(data) {
    return await api.get(`merch`, data);
  }

  static async detail(id) {
    return await api.get(`merch/${id}`);
  }

  static async getOption(id) {
    return await api.get(`option/${id}`, {
      page: 1,
      size: 999,
    });
  }

  static async getOptionValue(id) {
    return await api.get(`option-value/${id}`, {
      page: 1,
      size: 999,
    });
  }

  static async getGuide() {
    return await api.get(`shipping-setting`, {
      page: 1,
      size: 999,
    });
  }

  static async getAskList(id, data) {
    return await api.get(`merchAsk/merch/${id}`, data);
  }
}
