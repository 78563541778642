import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useDisplay from "../../hooks/useDisplay";
import { route } from "../../router/route";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import MypageMenu from "../../components/templates/mypageMenu";
import ReviewModal from "../../components/templates/modals/reviewModal";
import MypageMenuM from "../../components/templates/mypageMenuM";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import Pagination from "../../components/organisms/pagination";
import Empty from "../../components/organisms/empty";
import moment from "moment";
import ReviewApi from "../../api/reviewApi";

const ReviewListPage = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [merchId, setMerchId] = useState();
  const [reviewId, setReviewId] = useState();
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  const [categoryValue, setCategoryValue] = useState("Viết đánh giá");
  const [subCategoryList, setSubCategoryList] = useState([
    { title: "Viết đánh giá" },
    { title: "Xem lại đánh giá" },
  ]);

  const [isReviewModal, setIsReviewModal] = useState(false);
  const [textReviewReword, setTextReviewReword] = useState(0);
  const [photoReviewReword, setPhotoReviewReword] = useState(0);

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category")
        ? searchParams.get("category")
        : "Viết đánh giá"
    );

    getPointSettingFunc();
    reviewListFunc();
  }, [location]);

  const getPointSettingFunc = async () => {
    try {
      let response = (await ReviewApi.getPointSetting()).data.data.content;

      setTextReviewReword(response.normalReview);
      setPhotoReviewReword(response.photoReview);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const reviewListFunc = async () => {
    try {
      setDataList([]);

      const data = {
        page: page,
        size: pagination.size,
        deliveryStatus: "DELIVERED",
        orderStatus: "ORDER",
        reviewFlag: searchParams.get("category")
          ? searchParams.get("category") === "Viết đánh giá"
            ? false
            : true
          : false,
      };

      let response = (await ReviewApi.myReviewList(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ReviewListPageSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: isSmall ? "100%" : "auto",
            padding: isSmall ? "0px 0px 80px 0px" : "80px 0px 80px 0px",
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          {isSmall ? <MypageMenuM /> : <MypageMenu />}

          <div
            style={{
              width: isSmall ? "100%" : 880,
              padding: isSmall ? "28px 20px 0px 20px" : "60px 0px 0px 0px",
            }}
          >
            <div
              style={{
                padding: isMobile ? 0 : "32px 24px",
                background: "#fff",
                borderRadius: 8,
              }}
            >
              <MenuOverflowXHidden $isMobile={isMobile}>
                {subCategoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        padding: isMobile ? 12 : "15px 25px",
                        borderBottom:
                          categoryValue === item.title
                            ? "2px solid #FF649C"
                            : "none",
                      }}
                      onClick={() => {
                        navigate(
                          `${route.mypage_review_list}/1?category=${item.title}`
                        );
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 18,
                          lineHeight: "26.1px",
                          fontWeight: categoryValue === item.title ? 600 : 500,
                          color:
                            categoryValue === item.title
                              ? "#313131"
                              : "#939393",
                          width: "max-content",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    </div>
                  );
                })}
              </MenuOverflowXHidden>

              {dataList.length > 0 ? (
                dataList.map((item, index) => {
                  return isMobile ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: dataList.length === index + 1 ? 0 : 36,
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <img
                          style={{
                            width: 72,
                            height: 72,
                            borderRadius: 6,
                            marginRight: 12,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="banner02"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <ProductTitle $isMobile={isMobile}>
                            {item.title}
                          </ProductTitle>
                          {item.optionFlag ? (
                            JSON.parse(item.optionInfo).map((item02, index) => {
                              return (
                                <PretendardText
                                  key={index}
                                  style={{
                                    fontSize: 14,
                                    lineHeight: "19.6px",
                                    color: "#939393",
                                    fontWeight: 400,
                                    marginTop: index === 0 ? 4 : 0,
                                  }}
                                >
                                  {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                </PretendardText>
                              );
                            })
                          ) : (
                            <PretendardText
                              style={{
                                fontSize: 14,
                                color: "#939393",
                                fontWeight: 400,
                                marginTop: 4,
                              }}
                            >
                              {`Số lượng: ${item.noOptionQuantity}`}
                            </PretendardText>
                          )}
                          {item.deliveryDate ? (
                            <PretendardText
                              style={{
                                marginTop: 16,
                                fontSize: 14,
                                color: "#939393",
                                fontWeight: 400,
                              }}
                            >
                              {moment(item.deliveryDate).format("YY.MM.DD")}{" "}
                              Giao hàng
                            </PretendardText>
                          ) : null}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (item.reviewFlag) {
                            setReviewId(item.id);
                            setMerchId();
                          } else {
                            setReviewId();
                            setMerchId(item.id);
                          }

                          setIsReviewModal(true);
                        }}
                        style={{
                          marginTop: 16,
                          borderRadius: 6,
                          border: "1px solid #FF649C",
                          cursor: "pointer",
                          height: 55,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "23.2px",
                            color: "#626262",
                            fontWeight: 500,
                          }}
                        >
                          {item.reviewFlag
                            ? "Chỉnh sửa đánh giá"
                            : "Viết đánh giá"}
                        </PretendardText>
                      </div>
                      {item.reviewFlag ? null : (
                        <PretendardText
                          style={{
                            fontSize: 12,
                            lineHeight: "16.8px",
                            color: "#939393",
                            fontWeight: 400,
                            marginTop: 4,
                          }}
                        >
                          Đánh giá văn bản: {textReviewReword.toLocaleString()}P
                          | Đánh giá ảnh: {photoReviewReword.toLocaleString()}P
                        </PretendardText>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: dataList.length === index + 1 ? 0 : 40,
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <img
                          style={{
                            width: 120,
                            height: 120,
                            borderRadius: 6,
                            marginRight: 20,
                          }}
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`}
                          alt="banner02"
                        />
                        <div
                          style={{
                            height: 110,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <ProductTitle $isMobile={isMobile}>
                            {item.title}
                          </ProductTitle>
                          {item.optionFlag ? (
                            JSON.parse(item.optionInfo).map((item02, index) => {
                              return (
                                <PretendardText
                                  key={index}
                                  style={{
                                    fontSize: 14,
                                    lineHeight: "19.6px",
                                    color: "#939393",
                                    fontWeight: 400,
                                    marginTop: index === 0 ? 8 : 0,
                                  }}
                                >
                                  {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                                </PretendardText>
                              );
                            })
                          ) : (
                            <PretendardText
                              style={{
                                fontSize: 14,
                                color: "#939393",
                                fontWeight: 400,
                                marginTop: 8,
                              }}
                            >
                              {`Số lượng: ${item.noOptionQuantity}`}
                            </PretendardText>
                          )}

                          {item.deliveryDate ? (
                            <PretendardText
                              style={{
                                marginTop: 16,
                                fontSize: 14,
                                color: "#939393",
                                fontWeight: 400,
                              }}
                            >
                              {moment(item.deliveryDate).format("YY.MM.DD")}{" "}
                              Giao hàng
                            </PretendardText>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          onClick={() => {
                            if (item.reviewFlag) {
                              setReviewId(item.id);
                              setMerchId();
                            } else {
                              setReviewId();
                              setMerchId(item.id);
                            }

                            setIsReviewModal(true);
                          }}
                          style={{
                            padding: "8.5px 20px",
                            borderRadius: 6,
                            border: "1px solid #FF649C",
                            cursor: "pointer",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "23.2px",
                              color: "#626262",
                              fontWeight: 500,
                            }}
                          >
                            {item.reviewFlag
                              ? "Chỉnh sửa đánh giá"
                              : "Viết đánh giá"}
                          </PretendardText>
                        </div>
                        {item.reviewFlag ? null : (
                          <PretendardText
                            style={{
                              fontSize: 12,
                              lineHeight: "16.8px",
                              color: "#939393",
                              fontWeight: 400,
                              marginTop: 8,
                            }}
                          >
                            Đánh giá văn bản:{" "}
                            {textReviewReword.toLocaleString()}P | Đánh giá ảnh:{" "}
                            {photoReviewReword.toLocaleString()}P
                          </PretendardText>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty title={"Không có review."} />
              )}

              {dataList.length > 0 && pagination.totalCount > 5 ? (
                <div
                  style={{
                    marginTop: 44,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    route={route.mypage_review_list}
                    queryString={`category=${categoryValue}`}
                    currentPage={Number(page)}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ReviewListPageSection>

      {isReviewModal ? (
        <ReviewModal
          isScroll={false}
          setVisiable={setIsReviewModal}
          reviewId={reviewId}
          merchId={merchId}
          reviewListFunc={reviewListFunc}
          textReviewReword={textReviewReword}
          photoReviewReword={photoReviewReword}
        />
      ) : null}
    </>
  );
};

export default ReviewListPage;

const ReviewListPageSection = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$isSmall ? "#fff" : "#f5f5f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ProductTitle = styled(PretendardText)`
  font-size: 16px;
  color: rgb(49, 49, 49);
  font-weight: 500;
  line-height: 23.2px;
  margin-bottom: ${(props) => (props.$isMobile ? "4px" : "0px")};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props.$isMobile ? "1" : "2"}; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: ${(props) => (props.$isMobile ? "28px" : "48px")};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
