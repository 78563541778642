import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";

import PretendardText from "../text/pretendardText";

const SelectM = ({ dataList, value, setValue, placeholder, style }) => {
  const { isMobile } = useDisplay();
  const [itemClicked, setItemClicked] = useState(false);

  return (
    <>
      <SelectWrapper
        onClick={() => {
          setItemClicked(!itemClicked);
        }}
        style={{
          ...style,
        }}
      >
        <SelectText isselect={value}>{value ? value : placeholder}</SelectText>
        <div style={{}}>
          {itemClicked ? (
            <img
              style={{
                width: 24,
                height: 24,
                marginLeft: 6,
              }}
              src="/assets/icons/ic_arrow-up-20.png"
              alt="ic_arrow-up-20"
            />
          ) : (
            <img
              style={{
                width: 24,
                height: 24,
                marginLeft: 6,
              }}
              src="/assets/icons/down_arrow.png"
              alt="down_arrow"
            />
          )}
        </div>
        {itemClicked ? (
          <SelectItemContain>
            {dataList.map((item, index) => {
              return (
                <SelectSpanText
                  key={index}
                  onClick={() => {
                    setValue(item.value);
                  }}
                  style={{
                    borderBottom:
                      dataList.length === index + 1
                        ? "none"
                        : "1px solid #DCDCDC",
                  }}
                >
                  <SelectValueText $isActive={item.value === value}>
                    {item.label}
                  </SelectValueText>
                </SelectSpanText>
              );
            })}
          </SelectItemContain>
        ) : null}
      </SelectWrapper>
    </>
  );
};

export default SelectM;

const SelectWrapper = styled.div.attrs((props) => {})`
  border-radius: 8px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  z-index: 90;
`;

const SelectText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;
  color: ${(props) => (props.isselect ? "#626262" : "#C4C4C4")};

  white-space: nowrap;
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: ${(props) => (props.$isActive ? "#ff649c" : "#626262")};
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding: 16px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;

const SelectItemContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 38px;
  right: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 170px;

  width: 216px;

  padding: 4px 20px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
