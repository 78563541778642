const prefix = "/koviet";

export const route = {
  default: prefix + "/home",

  // 홈
  home: prefix + "/home",

  // 고객센터
  customer_center: prefix + "/customer_center",

  // 약관
  provision: prefix + "/provision",

  // 상품리스트
  new_product: prefix + "/new_product",
  best_product: prefix + "/best_product",
  event_product: prefix + "/event_product",

  category_product: prefix + "/category_product",

  //인플루언서
  influencer_list: prefix + "/influencer_list",
  influencer_list_detail: prefix + "/influencer_list_detail",
  dashboard: prefix + "/dashboard",
  video_register: prefix + "/video_register",

  //기획전
  exhibition_list: prefix + "/exhibition_list",
  exhibition_list_detail: prefix + "/exhibition_list_detail",

  // 상품
  product_detail: prefix + "/product_detail",
  product_payment: prefix + "/product_payment",
  shop_basket: prefix + "/shop_basket",
  wish_list: prefix + "/wish_list",

  // 검색
  search: prefix + "/search",

  // auth
  login: prefix + "/login",
  register_type: prefix + "/register_type",
  register: prefix + "/register",
  pw_find: prefix + "/pw_find",
  pw_change: prefix + "/pw_change",

  // 마이페이지
  mypage_order_list: prefix + "/mypage/order_list",
  mypage_order_list_exchange: prefix + "/mypage/order_list/exchange",
  mypage_order_list_exchange_complete:
    prefix + "/mypage/order_list/exchange_complete",
  mypage_order_list_delivery_status:
    prefix + "/mypage/order_list/delivery_status",

  mypage_exchange_list: prefix + "/mypage/exchange_list",
  mypage_review_list: prefix + "/mypage/review_list",
  mypage_inquiry_list: prefix + "/mypage/inquiry_list",
  mypage_inquiry_list_detail: prefix + "/mypage/inquiry_list_detail",
  mypage_notification_list: prefix + "/mypage/notification_list",
  mypage_point_manage: prefix + "/mypage/point_manage",
  mypage_account_manage: prefix + "/mypage/account_manage",
  mypage_withdraw: prefix + "/mypage/withdraw",
};
