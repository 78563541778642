import { useState } from "react";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";

function PaymentProduct({
  merchInfo,
  totalAmount,
  productAmount,
  deliveryAmount,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  return (
    <>
      {merchInfo ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#FFFFFF",
            border: "1px solid #DCDCDC",
            borderRadius: 12,
            padding: isMobile ? 20 : "30px 29px 23px 30px",
            marginBottom: isMobile ? 24 : 40,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 16 : 20,
              lineHeight: isMobile ? "23.2px" : "28px",
              color: "#313131",
              fontWeight: 600,
            }}
          >
            Sản phẩm đặt hàng
          </PretendardText>

          <div
            style={{
              width: "100%",
              height: 1,
              background: "#DCDCDC",
              margin: "16px 0px",
            }}
          />

          {merchInfo && merchInfo.length > 0
            ? merchInfo.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: merchInfo.length === index + 1 ? 0 : 16,
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.merch.imageServerFileName}`}
                      className="sample04"
                      style={{
                        width: isMobile ? 65 : 85,
                        height: isMobile ? 65 : 85,
                        borderRadius: 8,
                        marginRight: 10,
                      }}
                    />
                    <div>
                      <PretendardText
                        style={{
                          fontSize: isMobile ? 14 : 16,
                          lineHeight: isMobile ? "19.6px" : "23.2px",
                          color: "#222222",
                          fontWeight: 600,
                          marginBottom: 5,
                        }}
                      >
                        {item.merch.title}
                      </PretendardText>
                      {item.merch.optionFlag ? (
                        item.optionInfo.map((item02, index) => {
                          return (
                            <PretendardText
                              key={index}
                              style={{
                                fontSize: isMobile ? 12 : 14,
                                lineHeight: isMobile ? "16.8px" : "19.6px",
                                color: "#939393",
                                fontWeight: 400,
                                marginBottom: 5,
                              }}
                            >
                              {`${item02.option.title}: ${item02.optionValue.title}, ${item02.optionValue.quantity}`}
                            </PretendardText>
                          );
                        })
                      ) : (
                        <PretendardText
                          style={{
                            fontSize: isMobile ? 12 : 14,
                            lineHeight: isMobile ? "16.8px" : "19.6px",
                            color: "#939393",
                            fontWeight: 400,
                            marginBottom: 5,
                          }}
                        >
                          {`thành tiền: ${item.merch.noOptionQuantity}`}
                        </PretendardText>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "flex-start" : "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: isMobile ? 14 : 16,
                            lineHeight: isMobile ? "19.6px" : "23.2px",
                            color: "#ABABAB",
                            fontWeight: 400,
                            marginRight: isMobile ? 0 : 5,
                            marginBottom: isMobile ? 5 : 0,
                            textDecoration: "line-through",
                          }}
                        >
                          {Number(item.merch.originalPrice).toLocaleString()}{" "}
                          đồng
                        </PretendardText>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: isMobile ? 14 : 16,
                              lineHeight: isMobile ? "19.6px" : "23.2px",
                              color: "#313131",
                              fontWeight: 700,
                              marginRight: 5,
                            }}
                          >
                            {Number(item.merch.salePrice).toLocaleString()} đồng
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontSize: isMobile ? 14 : 16,
                              lineHeight: isMobile ? "19.6px" : "23.2px",
                              color: "#FF649C",
                              fontWeight: 700,
                            }}
                          >
                            {calculateDiscountRate(
                              Number(item.merch.originalPrice),
                              Number(item.merch.salePrice)
                            )}
                            %
                          </PretendardText>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <div
            style={{
              width: "100%",
              height: 1,
              background: "#DCDCDC",
              margin: "16px 0px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 500,
              }}
            >
              Tổng tiền hàng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {Number(productAmount).toLocaleString()} đồng
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 500,
              }}
            >
              Phí vận chuyển
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {Number(deliveryAmount).toLocaleString()} đồng
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Tổng thành tiền
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              {Number(totalAmount).toLocaleString()} đồng
            </PretendardText>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PaymentProduct;
