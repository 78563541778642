import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import Pagination from "../../organisms/pagination";
import MainBorderBtn from "../../atoms/button/mainBorderBtn";
import Input from "../../atoms/input/input";
import { useLocation, useParams } from "react-router-dom";
import MemberApi from "../../../api/memberApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import { route } from "../../../router/route";
import Empty from "../../organisms/empty";
import moment from "moment";
import ConfirmModal from "../modals/confirmModal";

function PointRefund({ parentCategory }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const location = useLocation();
  const { page } = useParams();

  const [isConfirmModal, setIsCofirmModal] = useState(false);
  const [point, setPoint] = useState("");
  const [myPoint, setMyPoint] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    myInfoFunc();
  }, []);

  const myInfoFunc = async () => {
    try {
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      setMyPoint(myInfoRP.point);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  useEffect(() => {
    settleListFunc();
  }, [location]);

  const settleListFunc = async () => {
    try {
      const data = {
        page: page,
        size: pagination.size,
      };

      let response = (await MemberApi.getSettlement(data)).data.data;

      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });

      setDataList([]);
      setDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const settleAddFunc = async () => {
    try {
      if (point.length > 0) {
        let regex = new RegExp("^[0-9]+$");
        if (!regex.test(point)) {
          return toast("Vui lòng nhập số.");
        }
      }

      if (Number(point) > Number(myPoint)) {
        toast("Số tiền lớn hơn so với điểm đang có.");
        return;
      }

      const data = {
        amount: Number(point),
      };

      await MemberApi.addSettlement(data);

      toast("Đã đăng ký quyết toán điểm.");
      setPoint("");
      await myInfoFunc();
      await settleListFunc();
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error.response.data.message === "정산 계좌정보가 없습니다.") {
          return toast(`Không có thông tin tài khoản quyết toán.`);
        }
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
          position: "relative",
          marginTop: isMobile ? 28 : 48,
        }}
      >
        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "23.2px",
            fontWeight: 600,
            color: "#000000",
            marginBottom: isMobile ? 24 : 12,
          }}
        >
          Đăng ký đổi điểm
        </PretendardText>
        <PretendardText
          style={{
            fontSize: 15,
            lineHeight: "24.5px",
            fontWeight: 600,
            color: "#ADB4BA",
            marginBottom: 8,
          }}
        >
          Số điểm đổi : {Number(myPoint).toLocaleString()}P
        </PretendardText>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
            marginBottom: isMobile ? 28 : 48,
          }}
        >
          <div
            style={{
              position: "relative",
              width: isMobile ? "100%" : "auto",
              marginBottom: isMobile ? 20 : 0,
            }}
          >
            <Input
              style={{
                width: isMobile ? "100%" : 338,
                height: isMobile ? 55 : 48,
              }}
              placeholder={"0"}
              value={point}
              onChange={(e) => {
                setPoint(e.target.value);
              }}
            />
            <PretendardText
              style={{
                position: "absolute",
                fontSize: 15,
                color: "#808991",
                fontWeight: 400,
                right: 15,
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              P
            </PretendardText>
          </div>
          <MainBorderBtn
            onClick={() => {
              setIsCofirmModal(true);
            }}
            style={{
              width: isMobile ? "100%" : 200,
              height: isMobile ? 55 : 48,
              borderWidth: 1,
              marginLeft: isMobile ? 0 : 20,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                color: "#626262",
                fontWeight: 500,
              }}
            >
              Đăng ký
            </PretendardText>
          </MainBorderBtn>
        </div>

        <PretendardText
          style={{
            fontSize: 16,
            lineHeight: "23.2px",
            fontWeight: 600,
            color: "#000000",
            marginBottom: isMobile ? 24 : 12,
          }}
        >
          Chi tiết
        </PretendardText>

        <OverflowXHidden>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: isSmall ? "fit-content" : "100%",
              height: 76,
              alignItems: "center",
              background: "#FAFAFA",
            }}
          >
            <div
              style={{
                minWidth: 168,
                maxWidth: 168,
                marginRight: 13.33,
              }}
            >
              <TableTitle>Ngày dăng ký đổi điểm</TableTitle>
            </div>
            <div
              style={{
                minWidth: 280,
                maxWidth: 280,
                marginRight: 13.33,
              }}
            >
              <TableTitle>Điểm đổi</TableTitle>
            </div>
            <div
              style={{
                minWidth: 180,
                maxWidth: 180,
                marginRight: 13.33,
              }}
            >
              <TableTitle>Tình trạng</TableTitle>
            </div>
            <div
              style={{
                minWidth: 148,
                maxWidth: 148,
              }}
            >
              <TableTitle>Ngày hoàn thành</TableTitle>
            </div>
          </div>
          {dataList.length > 0 ? (
            dataList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: isSmall ? "fit-content" : "100%",
                    alignItems: "center",
                    borderTop: "1px solid #DCDCDC",
                    borderBottom:
                      dataList.length === index + 1
                        ? "1px solid #DCDCDC"
                        : "none",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      minWidth: 168,
                      maxWidth: 168,
                      marginRight: 13.33,
                    }}
                  >
                    <TableContent>
                      {moment(item.createdAt).format("YYYY.MM.DD")}
                    </TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 280,
                      maxWidth: 280,
                      marginRight: 13.33,
                    }}
                  >
                    <TableContent>
                      {Number(item.amount).toLocaleString()}P
                    </TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 180,
                      maxWidth: 180,
                      marginRight: 13.33,
                    }}
                  >
                    <TableContent>
                      {item.completeFlag ? "sự hoàn thành" : "chế độ chờ"}
                    </TableContent>
                  </div>
                  <div
                    style={{
                      minWidth: 148,
                      maxWidth: 148,
                    }}
                  >
                    <TableContent>
                      {item.completeFlag
                        ? moment(item.updatedAt).format("YYYY.MM.DD")
                        : ""}
                    </TableContent>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty title={"Không có lịch sử quyết toán."} />
          )}
        </OverflowXHidden>
        {dataList.length > 0 && pagination.totalCount > 10 ? (
          <div
            style={{
              marginTop: 48,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              route={route.mypage_point_manage}
              queryString={`category=${parentCategory}`}
              currentPage={Number(page)}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        ) : null}

        {isConfirmModal ? (
          <ConfirmModal
            isScroll={false}
            title={"Thông tin hoán đổi điểm"}
            content={`Trang của tôi > Điểm sẽ được cộng vào tài\nkhoản đăng ký ở mục Quản lý điểm / mã giảm giá.\nKhông thể nhận điểm bằng tài khoản khác.`}
            onClick={() => {
              setIsCofirmModal(false);
            }}
            nText={"KHÔNG"}
            onYClick={() => {
              settleAddFunc();
              setIsCofirmModal(false);
            }}
            yText={"Có, tôi sẽ hoàn tiền."}
          />
        ) : null}
      </div>
    </>
  );
}

export default PointRefund;

const TableTitle = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  color: #313131;
  font-weight: 600;
  text-align: center;
`;

const TableContent = styled(PretendardText)`
  font-size: 16px;
  line-height: 23.2px;
  color: #626262;
  font-weight: 400;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  position: relative;

  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
