import api from "./api.js";

export default class TermApi {
  static async getOrderTermList() {
    return await api.get(`term-setting`, {
      page: 1,
      size: 999,
    });
  }

  static async termDetail(id) {
    return await api.get(`term/${id}`);
  }
}
