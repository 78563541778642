import api from "./api.js";

export default class WishApi {
  static async getList(data) {
    return await api.get(`member/wish/my`, data);
  }

  static async wishTrigger(id) {
    return await api.post(`member/wish/${id}`);
  }
}
