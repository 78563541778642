import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import styled from "styled-components";
import { route } from "../../../router/route";

function FollowSwiper({ dataList }) {
  const swiperRef = useRef();
  const navigation = useNavigate();

  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ width: "100%", padding: isMobile ? "0px 20px" : "0px 30px" }}
        >
          <Swiper
            onSwiper={(it) => {
              swiperRef.current = it;
            }}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.realIndex);
            }}
            slidesPerView={isMobile ? 2.6 : isTablet ? 4.4 : 7.5}
            spaceBetween={isMobile ? 18 : isTablet ? 22 : 28}
            grabCursor={true}
            modules={[Pagination]}
          >
            {dataList?.length > 0
              ? dataList?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        style={{
                          width: isMobile ? 109 : 139,
                          height: isMobile ? 91 : 143,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: isMobile ? 64 : "110px",
                            height: isMobile ? 64 : "110px",
                            borderRadius: "50%",
                            border: isMobile
                              ? "2px solid transparent"
                              : "3px solid transparent",
                            backgroundImage:
                              "linear-gradient(146.86deg, #FF3D83 7.41%, #BA77EF 52.86%)",
                            backgroundOrigin: "border-box",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              background: "#fff",
                            }}
                            src={
                              item.follower.imageServerFileName
                                ? `${process.env.REACT_APP_MEDIA_URL}${item.follower.imageServerFileName}`
                                : "/assets/icons/empty_person.png"
                            }
                            alt="user-temp03"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 7,
                          }}
                        >
                          <NameText $isMobile={isMobile}>
                            {item.follower.nickname}
                          </NameText>
                          <img
                            onClick={() => {
                              navigation(
                                `${route.influencer_list_detail}/${item.follower.id}/1`
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              width: 20,
                              height: 20,
                              marginLeft: 6,
                            }}
                            src="/assets/icons/btn-arrow-40.png"
                            alt="btn-arrow-40"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default FollowSwiper;

const NameText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "18px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "26.1px")};
  font-weight: 600;
  color: #313131;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
