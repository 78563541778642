import { useState } from "react";
import moment from "moment";

import PretendardText from "../../atoms/text/pretendardText";

function CustomerCenterItemM({ style, data }) {
  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid #DCDCDC",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px 20px",
          }}
        >
          <div>
            <PretendardText
              style={{
                fontSize: 16,
                color: "#313131",
                fontWeight: 500,
              }}
            >
              {data.title}
            </PretendardText>

            <PretendardText
              style={{
                fontSize: 14,
                color: "#939393",
                fontWeight: 400,
              }}
            >
              {moment(data.createdAt).format("YYYY.MM.DD")}
            </PretendardText>
          </div>
          <img
            onClick={() => {
              setIsDetail(!isDetail);
            }}
            style={{
              width: 36,
              height: 36,
              cursor: "pointer",
            }}
            src={
              isDetail
                ? "/assets/icons/btn-arrow-up-40.png"
                : "/assets/icons/btn-arrow-down-40.png"
            }
            alt="btn-arrow-up-40"
          />
        </div>

        {isDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              background: "#F9F9F9",
            }}
          >
            <div
              style={{
                padding: "20px",
                borderTop: "1px solid #DCDCDC",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {data.content}
              </PretendardText>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default CustomerCenterItemM;
