import React, { useRef } from "react";

import PretendardText from "../text/pretendardText";

function VideoFileForm(props) {
  // useRef를 이용해 input태그에 접근한다.
  const imageInput = useRef();

  // 버튼클릭시 input태그에 클릭이벤트를 걸어준다.
  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        accept="video/*"
        ref={imageInput}
        onChange={props.handleChange}
        multiple={props.multiple}
      />
      <div
        onClick={onCickImageUpload}
        style={{
          cursor: "pointer",
          height: 90,
          width: 90,
          background: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px dashed #D1D5D9",
          borderRadius: 3,
        }}
      >
        <img
          src="/assets/icons/ic_5_camera.png"
          style={{
            height: 18.07,
            width: 25,
            marginBottom: 5,
          }}
        />
        <PretendardText
          style={{
            fontSize: 15,
            lineHeight: "24.5px",
            color: "#808991",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Tải video lên
        </PretendardText>
      </div>
    </>
  );
}

export default VideoFileForm;
