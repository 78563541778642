import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import TitleInput from "../../components/molecules/input/titleInput";
import MainBtn from "../../components/atoms/button/mainBtn";

import useDisplay from "../../hooks/useDisplay";
import AuthApi from "../../api/authApi";
import { errorHandler } from "../../util/errorHandler";
import { toast } from "react-toastify";
import { route } from "../../router/route";

const PwChange = () => {
  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [searchParams, setSearchParams] = useSearchParams();

  const [pw, setPw] = useState("");
  const [pwType, setPwType] = useState("password");
  const [pwErrorM, setPwErrorM] = useState("");

  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmType, setPwConfirmType] = useState("password");
  const [pwConfirmErrorM, setPwConfirmErrorM] = useState("");

  useEffect(() => {
    if (pw.length > 0) {
      var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

      if (!regType1.test(pw)) {
        return setPwErrorM(
          "Vui lòng nhập tổ hợp ký tự đặc biệt, số, tiếng Anh 8-16 ký tự."
        );
      }

      if (pw.length < 8) {
        return setPwErrorM("Vui lòng nhập mật khẩu trên 8 chữ số.");
      }

      setPwErrorM("");
    }
  }, [pw]);

  useEffect(() => {
    if (pwConfirm.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErrorM("Mật khẩu không khớp.");
      }

      setPwConfirmErrorM("");
    }
  }, [pwConfirm]);

  const pwChangeFunc = async () => {
    try {
      let obj = {
        email: searchParams.get("email"),
        newPassword: pw,
      };

      const response = await AuthApi.pwChange(obj);

      toast("Mật khẩu đã được thay đổi.");

      navigation(route.login);
    } catch (error) {
      if (error.response) {
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <PwChangeSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isMobile ? "0px 20px" : 0,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              color: "#313131",
              textAlign: "center",
              fontWeight: 600,
              marginBottom: 15,
            }}
          >
            Đặt lại mật khẩu
          </PretendardText>

          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "23.2px",
              color: "#939393",
              textAlign: "center",
              fontWeight: 500,
              marginBottom: 40,
            }}
          >
            {isMobile
              ? "Mật khẩu có từ 8 ký tự trở lên đến 16 \nký tự bao gồm chữ cái, số, ký tự đặc biệt."
              : "Mật khẩu có từ 8 ký tự trở lên đến 16 \nký tự bao gồm chữ cái, số, ký tự đặc biệt."}
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : 440,
            }}
          >
            <TitleInput
              inputType={"password"}
              setInputType={setPwType}
              type={pwType}
              title={"Mật khẩu"}
              essential={true}
              placeholder={"Vui lòng nhập mật khẩu của bạn."}
              onChange={(e) => {
                setPw(e.target.value);
              }}
              value={pw}
              warningMessage={pwErrorM}
            />

            <TitleInput
              inputType={"password"}
              setInputType={setPwConfirmType}
              type={pwConfirmType}
              title={"Xác nhận lại mật khẩu"}
              essential={true}
              placeholder={"Vui lòng nhập lại mật khẩu của bạn."}
              style={{
                marginTop: isMobile ? 24 : 20,
              }}
              onChange={(e) => {
                setPwConfirm(e.target.value);
              }}
              value={pwConfirm}
              warningMessage={pwConfirmErrorM}
            />

            <MainBtn
              disabled={
                pw && pwConfirm && pwErrorM === "" && pwConfirmErrorM === ""
                  ? false
                  : true
              }
              onClick={() => {
                pwChangeFunc();
              }}
              style={{
                marginTop: 40,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                Hoàn tất
              </PretendardText>
            </MainBtn>
          </div>
        </PCContainer>
      </PwChangeSection>
    </>
  );
};

export default PwChange;

const PwChangeSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
`;
