import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import PretendardText from "../components/atoms/text/pretendardText";
import PCContainer from "../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../components/templates/footerBtnGroup";

import useDisplay from "../hooks/useDisplay";
import { terms } from "../constant/provision";
import { route } from "../router/route";
import TermApi from "../api/termApi";
import { toast } from "react-toastify";
import { errorHandler } from "../util/errorHandler";

const Provision = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [content01, setContent01] = useState("");
  const [content02, setContent02] = useState("");

  const [categoryValue, setCategoryValue] = useState("Điều khoản sử dụng");
  const [subCategoryList, setSubCategoryList] = useState([
    { title: "Điều khoản sử dụng" },
    { title: "Chính sách bảo mật" },
  ]);

  useEffect(() => {
    setCategoryValue(
      searchParams.get("category")
        ? searchParams.get("category")
        : "Điều khoản sử dụng"
    );

    termDetailFunc();
  }, [location]);

  const termDetailFunc = async () => {
    try {
      const response01 = (await TermApi.termDetail(2)).data.data.content;
      setContent01(response01?.content);

      const response02 = (await TermApi.termDetail(4)).data.data.content;
      setContent02(response02?.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ProvisionSection $isSmall={isSmall}>
        <PCContainer
          style={{
            width: "100%",
            marginBottom: isMobile ? 28 : 40,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 32,
              lineHeight: isMobile ? "28px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
            }}
          >
            Điều khoản
          </PretendardText>
        </PCContainer>

        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <OverflowXHidden
            $isSmall={isSmall}
            style={{
              marginBottom: isMobile ? 28 : 40,
            }}
          >
            {subCategoryList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    padding: isMobile ? "12px" : "15px 45px",
                    borderBottom:
                      categoryValue === item.title
                        ? "2px solid #FF649C"
                        : "none",
                  }}
                  onClick={() => {
                    navigate(`${route.provision}?category=${item.title}`);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      lineHeight: "26.1px",
                      fontWeight: categoryValue === item.title ? 600 : 500,
                      color:
                        categoryValue === item.title ? "#313131" : "#939393",
                      width: "max-content",
                    }}
                  >
                    {item.title}
                  </PretendardText>
                </div>
              );
            })}
          </OverflowXHidden>

          <div
            style={{
              width: "100%",
              background: "#F9F9F9",
              padding: isMobile ? 20 : 40,
              borderRadius: 20,
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                fontWeight: 400,
                color: "#313131",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  categoryValue === "Điều khoản sử dụng"
                    ? content01
                    : content02,
              }}
            />
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </ProvisionSection>
    </>
  );
};

export default Provision;

const ProvisionSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${(props) =>
    props.$isSmall ? "40px 20px 80px 20px" : "80px 0px 80px 0px"};
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(220, 220, 220);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
