import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";

import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";

import InputFS15 from "../../components/atoms/input/inputFS15";
import InputSearchFS15 from "../../components/atoms/input/inputSearchFS15";
import RightLabelRadio from "../../components/molecules/radio/rightLabelRadio";
import VideoFileForm from "../../components/atoms/fileForm/videoFileForm";
import { toast } from "react-toastify";
import MainBtn from "../../components/atoms/button/mainBtn";
import CommonApi from "../../api/commonApi";
import { errorHandler } from "../../util/errorHandler";
import MerchApi from "../../api/merchApi";
import MemberApi from "../../api/memberApi";

const VideoRegister = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const [title, setTitle] = useState("");
  const [product, setProduct] = useState("");
  const [link, setLink] = useState("");
  const [videoType, setVideoType] = useState([
    {
      title: "Tải tập tin",
      value: true,
    },
    {
      title: "Đăng ký liên kết",
      value: false,
    },
  ]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [searchDataList, setSearchDataList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueInfo, setSearchValueInfo] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    handleMerchListFunc();
  }, [product]);

  const handleMerchListFunc = _.debounce(() => {
    merchListFunc();
  }, 600);

  const merchListFunc = async () => {
    try {
      const data = {
        page: 1,
        size: 99,
        keyword: product,
      };

      const response = (await MerchApi.getList(data)).data.data;

      setSearchDataList(response.content);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  useEffect(() => {
    if (videoType[0].value) {
      if (title && searchValue && selectedFiles.length > 0) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    } else {
      if (title && searchValue && link) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [title, searchValue, videoType, selectedFiles, link]);

  const handleChange = async (e) => {
    const filesArray = Array.from(e.target.files);

    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop()
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          videoOriginFileName: file.name,
          videoServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setSelectedFiles((prevFiles) => [fileObject]);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType) => {
    try {
      const obj = {
        path: "influencer/video/",
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const deleteFile = (fileName) => {
    let temp = selectedFiles.filter(
      (item) => item.videoServerFileName != fileName
    );
    setSelectedFiles(temp);
  };

  const videoTypeClickFunc = (title) => {
    let temp = videoType.map((item) => {
      return item.title === title
        ? {
            ...item,
            value: true,
          }
        : {
            ...item,
            value: false,
          };
    });

    setVideoType(temp);
  };

  const videoRegisterFunc = async () => {
    try {
      const obj = {
        title: title,
        videoOriginFileName: selectedFiles[0]?.videoOriginFileName,
        videoServerFileName: selectedFiles[0]?.videoServerFileName,
        link: link,
      };

      const response = await MemberApi.videoRegister(searchValueInfo.id, obj);

      navigate(-1);
      toast("Video đã được đăng ký.");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <VideoRegisterSection $isMobile={isMobile}>
        <PCContainer
          style={{
            flexDirection: "column",
            width: "100%",
            padding: isSmall ? "40px 20px 80px 20px" : "80px 0px 80px 0px",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 24,
              lineHeight: isMobile ? "28px" : "33.6px",
              fontWeight: isMobile ? 600 : 700,
              color: "#000000",
              marginBottom: isMobile ? 36 : 20,
            }}
          >
            Đăng ký sản phẩm
          </PretendardText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: isMobile ? 0 : 40,
              background: "#ffffff",
              borderRadius: isMobile ? 0 : 10,
            }}
          >
            <PretendardText
              style={{
                fontSize: 18,
                lineHeight: "25px",
                fontWeight: 700,
                color: "#262C31",
                marginBottom: isMobile ? 24 : 30,
              }}
            >
              Thiết lập cơ bản
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                marginBottom: 20,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "24.5px",
                  fontWeight: 600,
                  color: "#ADB4BA",
                  marginRight: isMobile ? 0 : 20,
                  marginBottom: isMobile ? 8 : 0,
                  minWidth: 200,
                }}
              >
                Tiêu đề video*
              </PretendardText>
              <InputFS15
                style={{
                  width: isMobile ? "100%" : 338,
                  height: 48,
                }}
                placeholder={"Hãy nhập tiêu đề."}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                marginBottom: 20,
                position: "relative",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "24.5px",
                  fontWeight: 600,
                  color: "#ADB4BA",
                  marginRight: isMobile ? 0 : 20,
                  marginBottom: isMobile ? 8 : 0,
                  minWidth: 200,
                }}
              >
                Tên sản phẩm liên quan
              </PretendardText>
              <div
                style={{
                  position: "relative",
                  width: isMobile ? "100%" : "auto",
                }}
              >
                {searchValue ? (
                  <div
                    style={{
                      width: isMobile ? "100%" : 338,
                      minHeight: 48,
                      padding: "11px 15px",
                      border: "1px solid #b8bfc4",
                      borderRadius: 8,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        alignItems: "center",
                        background: "#EEEEEE",
                        borderRadius: 4,
                        padding: "2px 10px",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 15,
                          color: "#808991",
                          fontWeight: 700,
                        }}
                      >
                        {searchValue}
                      </PretendardText>

                      <img
                        onClick={() => {
                          setSearchValue("");
                          setSearchValueInfo(null);
                        }}
                        style={{
                          width: 14,
                          height: 14,
                          cursor: "pointer",
                          marginLeft: 10,
                        }}
                        src="/assets/icons/ic-closed-30.png"
                        alt="ic-closed-30"
                      />
                    </div>

                    <img
                      onClick={() => {
                        setIsSearch(true);
                      }}
                      src="/assets/icons/search.png"
                      alt=""
                      style={{
                        position: "absolute",
                        top: "13px",
                        right: "19px",
                        width: 18,
                        height: 18,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : (
                  <InputSearchFS15
                    style={{
                      height: 48,
                    }}
                    parentStyle={{
                      width: isMobile ? "100%" : 338,
                    }}
                    placeholder={"Vui lòng tìm kiếm tên sản phẩm."}
                    value={product}
                    onChange={(e) => {
                      setProduct(e);
                      setIsSearch(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsSearch(true);
                      }
                    }}
                    setDataToKeyword={() => {
                      setIsSearch(true);
                    }}
                  />
                )}

                {isSearch ? (
                  <SearchContain>
                    {searchDataList.map((item, index) => {
                      return (
                        <SelectSpanText
                          key={index}
                          onClick={() => {
                            setSearchValue(item.title);
                            setSearchValueInfo(item);
                            setIsSearch(false);
                            setProduct("");
                          }}
                        >
                          <SelectValueText>{item.title}</SelectValueText>
                        </SelectSpanText>
                      );
                    })}
                  </SearchContain>
                ) : null}
              </div>
              {isSmall ? null : (
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "21.25px",
                    fontWeight: 400,
                    color: "#808991",
                    marginLeft: 12,
                  }}
                >
                  Mã sản phẩm:{" "}
                  {searchValueInfo ? searchValueInfo.merchCode : "000000"}
                </PretendardText>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 15,
                  lineHeight: "24.5px",
                  fontWeight: 600,
                  color: "#ADB4BA",
                  marginRight: isMobile ? 0 : 20,
                  marginBottom: isMobile ? 8 : 0,
                  minWidth: 200,
                }}
              >
                Đăng tải video
              </PretendardText>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {videoType.map((item, index) => {
                    return (
                      <RightLabelRadio
                        checked={item.value}
                        key={index}
                        text={item.title}
                        onClick={() => {
                          videoTypeClickFunc(item.title);
                        }}
                      />
                    );
                  })}
                </div>

                {videoType[0].value ? (
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <VideoFileForm handleChange={handleChange} />
                    {selectedFiles.length > 0
                      ? selectedFiles.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginLeft: 20,
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "23.2px",
                                  color: "#313131",
                                  fontWeight: 700,
                                }}
                              >
                                {item.videoOriginFileName}
                              </PretendardText>

                              <img
                                onClick={() => {
                                  deleteFile(item.videoServerFileName);
                                }}
                                style={{
                                  width: 18,
                                  height: 18,
                                  cursor: "pointer",
                                  marginLeft: 20,
                                }}
                                src="/assets/icons/ic-closed-30.png"
                                alt="ic-closed-30"
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : (
                  <InputFS15
                    style={{
                      width: isMobile ? "100%" : 338,
                      height: 48,
                      marginTop: 20,
                    }}
                    placeholder={"Vui lòng nhập link."}
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <MainBtn
                disabled={btnDisabled}
                onClick={() => {
                  videoRegisterFunc();
                }}
                style={{
                  width: isMobile ? "100%" : 300,
                  marginTop: isMobile ? 36 : 60,
                }}
              >
                <PretendardText
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Chỉnh sửa thông tin
                </PretendardText>
              </MainBtn>
            </div>
          </div>
        </PCContainer>

        <FooterBtnGroup isAlways={true} />
      </VideoRegisterSection>
    </>
  );
};

export default VideoRegister;

const VideoRegisterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${(props) => (props.$isMobile ? "#ffffff" : "#f5f5f5")};
`;

const SearchContain = styled.div.attrs((props) => {})`
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  height: 225px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #adb4ba;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SelectValueText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.2px;

  color: #313131;
  white-space: nowrap;
`;

const SelectSpanText = styled.div.attrs((props) => {})`
  padding-left: 18px;

  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #e8e6e6;
    text-decoration: none;
  }
`;
