import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";

import {
  myActivityMenu,
  myActivityMenuIF,
  myInfoMenu,
  myShoppingMenu,
} from "../../constant/category";

const MypageMenuM = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();

  const [categoryList, setCategoryList] = useState([
    {
      value: "myShopping",
      title: "mua sắm của tôi",
      isActive: true,
    },
    {
      value: "myActivity",
      title: "hoạt động của tôi",
      isActive: false,
    },
    {
      value: "myInfo",
      title: "Thông tin của tôi",
      isActive: false,
    },
  ]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    let role = sessionStorage.getItem("role");

    setRole(role);

    if (pathname.includes("order_list") || pathname.includes("exchange_list")) {
      categoryClickFunc("myShopping");
    } else if (
      pathname.includes("review_list") ||
      pathname.includes("point_manage") ||
      pathname.includes("notification_list") ||
      pathname.includes("inquiry_list")
    ) {
      categoryClickFunc("myActivity");
    } else if (
      pathname.includes("account_manage") ||
      pathname.includes("withdraw")
    ) {
      categoryClickFunc("myInfo");
    }
  }, [pathname]);

  useEffect(() => {
    let temp = categoryList.find((item) => item.isActive === true);

    switch (temp.value) {
      case "myShopping":
        setSubCategoryList(myShoppingMenu);
        break;

      case "myActivity":
        if (role === "INFLUENCER") {
          setSubCategoryList(myActivityMenuIF);
        } else {
          setSubCategoryList(myActivityMenu);
        }

        break;

      case "myInfo":
        setSubCategoryList(myInfoMenu);
        break;
    }
  }, [categoryList, role]);

  const categoryClickFunc = (value) => {
    let temp = categoryList.map((item) => {
      return item.value === value
        ? {
            ...item,
            isActive: true,
          }
        : {
            ...item,
            isActive: false,
          };
    });

    setCategoryList(temp);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <OverflowXHidden>
        {categoryList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                cursor: "pointer",
                padding: "12px 16px",
                borderBottom: item.isActive ? "2px solid #FF649C" : "none",
              }}
              onClick={() => {
                categoryClickFunc(item.value);
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  fontWeight: 500,
                  color: item.isActive ? "#313131" : "#939393",
                  width: "max-content",
                }}
              >
                {item.title}
              </PretendardText>
            </div>
          );
        })}
      </OverflowXHidden>
      <MenuOverflowXHidden>
        {subCategoryList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                cursor: "pointer",
                padding: "10px 12px",
              }}
              onClick={() => {
                navigation(item.routeName);
              }}
            >
              <MenuText
                $isActive={pathname?.includes(item.activeName).toString()}
              >
                {item.title}
              </MenuText>
            </div>
          );
        })}
      </MenuOverflowXHidden>
    </div>
  );
};

export default MypageMenuM;

const MenuText = styled(PretendardText)`
  font-size: 14px;
  line-height: 19.6px;
  width: max-content;
  color: ${(props) => (props.$isActive === "true" ? "#FF649C" : "#939393")};
  font-weight: ${(props) => (props.$isActive === "true" ? "500" : "400")};

  &:hover {
    color: #ff649c;
  }
`;

const OverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(220, 220, 220);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  justify-content: flex-start;
  background-color: #fafafa;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
