import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import TitleInput from "../../molecules/input/titleInput";
import RightLabelCheckbox from "../../molecules/checkbox/rightLabelCheckbox";
import Input from "../../atoms/input/input";
import useDisplay from "../../../hooks/useDisplay";
import AddressSearch from "../../organisms/addressSearch";

function AddressModal({ visiable, setVisiable, isScroll, onClick, noUse }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [address, setAddress] = useState();
  const [addressDR, setAddressDR] = useState();
  const [addressWard, setAddressWard] = useState();

  const [addressDetail, setAddressDetail] = useState("");

  const [name, setName] = useState("");
  const [nameErrorM, setNameErrorM] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErrorM, setPhoneErrorM] = useState("");

  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  return (
    <AddressModalWrapper $isMobile={isMobile}>
      <AddressModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: 30,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Địa chỉ nhận hàng
            </PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
              }}
              style={{
                width: isMobile ? 20 : 30,
                height: isMobile ? 20 : 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 8,
                marginTop: 20,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Địa chỉ
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#FF3D83",
                  fontWeight: 700,
                }}
              >
                *
              </PretendardText>
            </div>
            <AddressSearch
              address={address}
              setAddress={setAddress}
              addressDR={addressDR}
              setAddressDR={setAddressDR}
              addressWard={addressWard}
              setAddressWard={setAddressWard}
            />

            <Input
              placeholder={"Vui lòng nhập địa chỉ chi tiết."}
              value={addressDetail}
              onChange={(e) => {
                setAddressDetail(e.target.value);
              }}
            />

            <TitleInput
              title={"Người nhận"}
              essential={true}
              placeholder={"Hãy nhập tên của người nhận."}
              type={"text"}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              warningMessage={nameErrorM}
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            />

            <TitleInput
              title={"Số điện thoại"}
              essential={true}
              placeholder={"Vui lòng nhập số điện thoại."}
              type={"text"}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              warningMessage={phoneErrorM}
              style={{
                marginBottom: 20,
              }}
            />

            {noUse ? null : (
              <RightLabelCheckbox
                checked={agree}
                onChange={() => {
                  setAgree(!agree);
                }}
                text={"Lưu địa chỉ"}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 40,
              width: "100%",
            }}
          >
            <MainBtn
              disabled={address && addressDR && name && phone ? false : true}
              onClick={() => {
                let addressArray = [];
                if (address) {
                  addressArray.push(address);
                }

                if (addressDR) {
                  addressArray.push(addressDR);
                }

                if (addressWard) {
                  addressArray.push(addressWard);
                }

                onClick(addressArray, addressDetail, name, phone, agree);
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Lưu
              </PretendardText>
            </MainBtn>
          </div>
        </div>
      </AddressModalContainer>
    </AddressModalWrapper>
  );
}

export default AddressModal;

const AddressModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const AddressModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "520px")};
  padding: ${(props) => (props.$isMobile ? "40px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;
