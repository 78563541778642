import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import RightLabelCheckbox from "../../molecules/checkbox/rightLabelCheckbox";
import TermApi from "../../../api/termApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import TermModal from "../modals/termModal";
import PayTermModal from "../modals/payTermModal";

let termsType = "";

function ProductPaymentRight({
  handlePayment,
  usePoint,
  couponAmount,
  totalAmount,
  calTotalAmount,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const navigation = useNavigate();

  const [allAgree, setAllAgree] = useState("");
  // const [agree01, setAgree01] = useState(false);
  const [termList, setTermList] = useState([]);

  const [isTermModal, setIsTermModal] = useState(false);

  useEffect(() => {
    if (termList.every((item) => item.isChecked)) {
      setAllAgree(true);
    } else {
      setAllAgree(false);
    }
  }, [termList]);

  useEffect(() => {
    if (allAgree) {
      // setAgree01(true);

      let temp = termList.map((item) => {
        return { ...item, isChecked: true };
      });

      setTermList(temp);
    }
  }, [allAgree]);

  useEffect(() => {
    termListFunc();
  }, []);

  const termListFunc = async () => {
    try {
      const response = (await TermApi.getOrderTermList()).data.data.content;

      let temp = response.map((item) => {
        return { ...item, isChecked: false };
      });

      setTermList(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          marginLeft: isSmall ? 0 : 20,
          marginTop: isMobile ? 24 : isTablet ? 40 : 0,
          width: isSmall ? "100%" : 470,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#FFFFFF",
            border: "1px solid #DCDCDC",
            borderRadius: 12,
            padding: isMobile ? 20 : 30,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 16 : 20,
              lineHeight: isMobile ? "23.2px" : "28px",
              color: "#313131",
              fontWeight: 600,
            }}
          >
            Thông tin thanh toán
          </PretendardText>

          <div
            style={{
              width: "100%",
              height: 1,
              background: "#DCDCDC",
              margin: "14px 0px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 14,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 500,
              }}
            >
              Tổng
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#626262",
                fontWeight: 400,
              }}
            >
              {Number(totalAmount).toLocaleString()}đ
            </PretendardText>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#313131",
                fontWeight: 500,
              }}
            >
              Điểm / Mã giảm giá
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "19.6px" : "23.2px",
                color: "#626262",
                fontWeight: 400,
              }}
            >
              -
              {Number(Number(usePoint) + Number(couponAmount)).toLocaleString()}{" "}
            </PretendardText>
          </div>

          <div
            style={{
              width: "100%",
              height: 1,
              background: "#DCDCDC",
              margin: "14px 0px 16px 0px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 18,
                lineHeight: isMobile ? "19.6px" : "26.1px",
                color: "#626262",
                fontWeight: 600,
              }}
            >
              Tổng số tiền thanh toán
            </PretendardText>
            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 24,
                lineHeight: isMobile ? "19.6px" : "33.6px",
                color: "#313131",
                fontWeight: 700,
              }}
            >
              {Number(calTotalAmount).toLocaleString()}đ
            </PretendardText>
          </div>

          <div
            style={{
              width: "100%",
              height: 1,
              background: "#C4C4C4",
              margin: "16px 0px",
            }}
          />

          <RightLabelCheckbox
            inputStyle={{
              marginBottom: 10,
            }}
            textStyle={{
              fontSize: isMobile ? 14 : 16,
            }}
            checked={allAgree}
            onChange={() => {
              if (allAgree) {
                // setAgree01(false);
                let temp = termList.map((item) => {
                  return { ...item, isChecked: false };
                });

                setTermList(temp);
              }

              setAllAgree(!allAgree);
            }}
            text={"Đồng ý với tất cả các điều khoản và điều kiện"}
          />

          {/* <RightLabelCheckbox
            inputStyle={{
              marginBottom: 10,
            }}
            textStyle={{
              fontSize: isMobile ? 14 : 16,
            }}
            checked={agree01}
            onChange={() => {
              setAgree01(!agree01);
            }}
            text={"Xác nhận chi tiết đơn hàng và đồng ý thanh toán (bắt buộc)"}
          /> */}

          {termList.length > 0
            ? termList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: termList.length === index + 1 ? 0 : 10,
                    }}
                  >
                    <RightLabelCheckbox
                      checked={item.isChecked}
                      textStyle={{
                        fontSize: isMobile ? 14 : 16,
                      }}
                      onChange={() => {
                        let temp = termList.map((item02) =>
                          item.id === item02.id
                            ? {
                                ...item02,
                                isChecked: !item02.isChecked,
                              }
                            : { ...item02 }
                        );

                        setTermList(temp);
                      }}
                      text={`${item.title} ${
                        item.requiredFlag ? "(bắt buộc)" : ""
                      }`}
                    />
                    <PretendardText
                      onClick={() => {
                        termsType = termList.find(
                          (item02) => item02.id === item.id
                        ).content;
                        setIsTermModal(true);
                      }}
                      style={{
                        textAlign: "right",
                        fontSize: isMobile ? 14 : 16,
                        lineHeight: isMobile ? "19.6px" : "22.4px",
                        color: "#939393",
                        fontWeight: 600,
                        cursor: "pointer",
                        minWidth: 101,
                      }}
                    >
                      Xem tất cả
                    </PretendardText>
                  </div>
                );
              })
            : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <MainBtn
            disabled={
              termList
                .filter((item) => item.requiredFlag) // requiredFlag가 true인 항목만 필터링
                .every((item) => item.isChecked)
                ? false
                : true
            }
            onClick={() => {
              handlePayment();
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "white",
                fontWeight: 700,
              }}
            >
              Thanh toán
            </PretendardText>
          </MainBtn>
        </div>
      </div>

      {isTermModal ? (
        <PayTermModal
          setVisiable={setIsTermModal}
          isScroll={false}
          isDetail={termsType}
        />
      ) : null}
    </>
  );
}

export default ProductPaymentRight;
